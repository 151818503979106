import { useContext, useEffect, useState } from 'react';

import di from '../../../di/DependencyInjection';
import { GetAISummaryByRecordingUseCase, GetAISummaryByRecordingUseCaseName } from '../../../domain/use_cases/ai/GetAISummaryByRecordingUseCase';
import { useParams } from 'react-router-dom';
import { Container, ContentLayout, Grid, Header, SpaceBetween } from '@cloudscape-design/components';
import { ValueWithLabel } from './components/ValueWithLabel';
import { isRight } from 'fp-ts/lib/Either';
import { elem } from 'fp-ts/lib/Option';
import { TranscriptSegment } from './components/TranscriptSegment';
import { TranscriptOverlay } from './components/TranscriptOverlay';
import { Entities } from './components/Entities';
import { ListItems } from './components/ListItems';
import { range } from './util';
import { LoudnessChart } from './components/LoudnessChart';
import { Tag } from './components/Tag';
import { SentimentChart } from './components/SentimentChart';
import { SpeakerTimeChart } from './components/SpeakerTimeChart';
import { Formatter } from './components/format';
import { Sentiment } from './components/Sentiment';
import { UpdateAISummaryByRecordingUseCase, UpdateAISummaryByRecordingUseCaseName } from '../../../domain/use_cases/ai/UpdateAISummaryByRecordingUseCase';
import ButtonComponent from '../../components/button/ButtonComponent';
import { ButtonType } from '../../components/button/ButtonComponentProps';
import Icons from '../../assets/Icons';
import UserContext from '../../../domain/providers/user/UserContext';
import UserContextType from '../../../domain/providers/user/UserContextType';
import ModalsContext from '../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../domain/providers/modal/ModalsContextType';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

interface SpeakerTime {
    TotalTimeSecs: number;
}

function extractSummary(data: any) {
    if (data === "") {
        return ""
    }
    const regex = /<summary>\s*(.*?)\s*<\/summary>/;
    const summaries: any = [];

    data.forEach((item: any) => {
        const match = regex.exec(item.value);
        if (match) {
            summaries.push(match[1]);
        }
    });

    return summaries;
}

function extractQuestionsToObject(data: any) {
    const regex = /<question(\d+)>\s*(.*?)\s*<\/question\1>/g;
    const questions: any = [];
    let match;

    data.forEach((item: any) => {
        while ((match = regex.exec(item.value)) !== null) {
            questions.push({ name: `${match[2].split(":")[0]}`, response: match[2].split(":")[1] });
        }
    });

    return questions;
}


interface AiSummaryPageProps {
}

const AiSummaryPage: React.FC<AiSummaryPageProps> = () => {

    const { recording, format } = useParams();
    
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    
    const { user } = useContext(UserContext) as UserContextType;

    let recordURL = (recording || "") + (format ? "." + format : "");
    recordURL = !recordURL.includes(".json") ? recordURL + ".json" : recordURL;

    const [ isLoading, setIsLoading ] = useState(false);

    const [speakerLabels, setSpeakerLabels] = useState<any>({});

    const [ data, setData ] = useState<any>();
    const [ summaryFromData, setSummaryFromData ] = useState<any>("");
    const [ questionsFromData, setQuestionsFromData ] = useState<any>("");

    const [loudnessData, setLoudnessData] = useState<any>({});

    const _updateUserRoleById02 = async () => await di.get<GetAISummaryByRecordingUseCase>(GetAISummaryByRecordingUseCaseName)._aiRepository.getAISumaryByRecord(recordURL as string);

    const firstCall = async () => {
        const response = await _updateUserRoleById02();
        if (isRight(response)) {
            let result = JSON.parse(response.right as string);
            
            const genAiSummary = (result["ConversationAnalytics"]["Summary"] ?
                Object.entries(result["ConversationAnalytics"]["Summary"]).map(([key, value]) => {
                return {
                    label: key,
                    value: (value instanceof Array ? value.join(', ') : value)
                }
                }) : []);
            const summaryExtracted = extractSummary(genAiSummary);
            let questionsObject = extractQuestionsToObject(genAiSummary);

            if (result["ConversationAnalytics"]["Questions"]) {
                questionsObject = result["ConversationAnalytics"]["Questions"].map((item: any) => {
                    return {
                        name: item[0],
                        response: item[1]
                    }
                })
            }
            
            setData(result);
            setSummaryFromData(summaryExtracted);

            let comments = result["ConversationAnalytics"]["ManualQuestions"];
            let scores = result["ConversationAnalytics"]["ManualEvaluations"];
            let aiScores = result["ConversationAnalytics"]["QuestionScoring"];

            const commentsKeys = comments.map((q: any) => {
                return {
                    question: q[0].replaceAll(" ", "").toLocaleLowerCase(),
                    comment: q[1]
                }
            });
            const scoresKeys = scores.map((q: any) => {
                return {
                    question: q[0].replaceAll(" ", "").toLocaleLowerCase(),
                    score: q[1],
                    score2: q[2],
                    score3: q[3]
                }
            });
            const aiScoresKeys = aiScores.map((q: any) => {
                return {
                    question: q[0].replaceAll(" ", "").toLocaleLowerCase(),
                    score: q[1]
                }
            });
            
            const questionScores = questionsObject.map((q: any) => {
                return {
                    question: q.name.replaceAll(" ", "").toLocaleLowerCase(),
                    name: q.name,
                    response: q.response
                }
            });

            const dataToRender: any = [];
            scoresKeys.forEach((question: any) => {
                //console.log(scoresKeys.filter((score: any) => score.question === question.question) ? scoresKeys.filter((score: any) => score.question === question.question)[0]?.score : 0);
                let newData = {
                    score: scoresKeys.filter((score: any) => score.question === question.question)[0]?.score,
                    comment: commentsKeys.filter((score: any) => score.question === question.question)[0].comment,
                    question: questionScores.filter((q: any) => q.question === question.question)[0].name,
                    response: questionScores.filter((q: any) => q.question === question.question)[0].response,
                    id: questionScores.filter((q: any) => q.question === question.question)[0].question,
                    score2: scoresKeys.filter((score: any) => score.question === question.question)[0].score2,
                    score3: scoresKeys.filter((score: any) => score.question === question.question)[0].score3,
                    aiScore: aiScoresKeys.filter((score: any) => score.question === question.question)[0].score
                }
                dataToRender.push(newData);
            });
            
            dataToRender.sort((a: any, b: any) => {
                if (a.id < b.id) return -1;
                if (a.id > b.id) return 1;
                return 0;
            });
            setQuestionsFromData(dataToRender);
            
        }
    }

    const  _handleScoreChange = (value: string, question: string) => {

        let filterData = questionsFromData.filter((item: any) => item.id === question);
        filterData[0]['score'] = +value;

        let filterDataWithNoValue =questionsFromData.filter((item: any) => item.id !== question);
        let newData = [...filterDataWithNoValue, ...filterData];
        newData.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        });
        setQuestionsFromData(newData);
        
    }

    const _handleCommentChange = (value: string, question: string) => {
        let filterData = questionsFromData.filter((item: any) => item.id === question);
        filterData[0]['comment'] = value;

        let filterDataWithNoValue =questionsFromData.filter((item: any) => item.id !== question);
        let newData = [...filterDataWithNoValue, ...filterData];
        newData.sort((a, b) => {
            if (a.id < b.id) return -1;
            if (a.id > b.id) return 1;
            return 0;
        });
        setQuestionsFromData(newData);
    }

    useEffect(() => {
        if (data) {
            const labels: any = data["ConversationAnalytics"]["SpeakerLabels"] || [];
            const newSpeakerLabels: any = {
                NonTalkTime: "Silence",
                Interruptions: "Interruptions",
                Positive: "Positive",
                Negative: "Negative",
                Neutral: "Neutral"
            };
            labels.map((Speaker: any) => {
                newSpeakerLabels[Speaker["Speaker"]] = Speaker["DisplayText"];
            });
            
            setSpeakerLabels(newSpeakerLabels);
        }
    }, [data]);

    const isTranscribeCallAnalyticsMode =
    data?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
        ?.TranscribeApiType === "analytics";

    const createLoudnessData = (segment: any) => {
        const start = Math.floor(segment.SegmentStartTime);
        const end = Math.floor(segment.SegmentEndTime);
        const r = range(start, end);
        return r.map((item, i) => ({
            x: item,
            y: segment.LoudnessScores[i],
            interruption: segment.SegmentInterruption && item === start ? 100 : null,
            sentiment: (segment.SentimentIsNegative ? -5 : (segment.SentimentIsPositive && segment.LoudnessScores[i] > 0 ? 5 : 0)),
            sentimentScore: segment.SentimentScore,
            silence: (segment.LoudnessScores[i] === 0 ? true : false)
        }));
    };

    const createSentimentData = (segment: any) => {
        const start = Math.floor(segment.SegmentStartTime);
        const end = Math.floor(segment.SegmentEndTime);
        const r = range(start, end);
        return r.map((item, i) => ({
            x: item,
            y: (segment.SentimentIsNegative === 1 ? segment.SentimentScore * -1 : segment.SentimentScore)
        }));
    }

    const issuesTab = () => {
        return <div key='issuesTab'>
            {data?.ConversationAnalytics?.IssuesDetected?.length > 0 ? 
            data?.ConversationAnalytics?.IssuesDetected?.map((issue: any, j: any) => (
                <Tag key={j}
                style={{
                    "--highlight-colour": "yellow",
                }}
                >
                {issue.Text}
                </Tag>
            )) : <div>No issues detected.</div>
            }
        </div>
    }
    const actionItemsTab = () => {
    return <div key='actionItemsTab'>
        {data?.ConversationAnalytics?.ActionItemsDetected?.length > 0 ? 
        data?.ConversationAnalytics?.ActionItemsDetected?.map(
        (actionItem: any, j: any) => (
            <Tag key={j}
            style={{
                "--highlight-colour": "LightPink",
            }}
            >
            {actionItem.Text}
            </Tag>
        )
        ) : <div>No action items detected.</div>
        }
    </div>
    }
    
    const outcomesTab = () => {
    return <div key='outcomesTab'>
        {data?.ConversationAnalytics?.OutcomesDetected?.length > 0 ?
        data?.ConversationAnalytics?.OutcomesDetected?.map(
        (outcome: any, j: any ) => (
            <Tag key={j}
            style={{
                "--highlight-colour": "Aquamarine",
            }}
            >
            {outcome.Text}
            </Tag>
        )
        ): <div>No outcomes detected.</div>
    }
    </div>
    }

    const getSentimentTrends = (d: any, target: any, labels: any) => {
        const id = Object.entries(labels).find(([_, v]) => v === target)?.[0];
        if (!id) return {};
        return d?.ConversationAnalytics?.SentimentTrends[id];
    };

    const hasTranscribeStreamingSession =
    data?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
        ?.StreamingSession;

    const usedCustomLanguageModel =
    data?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
        ?.CLMName;

    
    const callDetailColumn = [
        {
            label: "Timestamp",
            value: (d: any) => d?.ConversationAnalytics?.ConversationTime.substring(0, 19),
        },
        { label: "Guid", value: (d: any) => d?.ConversationAnalytics?.GUID },
        { label: "Agent", value: (d: any) => d?.ConversationAnalytics?.Agent },
        {
            label: "Call Duration",
            value: (d: any) => Formatter.Time(d.ConversationAnalytics.Duration),
        },
        {
            label: "Entity Recognizer Name",
            value: (d: any) => d?.ConversationAnalytics?.EntityRecognizerName,
        },
        {
            label: "Language Model",
            value: (d: any) =>
                usedCustomLanguageModel
                ? d?.ConversationAnalytics?.LanguageCode + " [" +
                    d?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo?.CLMName + "]"
                : d?.ConversationAnalytics?.LanguageCode
        },
        {
            label: "Agent Sentiment",
            value: (d: any) => (
            <Sentiment
                score={getSentimentTrends(d, "Agent", speakerLabels)?.SentimentScore}
                trend={getSentimentTrends(d, "Agent", speakerLabels)?.SentimentChange}
            />
            ),
        },
        {
            label: "Customer Sentiment",
            value: (d: any) => (
            <Sentiment
                score={
                getSentimentTrends(d, "Customer", speakerLabels)?.SentimentScore
                }
                trend={
                getSentimentTrends(d, "Customer", speakerLabels)?.SentimentChange
                }
            />
            ),
        },
    ];

    const transcribeDetailColumn = [
        {
            label: "Type",
            value: (d: any) =>
            isTranscribeCallAnalyticsMode
                ? hasTranscribeStreamingSession
                ? "Transcribe Streaming Call Analytics"
                : "Transcribe Call Analytics"
                : hasTranscribeStreamingSession
                ? "Transcribe Streaming"
                : "Transcribe"
        },
        {
            label: "Job Id",
            value: (d: any) => (
            <div key='jobIdKey' className="text-break">
                {
                d?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
                    ?.TranscriptionJobName
                }
            </div>
            ),
        },
        {
            label: "File Format",
            value: (d: any) =>
            d?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
                ?.MediaFormat,
        },
        {
            label: "Sample Rate",
            value: (d: any) =>
            d?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
                ?.MediaSampleRateHertz,
        },
        {
            label: "PII Redaction",
            value: (d: any) =>
            d?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
                ?.RedactedTranscript === true
                ? "Enabled"
                : "Disabled"
        },
        {
            label: "Custom Vocabulary",
            value: (d: any) =>
            d?.ConversationAnalytics?.SourceInformation[0]?.TranscribeJobInfo
                ?.VocabularyName,
        },
        {
            label: "Vocabulary Filter",
            value: (d: any) =>
            d.ConversationAnalytics.SourceInformation[0]?.TranscribeJobInfo
                ?.VocabularyFilter,
        },
        {
            label: "Average Word Confidence",
            value: (d: any) =>
            Formatter.Percentage(
                d.ConversationAnalytics.SourceInformation[0]?.TranscribeJobInfo
                ?.AverageWordConfidence
            ),
        },
    ];

    const _handleUpdateComments = async () => {
        setIsLoading(true);

        let manualEvaluations = questionsFromData.map((item: any) => {
            return [
                item.question,
                item.comment
            ]
        });
        let manualQuestions = questionsFromData.map((item: any) => {
            return [
                item.question,
                item.score,
                item.score2,
                item.score3
            ]
        });
        
        let bodytoSend = {
            ManualQuestions: manualEvaluations,
            ManualEvaluations: manualQuestions
        }

        const _updateUserRoleById02 = async () => await di.get<UpdateAISummaryByRecordingUseCase>(UpdateAISummaryByRecordingUseCaseName)._aiRepository.updateAISumaryByRecord(recordURL, bodytoSend);
        const response = await _updateUserRoleById02();
        console.log(response);
        
        setIsLoading(false);

        if (isRight(response)) {
            if (response.right !== "ValidationError") {
                addToast("The evaluation has been stored", "success", undefined);
            } else {
                addToast("There was an error creating the evaluation", "error", undefined);
            }
        } 
        
        
    }

    useEffect(() => {
        firstCall();
    }, []);

    
    useEffect(() => {
    const loudness: any = {};

    if (isTranscribeCallAnalyticsMode) {
      // TCA mode
        let interruptions: any[] = [];
        let silence: any[] = [];
        let positive: any[] = [];
        let negative: any[] = [];
        let neutral: any[] = [];
        let nonSilence: any[] = [];

        Object.keys(speakerLabels).forEach(key => {
            let keyLoudness = (data?.SpeechSegments || [])
            .filter((segment: any) => segment.SegmentSpeaker === key)
            .map(createLoudnessData)
            .flat();
            
            loudness[key] = keyLoudness;
            let newInterruptions = keyLoudness.filter((d: any) => d.interruption)
                .map((d: any) => ({ y: d.interruption, x: d.x }))
            interruptions = interruptions.concat(newInterruptions)

            let newSilence = keyLoudness.filter((d: any) => d.silence)
                .map((d: any) => ({ x: d.x, y: 100 }))
            silence = silence.concat(newSilence);

            keyLoudness.forEach((item: any) => {
                let sentimentItem = {
                x: item.x,
                y: 10,
                sentiment: item.sentiment
            };
            if (item.sentiment > 0) positive.push(sentimentItem)
            else if (item.sentiment < 0) negative.push(sentimentItem)
            else neutral.push(sentimentItem);
            nonSilence[item.x.toString()] = sentimentItem;
            });

        });
    
    // generate the rest of the silence
    if (data) {
        const r = range(0, parseInt(data?.ConversationAnalytics.Duration));
        r.map((item, i) => {
            if (!(i in nonSilence)) {
            silence = silence.concat({ x: i, y: 100 });
            }
        });
    }

        loudness['Interruptions'] = interruptions;
        loudness['NonTalkTime'] = silence;
        loudness['Positive'] = positive;
        loudness['Neutral'] = neutral;
        loudness['Negative'] = negative;
    } else {
      // this is transcribe standard
        Object.keys(speakerLabels).forEach(key => {
            if (key.indexOf('spk_') >= 0) {
                let keyLoudness = (data?.SpeechSegments || [])
                .filter((segment: any) => segment.SegmentSpeaker === key)
                .map(createSentimentData)
                .flat();
                loudness[key] = keyLoudness;
                }
            });
    }
        setLoudnessData(loudness);
    }, [speakerLabels])
    
    return (
        <div style={{padding: "20px"}}>
            <ContentLayout 
                header={
                <Header
                    variant="h1"
                >
                    <p style={{margin: "20px"}}>
                        Call Details
                    </p>
                </Header>
                }>
            <div style={{ backgroundColor: "white", padding: "0px 20px 0px 20px", borderRadius: "5px" }}>
                <Grid
                        gridDefinition={[
                            { colspan: { l: 4, m: 4, default: 12 } },
                            { colspan: { l: 4, m: 4, default: 12 } },
                            { colspan: { l: 4, m: 4, default: 12 } }
                        ]}
                    >
                        <Container
                            fitHeight={true}
                            header={
                                <Header variant="h2">
                                    Call Metadata
                                </Header>
                            }
                        >
                            <SpaceBetween size="m">
                                {callDetailColumn
                                    .filter(entry => entry.label !== "Entity Recognizer Name")
                                    .map((entry, j) => (
                                        <ValueWithLabel key={j} label={entry.label}>
                                            {!data ? (
                                                <></>
                                            ) : (
                                                entry.value(data) || "-"
                                            )}
                                        </ValueWithLabel>
                                    ))}
                            </SpaceBetween>
                        </Container>
                        <Container
                            fitHeight={true}
                            header={
                                <Header variant="h2">
                                Transcribe Details
                                </Header>
                            }
                            >
                            <SpaceBetween size="m">
                                {transcribeDetailColumn.map((entry, i) => (
                                <ValueWithLabel key={i} label={entry.label}>
                                    {!data ? (
                                        <></>
                                    ) : (
                                    entry.value(data) || "-"
                                    )}
                                </ValueWithLabel>
                                ))}
                            </SpaceBetween>
                            </Container>
                        <Container
                            header={
                                <Header variant="h2">
                                Sentiment
                                </Header>
                            }>
                            <SentimentChart
                                data={data?.ConversationAnalytics?.SentimentTrends}
                                speakerOrder={speakerLabels}
                                />
                                <Header variant="h2">Speaker Time</Header>
                                <SpeakerTimeChart
                                data={Object.entries(
                                    data?.ConversationAnalytics?.SpeakerTime || {}
                                ).map(([key, value]) => {
                                    const speakerTime = value as SpeakerTime; // Type assertion
                                    return {
                                        value: speakerTime.TotalTimeSecs,
                                        label: speakerLabels[key],
                                        channel: key
                                    };
                                })}
                            />
                        </Container>
                </Grid>
                <br />
                {isTranscribeCallAnalyticsMode && (
                        <Container
                            header={
                            <Header variant="h2">
                                Loudness/Sentiment
                            </Header>
                            }
                        >
                            {!loudnessData ? (
                            <div key='noSpeakers'>No Speakers</div>
                            ) : (
                            <LoudnessChart loudnessData={loudnessData} speakerLabels={speakerLabels} />
                            )}
                        </Container>
                )}
                <br />
                <Grid
                    gridDefinition={[
                        { colspan: { l: 6, m: 6, default: 12 } },
                        { colspan: { l: 6, m: 6, default: 12 } },
                        { colspan: { l: 6, m: 6, default: 12 } },
                        { colspan: { l: 6, m: 6, default: 12 } },
                        
                    ]}
                >
                    
                    <Container 
                        fitHeight={true}
                        header={
                            <Header variant="h2">
                            Entities
                            </Header>
                        }
                        >
                        {!data ? (
                            <></>
                        ) : (
                            <Entities data={data?.ConversationAnalytics?.CustomEntities} />
                        )}
                    </Container>
                    {isTranscribeCallAnalyticsMode && (
                        <Container
                            fitHeight={true}
                            header={
                            <Header variant="h2">
                                Categories
                            </Header>
                        }>
                            {!data ? (
                            <></>
                            ) : (
                            <ListItems
                                data={data?.ConversationAnalytics?.CategoriesDetected.map(
                                (category: any) => category.Name
                                )}
                            />
                            )}
                        </Container>
                        )}
                        {isTranscribeCallAnalyticsMode && (
                            <Container
                                fitHeight={true}
                                header={
                                    <Header variant="h2">
                                    Call Analytics Summary
                                    </Header>
                                }
                            >
                                {!data ? (
                                <h4>No summary available.</h4>
                                ) : (
                                    <SpaceBetween size="l">
                                    <ValueWithLabel key='issues' label="Issue">
                                        {issuesTab()}
                                    </ValueWithLabel>
                                    <ValueWithLabel key='actionItems' label="Action Items">
                                        {actionItemsTab()}
                                    </ValueWithLabel>
                                    <ValueWithLabel key='outcomes' label="Outcomes">
                                        {outcomesTab()}
                                    </ValueWithLabel>
                                    </SpaceBetween>
                            )}
                            </Container>
                        )}
                    <Container
                        fitHeight={true}
                        header={<Header variant="h2">Generative AI Insights (summary)</Header>}
                    >
                        <SpaceBetween size="m">
                            {summaryFromData.length > 0 ? (
                                <ValueWithLabel>{summaryFromData}</ValueWithLabel>
                            ) : (
                                <ValueWithLabel key="nosummary">No Summary Available</ValueWithLabel>
                            )}
                        </SpaceBetween>
                    </Container>
                    <Container
                        header={
                        <Header variant="h2">
                            Generative AI Insights (questions)
                        </Header>}
                    >
                        {questionsFromData.length > 0 ? (
                            <>
                            {
                                questionsFromData?.map((q: any) => {
                                    return(
                                    <div style={{display: 'flex', marginBottom: "10px", border: "1px solid #dcdcdc", padding: "8px", justifyContent: "space-between"}}>
                                        <div key={q.id}>
                                            <div style={{ fontWeight: "bold" }}>{q.question}</div>
                                            {q.response}
                                            <div className='ai_score'>
                                                <span className='ai_score_label'>AI Score: </span>{`${q.aiScore}`}
                                            </div>
                                        </div>
                                        
                                        <div style={{marginLeft: "20px", minWidth:'40%'}}>
                                            <div>
                                                Comment:
                                                <input className='ai_manual_comment' type='text' style={{width: '100%'}}
                                                    value={q.comment}
                                                    onChange={(e) => _handleCommentChange(e.target.value, q.question.replaceAll(" ", "").toLocaleLowerCase())}
                                                />
                                            </div>
                                            <div style={{marginTop: '10px'}}>
                                                Manual Score: <input className='ai_manual_score' type="number" style={{width: "50px"}} min="0" max="10" value={
                                                    q.score
                                                }
                                                onChange={(e) => _handleScoreChange(e.target.value, q.question.replaceAll(" ", "").toLocaleLowerCase())} /> Range(0-10)
                                            </div>
                                        </div>
                                        </div>
                                )})
                            }
                            {
                                user?.permissions?.aiEvaluation.update &&
                                <>
                                {
                                    !isLoading ?
                                    <ButtonComponent text={"Update"} type={ButtonType.MAIN} onClick={_handleUpdateComments} icon={<Icons.Plus />} /> 
                                    :
                                    <LoadingComponent />
                                }
                                </>
                                    
                            }
                            
                            </>
                        ) : (
                            <ValueWithLabel key="noquestions">No Questions Available</ValueWithLabel>
                        )}

                    
                    </Container>
                    <Container
                        header={
                            <Header
                            variant="h2"
                            actions={
                                <SpaceBetween 
                                direction="horizontal"
                                size="xs"
                                >
                                {data && (
                                    <audio
                                    key='audoiElem'
                                    className="float-end"
                                    controls
                                    src={
                                        data["ConversationAnalytics"]["SourceInformation"][0]
                                        ["TranscribeJobInfo"]["MediaFileUri"]
                                    }
                                    >
                                    Your browser does not support the
                                    <code>audio</code> element.
                                    </audio>
                                )}
                                </SpaceBetween>
                            }
                            >
                            </Header>

                    }>
                        <div>
                            {!data? (
                                <></>
                            ) : (
                                (data["SpeechSegments"] || []).map((s: any, i: any) => {
                                    
                                    return(
                                        <TranscriptSegment
                                    key={i}
                                    name={speakerLabels[s.SegmentSpeaker]}
                                    allSegments={s?.WordConfidence || []}
                                    segmentStart={s.SegmentStartTime}
                                    text={s.DisplayText}
                                    onClick={()=>{}}
                                    highlightLocations={[
                                    ...s.EntitiesDetected.map((e: any) => ({
                                        start: e.BeginOffset,
                                        end: e.EndOffset,
                                        fn: (match: any, key: any, start: any, end: any, offsetStart: any, offsetEnd: any) => (
                                        <TranscriptOverlay
                                            key={key}
                                            colour={{}}
                                            visuallyHidden={`Entity - ${e.Type}`}
                                            data-start={start}
                                            data-end={end}
                                            data-offset-start={offsetStart}
                                            data-offset-end={offsetEnd}
                                            content={match}
                                            type={""}
                                            entityOffsetStart={e.BeginOffset}
                                            entityOffsetEnd={e.EndOffset}
                                            entityClass={"text-danger"}
                                            addType={offsetStart === e.BeginOffset ? true : false}
                                        >
                                        </TranscriptOverlay>
                                        ),
                                    })),
                                    ...(s.IssuesDetected? s.IssuesDetected?.map((issue: any) => ({
                                        start: issue.BeginOffset,
                                        end: issue.EndOffset,
                                        fn: (match: any, key: any, start: any, end: any, offsetStart: any, offsetEnd: any) => (
                                        <TranscriptOverlay
                                            key={key}
                                            colour="#ffff00"
                                            tooltip="Issue"
                                            data-start={start}
                                            data-end={end}
                                            data-offset-start={offsetStart}
                                            data-offset-end={offsetEnd}
                                            content={match}
                                            type={"Issue"}
                                            entityOffsetStart={issue.BeginOffset}
                                            entityOffsetEnd={issue.EndOffset}
                                            entityClass={"text-danger"}
                                            addType={offsetStart === issue.BeginOffset ? true : false}
                                        >
                                        </TranscriptOverlay>
                                        ),
                                    })) : []),
                                    ...(s.ActionItemsDetected? s.ActionItemsDetected?.map((issue: any) => ({
                                        start: issue.BeginOffset,
                                        end: issue.EndOffset,
                                        fn: (match: any, key: any, start: any, end: any, offsetStart: any, offsetEnd: any) => (
                                        <TranscriptOverlay
                                            key={key}
                                            colour="lightpink"
                                            tooltip="Action Item"
                                            data-start={start}
                                            data-end={end}
                                            data-offset-start={offsetStart}
                                            data-offset-end={offsetEnd}
                                            content={match}
                                            type={"Action Item"}
                                            entityOffsetStart={issue.BeginOffset}
                                            entityOffsetEnd={issue.EndOffset}
                                            entityClass={"text-danger"}
                                            addType={offsetStart === issue.BeginOffset ? true : false}
                                        >
                                        </TranscriptOverlay>
                                        ),
                                    })) : []),
                                    ...(s.OutcomesDetected? s.OutcomesDetected?.map((issue: any) => ({
                                        start: issue.BeginOffset,
                                        end: issue.EndOffset,
                                        fn: (match: any, key: any, start: any, end: any, offsetStart: any, offsetEnd: any) => (
                                        <TranscriptOverlay
                                            key={key}
                                            colour="aquamarine"
                                            tooltip="Outcome"
                                            data-start={start}
                                            data-end={end}
                                            data-offset-start={offsetStart}
                                            data-offset-end={offsetEnd}
                                            content={match}
                                            type={"Outcome"}
                                            entityOffsetStart={issue.BeginOffset}
                                            entityOffsetEnd={issue.EndOffset}
                                            entityClass={"text-danger"}
                                            addType={offsetStart === issue.BeginOffset ? true : false}
                                        >
                                        </TranscriptOverlay>
                                        ),
                                    })) : []),
                                    ]}
                                    score={s.SentimentIsPositive - s.SentimentIsNegative}
                                    interruption={s.SegmentInterruption}
                                    ivr={s?.IVRSegment || false}
                                    categoryList={s.CategoriesDetected}
                                />
                                    )
                                })
                            )}
                    </div>

        </Container>
                        
                </Grid>
            </div>
            </ContentLayout>
        </div>
    );
};


export default AiSummaryPage;
