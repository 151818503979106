import { useContext } from "react";
import KeyWordLocalization from "../providers/localization/dictionaries/KeyWordLocalization";
import LocalizationContext from "../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../domain/providers/localization/LocalizationContextType";

export interface ValidatorProps {
    required?: boolean | undefined,
    email?: boolean | undefined,
    minLength?: number | undefined,
    maxLength?: number | undefined,
    minValue?: number | undefined,
    maxValue?: number | undefined,
    minTime?: string | undefined,
    maxTime?: string | undefined,
    mustBeNumber?: boolean | undefined,
    specialCharacterRequired?: boolean | undefined,
    uppercaseRequired?: boolean | undefined,
    lowercaseRequired?: boolean | undefined,
    numberRequired?: boolean | undefined,
    mustBeEqual?: string | undefined,
    noSpecialCharacter?: boolean | undefined,
    noUpperCase?: boolean | undefined,
    noLowerCase?: boolean | undefined,
    noNumber?: boolean | undefined,
    name?: boolean | undefined,
    phone?: boolean | undefined,
    isPassword?: boolean | undefined,
    pattern?: RegExp | undefined,
    validate?: Function | undefined,
    onChange?: Function | undefined,
    greaterThan?: number | Date | undefined,
    greaterOrEqualThan?: number | Date | undefined,
    lessThan?: number | Date | undefined
}

const convertAmPmTo24 = (time: string): string => {
    try {
        //first check if is AM PM format
        if (time.indexOf("AM") === -1 && time.indexOf("PM") === -1) return time;
        let hours = parseInt(time.split(":")[0]);
        let minutes = parseInt(time.split(":")[1].split(" ")[0]);
        let ampm = time.split(" ")[1];
        if (ampm === "PM") {
            hours += 12;
        }
        return `${hours}:${minutes}`;

    } catch (error) {
        return time;
    }
}
const Validators = (_: ValidatorProps): any => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { minTime, maxTime, required, minLength, maxLength, pattern, validate, email, onChange, minValue, maxValue, mustBeNumber, specialCharacterRequired, uppercaseRequired, lowercaseRequired, numberRequired, mustBeEqual, noSpecialCharacter, noUpperCase, noLowerCase, noNumber, name, phone, isPassword, greaterThan, greaterOrEqualThan, lessThan } = _;
    let validator = {};
    let validateInside = {};

    if (required == true) {
        validator = { ...validator, required: i18n(KeyWordLocalization.ValidatorRequired) };
    }

    if (email) {
        validateInside = {
            ...validateInside,
            mustHavePartBeforeAt: (value: string) => value.indexOf("@") > 0 ? null : i18n(KeyWordLocalization.ValidatorMustHavePartBeforeAt),
        };
        validateInside = {
            ...validateInside,
            email: (value: string) => value.match(/^[a-zA-Z0-9.a-zA-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[a-zA-Z0-9]+\.[a-zA-Z]+/g) ? null : i18n(KeyWordLocalization.ValidatorEmail),
        };

    }

    if (minLength) {
        validator = {
            ...validator, minLength: {
                value: minLength,
                message: i18n(KeyWordLocalization.ValidatorMinLength, { minLength: minLength })
            }
        };
    }

    if (maxLength) {
        validator = {
            ...validator, maxLength: {
                value: maxLength,
                message: i18n(KeyWordLocalization.ValidatorMaxLength, { maxLength: maxLength })
            }
        };
    }

    if (minValue) {
        validator = {
            ...validator, min: {
                value: minValue,
                message: i18n(KeyWordLocalization.ValidatorMinValue, { min: minValue })
            }
        };
    }

    if (maxValue) {
        validator = {
            ...validator, max: {
                value: maxValue,
                message: i18n(KeyWordLocalization.ValidatorMaxValue, { max: maxValue })
            }
        };
    }

    if (mustBeNumber) {
        validateInside = {
            ...validateInside,
            mustBeNumber: (value: string) => value.match(/(?=.*[0-9])/g) ? null : i18n(KeyWordLocalization.ValidatorMustBeNumber),
        };
    }

    if (specialCharacterRequired || isPassword) {
        validateInside = {
            ...validateInside,
            specialCharacterRequired: (value: string) => value.match(/(?=.*[\^$*.[\]{}()?"!@#%&/\\,><'+r':;|_~`=+\- ])/g) ? null : i18n(KeyWordLocalization.ValidatorSpecialCharactersIsRequired),
        };
    }

    if (uppercaseRequired || isPassword) {
        validateInside = {
            ...validateInside,
            uppercaseRequired: (value: string) => value.match(/(?=.*[A-Z])/g) ? null : i18n(KeyWordLocalization.ValidatorUpperCaseIsRequired),
        };
    }

    if (lowercaseRequired || isPassword) {
        validateInside = {
            ...validateInside,
            lowercaseRequired: (value: string) => value.match(/(?=.*[a-z])/g) ? null : i18n(KeyWordLocalization.ValidatorLowerCaseIsRequired),
        };
    }

    if (numberRequired || isPassword) {
        validateInside = {
            ...validateInside,
            numberRequired: (value: string) => value.match(/(?=.*[0-9])/g) ? null : i18n(KeyWordLocalization.ValidatorNumberIsRequired),
        };
    }

    if (noSpecialCharacter || name) {
        validateInside = {
            ...validateInside,
            noSpecialCharacter: (value: string) => !value.match(/^((?![\{}()?"!@#%&/\\,.:;|_~`=+$\><]).)*$/g) ? i18n(KeyWordLocalization.ValidatorSpecialCharacterIsNotAllowed) : null,
        };
    }

    if (greaterThan) {
        validateInside = {
            ...validateInside,
            //compare numbers, string, etc
            greaterThan: (value: any) => value <= greaterThan ? i18n(KeyWordLocalization.ValidatorGreaterThan, { "value": greaterThan }) : null
        };
    }

    if(greaterOrEqualThan){
        validateInside = {
            ...validateInside,
            //compare numbers, string, etc
            greaterOrEqualThan: (value: any) => value < greaterOrEqualThan ? i18n(KeyWordLocalization.ValidatorGreaterOrEqualThan, { "value": greaterOrEqualThan }) : null
        };
    }

    if (lessThan) {
        validateInside = {
            ...validateInside,
            //compare numbers, string, etc
            lessThan: (value: any) => value >= lessThan ? i18n(KeyWordLocalization.ValidatorLessThan, { "value": lessThan }) : null
        };
    }

    if(minTime) {
        validateInside = {
            ...validateInside,
            minTime: (value: any) => convertAmPmTo24(value) >= minTime ? null : i18n(KeyWordLocalization.ValidatorMinTime, { "value": minTime })
        };
    }

    if(maxTime){
        validateInside = {
            ...validateInside,
            maxTime: (value: any) => convertAmPmTo24(value) <= maxTime ? null : i18n(KeyWordLocalization.ValidatorMaxTime, { "value": maxTime })
        };
    }

    if (noUpperCase) {
        validateInside = {
            ...validateInside,
            noUpperCase: (value: string) => value.match(/^((?![A-Z]).)*$/g) ? i18n(KeyWordLocalization.ValidatorUpperCaseIsNotAllowed) : null,
        };
    }

    if (noLowerCase) {
        validateInside = {
            ...validateInside,
            noLowerCase: (value: string) => value.match(/^((?![a-z]).)*$/g) ? i18n(KeyWordLocalization.ValidatorLowerCaseIsNotAllowed) : null,
        };
    }

    if (noNumber || name) {
        validateInside = {
            ...validateInside,
            noNumber: (value: string) => /\d/.test(value) ? i18n(KeyWordLocalization.ValidatorNumberIsNotAllowed) : null,
        };
    }


    if (mustBeEqual) {
        validateInside = {
            ...validateInside,
            mustBeEqual: (value: string) => value === mustBeEqual ? null : i18n(KeyWordLocalization.ValidatorMustBeEqual, { mustBeEqual: mustBeEqual }),
        };
    }

    if (phone) {
        validator = {
            ...validator, minLength: {
                value: 5,
                message: i18n(KeyWordLocalization.ValidatorMinLength, { minLength: 5 })
            }
        };

        validator = {
            ...validator, maxLength: {
                value: 13,
                message: i18n(KeyWordLocalization.ValidatorMaxLength, { maxLength: 13 })
            }
        };

        validateInside = {
            ...validateInside,
            phone: (value: string) => value.match(/^(?:[0-9] ?){5,13}[0-9]$/g) ? null : i18n(KeyWordLocalization.ValidatorIsNotPhone),
        };
    }

    if (isPassword) {
        validator = {
            ...validator, minLength: {
                value: 6,
                message: i18n(KeyWordLocalization.ValidatorMinLength, { minLength: 6 })
            }
        };

        validator = {
            ...validator, maxLength: {
                value: 20,
                message: i18n(KeyWordLocalization.ValidatorMaxLength, { maxLength: 20 })
            }
        };
    }

    if (pattern) {
        validator = { ...validator, pattern: pattern };
    }

    if (validate) {
        validateInside = { ...validateInside, validate: validate };
    }

    if (onChange) {
        validator = { ...validator, onChange: onChange };
    }

    validator = { ...validator, validate: validateInside };
    return validator;
}

Validators.defaultProps = {
    required: undefined,
    email: undefined,
    minValue: undefined,
    maxValue: undefined,
    mustBeNumber: undefined,
    specialCharacterRequired: undefined,
    uppercaseRequired: undefined,
    lowercaseRequired: undefined,
    numberRequired: undefined,
    noSpecialCharacter: undefined,
    noUpperCase: undefined,
    noLowerCase: undefined,
    noNumber: undefined,
    mustBeEqual: undefined,
    phone: undefined,
    isPassword: undefined,
    name: undefined,
    minLength: undefined,
    maxLength: undefined,
    pattern: undefined,
    validate: undefined,
    onChange: undefined,
};

export default Validators;

// export default (_: any): any => {};