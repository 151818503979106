import { useNavigate } from 'react-router-dom';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import Icons from '../../../assets/Icons';
import ButtonComponent from '../../../components/button/ButtonComponent';
import { ButtonType } from '../../../components/button/ButtonComponentProps';
import CalendarWeekFormComponent from '../../../components/forms/calendarWeekForm/CalendarWeekFormComponent';
import { DateOperations } from '../../../utils/DateOperations';
import './DataLoaderPageStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import readXlsxFile from 'read-excel-file';
import di from '../../../../di/DependencyInjection';
import MastersContext from '../../../../domain/providers/master/MastersContext';
import MastersContextType from '../../../../domain/providers/master/MastersContextType';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import GetAllTypeOfEventsUseCase, { GetAllTypeOfEventsUseCaseName } from '../../../../domain/use_cases/calendar/GetAllTypeOfEventsUseCase';
import ShiftEntity from '../../../../domain/entities/ShiftEntity';
import DateParse from '../../../utils/DateParse';
import DataLoaderTableComponent from './components/dataLoaderTable/DataLoaderTableComponent';
import EventScheduleEntity from '../../../../domain/entities/EventScheduleEntity';
import AsignShiftBulkImportUseCase, { AsignShiftBulkImportUseCaseName } from '../../../../domain/use_cases/shift/AsignShiftBulkImportUseCase';
import { AsingShiftBulkImportResponse } from '../../../../domain/repositories/ShiftRepository';
import { isLeft } from 'fp-ts/lib/Either';
import DataLoaderSpinnerComponent from './components/dataLoaderSpinner/DataLoaderSpinnerComponent';


// Function to validate the format
function validateFormat(data: any[]) {

    const encounteredRows = new Set();

    for (const row of data) {
        // Check for duplicated row
        const rowString = row.join();
        if (encounteredRows.has(rowString)) {
            return { validation: false, message: "Duplicate row found" }; // Duplicate row found
        } else {
            encounteredRows.add(rowString);
        }

        if (row.length < 9) {
            return { validation: false, message: "Empty cell found" }; // Empty cell found
        }

        // Check email format
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(row[0])) {
            return { validation: false, message: "Invalid email format found" }; // Invalid email format
        }

        // Check code format
        const validCodes = ["Work Time", "Break", "Lunch", "Support", "Meeting", "Training", "Personal Time", "Paid Time Off", "Medical Leave"];
        if (!validCodes.includes(row[1])) {
            return { validation: false, message: "Invalid code found" }; // Invalid code
        }

        // Regular expression to match time in HH:MM-HH:MM format or "OFF"
        const timeRegex = /^(\d{2}:\d{2}-\d{2}:\d{2}|OFF|off)$/;

        // Check time format for each day
        for (let i = 2; i < row.length; i++) {
            if (!timeRegex.test(row[i])) {
                return { validation: false, message: `Invalid time format found` }; // Invalid time format
            } else if (row[i].toLowerCase() !== "off") {
                const [startTime, endTime] = row[i].split("-");
                const [startHour, startMinute] = startTime.split(":").map(Number);
                const [endHour, endMinute] = endTime.split(":").map(Number);

                // Check if the second hour is numerically greater than the first
                if (endHour < startHour || (endHour === startHour && endMinute <= startMinute)) {
                    return { validation: false, message: `Invalid time format found` };
                }
            }
        }
    }
    return { validation: true, message: "Valid format" }; // All entries are valid
}

const DataLoaderPage: FC<{}> = () => {


    const [currentWeek, setCurrentWeek] = useState<Date[]>(DateOperations.getWeekDays(new Date())); //undefined: loading, null: not found
    const [shiftsToLoad, setShiftsToLoad] = useState<{ shifts: ShiftEntity[], email: string, error?: string }[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const { addToast } = useContext(ModalsContext) as ModalsContextType;
    const { typeSchedules, workTime } = useContext(MastersContext) as MastersContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [loaderCounter, setLoaderCounter] = useState(-1);
    const navigate = useNavigate();


    const _parseDataToShifts = (data: string[][]) => {
        try {
            const shiftsParsed: { shifts: ShiftEntity[], email: string, error?: string }[] = [];
            const groupByEmail: any = {};
            const convertToEvent = (code: string, startHour: string, endHour: string, dayIndex: number): EventScheduleEntity => {
                const type = typeSchedules.find((type) => type.name.toLowerCase() === code.toLowerCase());
                const copyDate = new Date(currentWeek[dayIndex]);
                copyDate.setHours(Number(startHour.split(":")[0]), Number(startHour.split(":")[1]));
                const copyEndDate = new Date(currentWeek[dayIndex]);
                copyEndDate.setHours(Number(endHour.split(":")[0]), Number(endHour.split(":")[1]));
                if (copyDate > copyEndDate) copyEndDate.setDate(copyEndDate.getDate() + 1);

                const parsed: EventScheduleEntity = {
                    id: '',
                    name: type!.name ?? '',
                    dateStart: copyDate,
                    dateEnd: copyEndDate,
                    type: type!,
                }

                return parsed;
            }
            for (const row of data) {
                const email = row[0];
                if (!groupByEmail[email]) {
                    groupByEmail[email] = [];
                }
                const code = row[1];
                for (let i = 2; i < row.length; i++) {
                    if (row[i].toLowerCase() !== "off") {
                        const [start, end] = row[i].split("-");
                        groupByEmail[email].push(convertToEvent(code, start, end, i - 2));
                    }
                }
            }


            //now its converted so we need to convert to shifts, first for every email we have to sort the events by date and if between two events there is a gap we have to create a shift
            for (const email in groupByEmail) {
                const events = groupByEmail[email].sort((a: EventScheduleEntity, b: EventScheduleEntity) => a.dateStart.getTime() - b.dateStart.getTime());
                const shiftsCreated: ShiftEntity[] = [];

                if (events.length === 0) {
                    continue;
                }
                const firstEvent = events[0];
                let shiftCreating: ShiftEntity = {
                    id: '',
                    initHour: firstEvent.dateStart,
                    endHour: firstEvent.dateEnd,
                    events: [firstEvent],
                    shiftGroup: '',
                    users: [],
                }
                for (let i = 0; i < events.length; i++) {
                    const event = events[i];
                    if (event.dateStart.getTime() > shiftCreating.endHour.getTime()) {
                        shiftsCreated.push(shiftCreating);
                        shiftCreating = {
                            ...shiftCreating,
                            initHour: event.dateStart,
                            endHour: event.dateEnd,
                            events: [event]
                        };

                    } else {
                        shiftCreating.endHour = event.dateEnd.getTime() > shiftCreating.endHour.getTime() ? event.dateEnd : shiftCreating.endHour;
                        shiftCreating.events.push(event);
                    }
                    if (i === events.length - 1) {
                        shiftsCreated.push(shiftCreating);
                    }
                }

                const filtredEvents = shiftsCreated.map((shift) => {
                    shift.events = shift.events.filter((event) => event.type.name.toLowerCase() != workTime?.name.toLowerCase());
                    return shift;
                });
                shiftsParsed.push({ shifts: filtredEvents, email });
            }


            const sortShifts = shiftsParsed.sort((a, b) => a.email.localeCompare(b.email));

            setShiftsToLoad(sortShifts);
        } catch (e) {
        }
    }
    const handleFileUpload = (file: File) => {
        setShiftsToLoad([]);
        readXlsxFile(file)
            .then((rows: any[]) => {
                const data: string[][] = [];
                for (let i = 1; i < rows.length; i++) {
                    const rowData: string[] = [];
                    for (let j = 0; j < Math.min(9, rows[i].length); j++) {
                        if (rows[i][j] === null) {
                            break;
                        }
                        rowData.push(rows[i][j]);
                    }
                    if (rowData.length > 0) {
                        data.push(rowData);
                    }
                }
                const validationCheck = validateFormat(data);
                if (validationCheck.validation === false) {
                    addToast(i18n(validationCheck.message), 'error', 5000);
                } else {
                    _parseDataToShifts(data);
                }

            })
            .catch((error: Error) => {
                addToast("Invalid file format. Please upload an Excel file.", 'error', 5000);
            });

    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(false);
        const file = event.dataTransfer.files[0];
        if (file) {
            handleFileUpload(file);
        }
    };

    const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDrageLeave = (event: React.DragEvent<HTMLDivElement>) => {
        setIsDragging(false);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files ? event.target.files[0] : null;
        if (file) {
            handleFileUpload(file);
        }
    };

    const _clearTable = () => {
        setShiftsToLoad([]);
    }

    const _loadTypeSchedules = async () => {
        await di.get<GetAllTypeOfEventsUseCase>(GetAllTypeOfEventsUseCaseName).call();
    }

    const _handleAsignShift = async () => {
        const _updateLoaderCounter = (data: AsingShiftBulkImportResponse) => {
            setLoaderCounter(data.doneEmails.length + data.failedEmails.length);
        }
        setLoaderCounter(0);
        const response = await di.get<AsignShiftBulkImportUseCase>(AsignShiftBulkImportUseCaseName).call(shiftsToLoad, _updateLoaderCounter)
        setLoaderCounter(-1);
        if (isLeft(response)) return addToast("An error occurred while assigning shifts. Check the logs for more information", 'error', 5000);
        if (response.right.failedEmails.length > 0) {
            //remove shifts done from the list
            const shiftsToLoadFiltered = shiftsToLoad.map((shift) => {
                const failedEmail = response.right.failedEmails.find((email) => email.email === shift.email);
                if (failedEmail) {
                    return { ...shift, error: failedEmail.error };
                } else {
                    return undefined;
                }
            }).filter((shift) => shift !== undefined) as { shifts: ShiftEntity[], email: string, error?: string }[];
            setShiftsToLoad(shiftsToLoadFiltered);
            if (response.right.doneEmails.length == 0) {
                addToast("The shifts could not be assigned. Check the logs for more information", 'error', 5000);
            } else {
                addToast(`${response.right.doneEmails.length} of ${response.right.doneEmails.length + response.right.failedEmails.length} Shifts assigned successfully`, 'warning', 5000);
            }
        } else {
            setShiftsToLoad([]);
            addToast("Shifts assigned successfully", 'success', 5000);
        }
    }

    const _handleChangeWeek = (week: Date[]) => {
        const copyShiftsToLoad = [...shiftsToLoad];
        copyShiftsToLoad.forEach((shiftToLoad) => {
            shiftToLoad.shifts.forEach((shift) => {
                const initHour = new Date(week[DateOperations.getDayFromMonday(shift.initHour)]);
                initHour.setHours(shift.initHour.getHours(), shift.initHour.getMinutes(), 0, 0);
                const endHour = new Date(week[DateOperations.getDayFromMonday(shift.endHour)]);
                endHour.setHours(shift.endHour.getHours(), shift.endHour.getMinutes(), 0, 0);
                shift.initHour = initHour;
                shift.endHour = endHour;

                //do the dame for the events
                shift.events.forEach((event) => {
                    const eventDateStart = new Date(week[DateOperations.getDayFromMonday(event.dateStart)]);
                    eventDateStart.setHours(event.dateStart.getHours(), event.dateStart.getMinutes(), 0, 0);
                    const eventDateEnd = new Date(week[DateOperations.getDayFromMonday(event.dateEnd)]);
                    eventDateEnd.setHours(event.dateEnd.getHours(), event.dateEnd.getMinutes(), 0, 0);
                    event.dateStart = eventDateStart;
                    event.dateEnd = eventDateEnd;
                });
            });
        });
        console.log('week parsed', {week, shiftsToLoad, copyShiftsToLoad});
        setShiftsToLoad(copyShiftsToLoad);
        setCurrentWeek(week);
    }

    useEffect(() => {
        _loadTypeSchedules();
    }, []);

    function downloadFile() {
        const fileUrl = 'https://itelligence.s3.amazonaws.com/settings/bulk_import_template.xlsx';
        const fileName = 'bulk_import_template.xlsx';
        // Create an anchor element
        const anchor = document.createElement('a');
        anchor.href = fileUrl;
        anchor.setAttribute('download', fileName);

        // Programmatically trigger a click on the anchor
        document.body.appendChild(anchor);
        anchor.click();

        // Clean up
        document.body.removeChild(anchor);
    }

    return <div className="data_loader" onDragOver={handleDragOver} onDragLeave={handleDrageLeave} onDrop={handleDrop} >
        <DataLoaderSpinnerComponent loading={loaderCounter != -1} current={loaderCounter} total={shiftsToLoad.length} />
        {isDragging && loaderCounter == -1 && <div className="dragging_overlay">
            <p>DROP YOUR XSLX FILE HERE TO UPLOAD IT
                <br />
                <Icons.Download height={50} width={50} />
            </p>
        </div>}
        <div className="page_content" id='scrollable_container'>
            <div className='header_content_loader'>
                <p className='title_content_loader'>Dear user, please paste the shifts you want to schedule in the following table considering the following descriptions for each column</p>
                <p><span className='title_content_loader'>- itel email:</span> Corresponds to the agent's itel email address.</p>
                <p><span className='title_content_loader'>- Code:</span> Corresponds to the type of activity being scheduled. It can contain values such as Work Time, Lunch, Break, Training, Support, Meeting, Paid Time Off, Personal Time, or Medical Leave.</p>
                <p><span className='title_content_loader'>- Monday to Sunday:</span> Start and end time of the day's activity separated by a hyphen and in 24-hour format (no AM or PM). If it is the agent's day off, it will be scheduled as OFF.</p>
            </div>
            <div className='body_content_loader'>
                <div className="d-flex justify-content-between flex-wrap">
                    <CalendarWeekFormComponent onChange={_handleChangeWeek} selectedWeek={currentWeek} />
                    <div style={{ width: "fit-content", display: "flex", marginRight: "20px" }}>
                        <ButtonComponent text={"Download Excel"} type={ButtonType.MAIN} onClick={() => downloadFile()} icon={<Icons.Download />} />
                        <div style={{ width: "10px" }}></div>
                        <div className="input_select_excel">
                            <input
                                id="fileInput"
                                type="file"
                                value={""}
                                onChange={handleInputChange}
                                accept=".xlsx,.xls"
                            />
                            <ButtonComponent text={"Load Excel File"} type={ButtonType.MAIN} onClick={() => { }} icon={<Icons.Plus />} />
                        </div>
                        <div style={{ width: "10px" }
                        }></div>
                        {shiftsToLoad.length > 0 && <ButtonComponent text={"Clear Table"} type={ButtonType.DANGER} onClick={() => _clearTable()} icon={<Icons.Trash />} />}
                    </div>
                </div>

                <div>From {DateParse.getDateBeauty(currentWeek[0])} to {DateParse.getDateBeauty(currentWeek[currentWeek.length - 1])}</div>

                <DataLoaderTableComponent data={shiftsToLoad} week={currentWeek} />
            </div>

            <div className="floating_button">
                <ButtonComponent text={"Assign Shifts"} type={ButtonType.MAIN} onClick={_handleAsignShift} icon={<Icons.CheckRounded />} />
            </div>

        </div>
    </div>
}

export default DataLoaderPage;