export default interface AIRepository {
    getAISumaries: (params: string) => Promise<any>;
    getAISumaryByRecord: (record: string) => Promise<any>;
    updateAISumaryByRecord: (record: string, content: any) => Promise<any>;
    getAIEmbedURL: () => Promise<any>;
    searchAISummaries: (query: any) => Promise<any>;
    searchAISummariesLanguages: () => Promise<any>;
    searchAISummariesEntities: () => Promise<any>;
    getAIAgents: () => Promise<any>;
    getAICustomers: () => Promise<any>;
    getAIPerson: (person: string, type: string, params: string) => Promise<any>;
}

export const AIRepositoryName = "AIRepositoryName";