import './ShiftAsLineComponentStyles.scss';
import { FC, useContext, useState } from "react";
import ShiftAsLineComponentProps from "./ShiftAsLineComponentProps";
import { DateOperations } from "../../utils/DateOperations";
import DateParse from '../../utils/DateParse';
import LocalizationContext from '../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';
import ShiftEntity from '../../../domain/entities/ShiftEntity';

const ShiftAsLineComponent: FC<ShiftAsLineComponentProps> = ({ shifts, onClick, width, height, weekToShow }) => {

    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const calculateWidth = (): number => {
        const weekReduced = removeWeeksNotToShow();
        
        if(weekReduced.length <= 0 || weekReduced[0] == null || weekReduced[weekReduced.length - 1] == null) return 0;
        const daysDifference = Math.abs(DateOperations.differenceInDays(weekReduced[0], weekReduced[weekReduced.length - 1]) - 1);
        
        return Math.abs(Math.ceil(daysDifference)) * width;
    }

    const removeWeeksNotToShow = (): Date[] => {
        const days = shifts.filter(day => {
            return weekToShow.find(weekDay => DateOperations.isSameDate(weekDay, day.initHour));
        });
        return days.map(day => day.initHour);
    }

    const _existingDate = (date: Date): string => {
        
        const findDate = shifts.find(day => DateOperations.isSameDate(day.initHour, date))as ShiftEntity;
        if (findDate) return `${DateParse.getHourAmPm(findDate.initHour)} - ${DateParse.getHourAmPm(findDate.endHour)}`
        return '';
    }

    const isBetween = (date: Date): boolean => {
        const removedWeeks = removeWeeksNotToShow();
        const start = new Date(removedWeeks[0]);
        const end = new Date(removedWeeks[removedWeeks.length - 1]);
        if(start == null || end == null) return false;
        return date.setHours(0,0,0,0) >= start.setHours(0,0,0,0) && date.setHours(0,0,0,0) <= end.setHours(0,0,0,0);
    }

    return <div className="shift_as_line_component" style={{ width: calculateWidth(), height: height }}>
        <div className={`shift_hour_container ${onClick && 'hover'}`} onClick={() => onClick?.(shifts)}>
            
            <div className='p-2 shift_title shift_filled'  style={{ width: width / 2, height: height, fontSize: "14px"}}>
                { calculateWidth() > 0 && <strong>{i18n(KeyWordLocalization.ShiftEntity)}</strong>}
            </div>
            
            {weekToShow.map((day, index) => isBetween(day) ? <div key={index} className={`shift_hour ${_existingDate(day) != '' ? 'shift_filled' : 'shift_not_filled'}`} style={{ height: height, width: width / (DateOperations.isSameDate(day, removeWeeksNotToShow()[0]) ? 2 : 1), fontSize: "12px" }}>
                <div className="shift_hour_content">
                    {_existingDate(day)}
                </div>
            </div> : '' )}
        </div>
    </div>
}

export default ShiftAsLineComponent;