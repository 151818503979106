import './SearchWithGroupsComponentStyles.scss';
import { FC, useContext, useEffect, useRef, useState } from "react";
import LocalizationContext from "../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../domain/providers/localization/LocalizationContextType";
import { useForm } from "react-hook-form";
import SearchWithGroupsComponentProps from './SearchWithGroupsComponentProps';
import KeyWordLocalization from '../../providers/localization/dictionaries/KeyWordLocalization';
import GroupEntity from '../../../domain/entities/GroupEntity';
import AutoCompleteWithCheckComponent from '../forms/autocompleteWithCheck/AutoCompleteWithCheckComponent';
import BusinessUnitEntity from '../../../domain/entities/BusinessUnitEntity';
import { AutoCompleteItem } from '../forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import ButtonComponent from '../button/ButtonComponent';
import { ButtonType } from '../button/ButtonComponentProps';
import Icons from '../../assets/Icons';
import di from '../../../di/DependencyInjection';
import SearchAllMyGroupsUseCase, { SearchAllMyGroupsUseCaseName } from '../../../domain/use_cases/group/SearchAllMyGroupsUseCase';
import ModalsContext from '../../../domain/providers/modal/ModalsContext';
import ModalsContextType from '../../../domain/providers/modal/ModalsContextType';
import CreateGroupModalComponent from '../../pages/schedule/createGroupModal/CreateGroupModalComponent';
import UserEntity from '../../../domain/entities/UserEntity';
import DeleteGroupByIdUseCase, { DeleteGroupByIdUseCaseName } from '../../../domain/use_cases/group/DeleteGroupByIdUseCase';
import SearchUsersOfGroupUseCase, { SearchUsersOfGroupUseCaseName } from '../../../domain/use_cases/group/SearchUsersOfGroupUseCase';
import { isLeft } from 'fp-ts/lib/Either';

const SearchWithGroupsComponent: FC<SearchWithGroupsComponentProps> = ({ defaultFormFunctions, isLoading, from, setItems, items, onSearch, onSearchBusinessUnit, onSearchEmployees, onSearchSupervisor, itemsPerPage, children, aditionalButtons }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { openModalCustom, closeModalCustom, addToast } = useContext(ModalsContext) as ModalsContextType;

    const _tempFormFunctions = useForm();
    const formFunctions = defaultFormFunctions ?? _tempFormFunctions;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [groups, setGroups] = useState<GroupEntity[]>([]);
    const selectedGroups = watch(KeyWordLocalization.SearchWithGroupsComponentGroups)?.length > 0 ?? false

    const onSubmit = async (values: any) => {
        _handleOnSearch();
    }

    const _buClear = () => {
        addToast(i18n(KeyWordLocalization.SearchWithGroupsComponentAGroupIsSelected), 'error', undefined);
        setValue(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds, []);
        setValue(KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds, []);
        setValue(KeyWordLocalization.SearchWithGroupsComponentEmployeesIds, []);
    }

    const _handleChangeBu = async (businessUnit: BusinessUnitEntity) => {
        if (selectedGroups) {
            _buClear();
            return;
        }

        const currentBusinessUnits = getValues()[KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds] || [];
        const currentSupervisors = getValues()[KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds] || [];
        //filter supervisor with same business unit
        const filtred = currentSupervisors.filter((supervisor: AutoCompleteItem<UserEntity>) => {
            return currentBusinessUnits.some((bu: AutoCompleteItem<BusinessUnitEntity>) => bu.id === supervisor.aditionalValue.business_unit);
        });
        setValue(KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds, filtred);

        //filter employees
        const currentEmployees = getValues()[KeyWordLocalization.SearchWithGroupsComponentEmployeesIds] || [];
        const filtredEmployees = currentEmployees.filter((employee: AutoCompleteItem<UserEntity>) => {
            return currentBusinessUnits.some((bu: AutoCompleteItem<BusinessUnitEntity>) => bu.id === employee.aditionalValue.business_unit);
        });
        setValue(KeyWordLocalization.SearchWithGroupsComponentEmployeesIds, filtredEmployees);
        _handleOnSearch();
    }

    const _handleChangeSupervisor = async (supervisor: UserEntity) => {
        if (selectedGroups) {
            _buClear();
        }
        _handleOnSearch();

        // setValue(KeyWordLocalization.SearchWithGroupsComponentEmployeesIds, []);
    }

    //Disabeld
    // const _handleOnChangeGroup = async (group: GroupEntity) => {
    //     setValue(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds, []);
    //     setValue(KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds, []);
    //     const employeesId = group.users.map(user => user.id);
    //     setValue(KeyWordLocalization.SearchWithGroupsComponentEmployeesIds, employeesId);
    //     setValue(KeyWordLocalization.SearchWithGroupsComponentGroupId, group.id);
    //     await onSearch([], [], employeesId);
    // }

    const _handleOnPickOption = (option: string) => {
        if (option?.length > 0) {
            setValue(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds, []);
            setValue(KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds, []);
            setValue(KeyWordLocalization.SearchWithGroupsComponentEmployeesIds, []);
        }
        _handleOnSearch();
    }

    const _handleChangeGroup = async (group: AutoCompleteItem<GroupEntity> | undefined) => {
        _handleOnSearch();
    }

    const _handleOnSearchGroup = async (word: string): Promise<AutoCompleteItem<GroupEntity>[]> => {

        const response = await di.get<SearchAllMyGroupsUseCase>(SearchAllMyGroupsUseCaseName).call(word, 1, 20);
        const mapped = response.items.map((group: GroupEntity) => {
            return {
                label: group.name,
                id: group.id,
                aditionalValue: group,
            }
        });
        return mapped;
    }

    const _handleOnSearchBusinessUnit = async (word: string): Promise<AutoCompleteItem<BusinessUnitEntity>[]> => {
        const response = await onSearchBusinessUnit(word);
        return response.map((businessUnit: BusinessUnitEntity) => {
            return {
                label: businessUnit.name,
                image: businessUnit.logo,
                id: businessUnit.id,
                aditionalValue: businessUnit,
            }
        });
    }

    const _handleSearchSupervisors = async (word: string): Promise<AutoCompleteItem<UserEntity>[]> => {

        const businessValues = getValues()[KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds] || [""];
        const businessCasting = businessValues?.map((bus: any) => bus.label) || [""];
        const response = await onSearchSupervisor(word, [...businessCasting]);

        return response.map((supervisor: UserEntity) => {
            return {
                label: supervisor.name,
                image: supervisor.image ?? '',
                id: supervisor.id,
                aditionalValue: supervisor,
            }
        });

    }

    const _handleSearchAgents = async (word: string): Promise<AutoCompleteItem<UserEntity>[]> => {

        const businessValues = getValues()[KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds] || [""];
        const supervisorsValues = getValues()[KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds] || [""];

        const businessCasting = businessValues?.map((bus: any) => bus.label) || [""];
        const supervisorsCasting = supervisorsValues?.map((bus: any) => bus.id) || [""];

        const response = await onSearchEmployees(word, [...businessCasting], [...supervisorsCasting]);

        return response.map((employee: UserEntity) => {
            return {
                label: employee.name,
                image: employee.image ?? '',
                id: employee.id,
                aditionalValue: employee,
            }
        });
    }

    const _handleChangeAgents = (employee: UserEntity | undefined) => {
        if (selectedGroups) {
            _buClear();
        }
        _handleOnSearch();
    }

    const _handleAddGroup = async () => {
        const onEnd = async () => {
            addToast(i18n(KeyWordLocalization.SearchWithGroupsComponentGroupCreated), 'success', 5000);
            closeModalCustom();
        }
        openModalCustom('lg', i18n(KeyWordLocalization.SearchWithGroupsComponentCreateGroup), <CreateGroupModalComponent onDone={onEnd} />)
    }

    const _handleSearchGroup = async (word: string): Promise<AutoCompleteItem<GroupEntity>[]> => {
        const response = await di.get<SearchAllMyGroupsUseCase>(SearchAllMyGroupsUseCaseName).call(word, 1, 20);
        const mapped = response.items.map((group: GroupEntity) => {
            return {
                label: group.name,
                id: group.id,
                aditionalValue: group,
            }
        });
        return mapped;
    }

    const handleDeleteGroup = async () => {
        const groupValues = getValues()[KeyWordLocalization.SearchWithGroupsComponentGroups] || [];

        const confirmation = 'Deleting a group is an irreversible action in the platform. Are you sure you want to delete this group and all associated settings?';
        const messageModal = groupValues.length === 1 ? confirmation : groupValues.length > 1 ? "You only are able to delete one group per confirmation" : "You need to select a group to delete it"
        openModalCustom(
            'md',
            'Delete Group',
            <>
                <div>
                    {messageModal}
                </div>
                {
                    groupValues.length === 1 &&
                    <button className="delete_modal_delete" onClick={() => onDelete()}>Delete</button>
                }
            </>
        )

    }

    const onDelete = async () => {
        const groupValues = getValues()[KeyWordLocalization.SearchWithGroupsComponentGroups] || [];
        await di.get<DeleteGroupByIdUseCase>(DeleteGroupByIdUseCaseName).call(groupValues[0].id);
        setValue(KeyWordLocalization.SearchWithGroupsComponentGroups, []);
        closeModalCustom();

    }

    const _handleOnSearch = async () => {
        const values = getValues();
        const buSelected = values[KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds] == "" ? [] : values[KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds];
        const supSelected = values[KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds] == "" ? [] : values[KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds];
        const empSelected = values[KeyWordLocalization.SearchWithGroupsComponentEmployeesIds] == "" ? [] : values[KeyWordLocalization.SearchWithGroupsComponentEmployeesIds];
        const groupSelected = values[KeyWordLocalization.SearchWithGroupsComponentGroups] == "" ? [] : values[KeyWordLocalization.SearchWithGroupsComponentGroups];

        const businessUnitIds = buSelected.map((bu: AutoCompleteItem<BusinessUnitEntity>) => bu.aditionalValue) ?? [];
        const supervisorsIds = supSelected.map((sup: AutoCompleteItem<UserEntity>) => sup.aditionalValue) ?? [];
        const employesIds = empSelected?.map((emp: AutoCompleteItem<UserEntity>) => emp.aditionalValue) ?? [];
        const groupsIds = groupSelected?.map((group: AutoCompleteItem<GroupEntity>) => group.aditionalValue) ?? [];

        console.log('sending search', { businessUnitIds, supervisorsIds, employesIds, groupsIds });

        const response = await onSearch(businessUnitIds, supervisorsIds, employesIds, groupsIds);
    }

    useEffect(() => {
        _handleSearchAgents("");
    }, [])


    return <form className="search_with_groups_component" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
            <div className="col-12 col-md-6 col-lg-4 my-2">
                <div className="form-group">
                    <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds)}
                        onChange={_handleChangeBu}
                        onSearch={_handleOnSearchBusinessUnit}
                        keyName={KeyWordLocalization.SearchWithGroupsComponentBusinessUnitIds}
                        formInfo={formFunctions}
                        multiple={true}
                    />
                </div>
            </div>

            <div className="col-12 col-md-6 col-lg-4 my-2">
                <div className="form-group">
                    <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds)}
                        onSearch={_handleSearchSupervisors}
                        onChange={_handleChangeSupervisor}
                        rounded={true}
                        keyName={KeyWordLocalization.SearchWithGroupsComponentSupervisorsIds}
                        formInfo={formFunctions}
                        multiple={true}
                    />
                </div>
            </div>
            {
                from !== "staffPerShift" &&
                <div className="col-12 col-md-6 col-lg-4 my-2">
                    <div className="form-group">
                        <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.SearchWithGroupsComponentEmployeesIds)}
                            onSearch={_handleSearchAgents}
                            onChange={_handleChangeAgents}
                            rounded={true}
                            keyName={KeyWordLocalization.SearchWithGroupsComponentEmployeesIds}
                            formInfo={formFunctions}
                            multiple={true}
                        />
                    </div>
                </div>
            }
            {
                from !== "staffPerShift" &&
                <div className="w-100">
                    <div className="row d-flex align-items-end my-2">
                        <div className="col-12 col-md-6 col-lg-8">
                            <div className="form-group">
                                <AutoCompleteWithCheckComponent label={i18n(KeyWordLocalization.SearchWithGroupsComponentGroups)}
                                    onSearch={_handleOnSearchGroup}
                                    rounded={true}
                                    keyName={KeyWordLocalization.SearchWithGroupsComponentGroups}
                                    formInfo={formFunctions}
                                    multiple={true}
                                    onChange={_handleChangeGroup}
                                />
                            </div>
                        </div>

                        {aditionalButtons}

                        <div className="col-12 col-md-6 col-lg-2 my-md-3 my-lg-0">
                            <ButtonComponent text={i18n(KeyWordLocalization.SearchWithGroupsComponentCreateGroup)} onClick={_handleAddGroup} type={ButtonType.MAIN} icon={<Icons.AddPeople />} />
                        </div>
                        <div className="col-12">
                            {children ?? ''}
                        </div>

                    </div>
                </div>
            }

        </div>
        <button type='submit' className='save_filter_button'>Apply filter</button>
        {
            from !== "staffPerShift" &&
            <button className='save_filter_button' onClick={() => handleDeleteGroup()}>Delete Group</button>
        }


    </form>
};

export default SearchWithGroupsComponent;