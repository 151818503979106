import { FC, useContext, useState } from "react";
import "./TerminationStartPageStyles.scss";
import HorizontalSteperComponent from "../../../components/horizontalStepper/HorizontalStepperComponent";
import ButtonComponent from "../../../components/button/ButtonComponent";
import { useForm } from "react-hook-form";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import Validators from "../../../utils/Validators";
import { ErrorMessage } from "@hookform/error-message";

const TerminationStartPage: FC<{}> = () => {
    const [currentStep, setCurrentStep] = useState(3);
    const steps = [
        KeyWordLocalization.TerminationStartPageEmployeeInformation,
        KeyWordLocalization.TerminationStartPagePositionInformation,
        KeyWordLocalization.TerminationStartPageTerminationInformation,
        KeyWordLocalization.TerminationStartPageCompanyPropertyInformation,
        KeyWordLocalization.TerminationStartPageConfirmation];
    const formFunctions = useForm();
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const { register, handleSubmit, getValues, setValue, watch, formState: { errors } } = formFunctions;
    const fields = {
        employeeName: KeyWordLocalization.TerminationStartPageFormEmployeeName,
        employeeGender: KeyWordLocalization.TerminationStartPageFormEmployeeGender,
        employeeLanguage: KeyWordLocalization.TerminationStartPageFormEmployeeLanguage,
        employeeBirthday: KeyWordLocalization.TerminationStartPageFormEmployeeBirthday,
        employeePersonalEmail: KeyWordLocalization.TerminationStartPageFormEmployeePersonalEmail,
        employeeHRMId: KeyWordLocalization.TerminationStartPageFormEmployeeHRMId,

        supervisorName: KeyWordLocalization.TerminationStartPageFormSupervisorName,
        supervisorEmail: KeyWordLocalization.TerminationStartPageFormSupervisorEmail,
        department: KeyWordLocalization.TerminationStartPageFormPositionDepartment,
        worksiteLocations: KeyWordLocalization.TerminationStartPageFormWorksiteLocation,
        location: KeyWordLocalization.TerminationStartPageFormLocation,

        employeeStartDate: KeyWordLocalization.TerminationStartPageFormEmployeeStartDate,
        lastDayWorked: KeyWordLocalization.TerminationStartPageFormLastDayWorked,
        notifiactionOfTerminationDate: KeyWordLocalization.TerminationStartPageFormNotifiactionOfTerminationDate,
        terminationDate: KeyWordLocalization.TerminationStartPageFormTerminationDate,
        positionAtTermination: KeyWordLocalization.TerminationStartPageFormPositionAtTermination,
        elegibleForRehire: KeyWordLocalization.TerminationStartPageFormElegibleForRehire,

        companyPropertyReturned: KeyWordLocalization.TerminationStartPageFormCompanyPropertyReturned,
        companyPropertyNotReturned: KeyWordLocalization.TerminationStartPageFormCompanyPropertyNotReturned,
        terminationPhase: KeyWordLocalization.TerminationStartPageFormTerminationPhase,
        terminationType: KeyWordLocalization.TerminationStartPageFormTerminationType,
    }

    const _handleOnSubmit = async (data: any) => {
        if (currentStep < steps.length - 1)
            return setCurrentStep(currentStep + 1);
        console.log(data);
    }

    return <div className="termination_start_page">
        <form onSubmit={handleSubmit(_handleOnSubmit)}>
            <div className="card">
                <div className="card-body">
                    <HorizontalSteperComponent changeStep={setCurrentStep} currentStep={currentStep} steps={steps}>
                        <section> // Employee Information
                            <div className="row">
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeeName] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeeName)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.employeeName)}
                                        {...register(fields.employeeName, Validators({ required: true, minLength: 10 }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeeName} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeeGender] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeeGender)}</label>
                                    <select className="form-control" placeholder={i18n(fields.employeeGender)}
                                        {...register(fields.employeeGender, Validators({ required: true }))}>
                                        <option value={KeyWordLocalization.TerminationStartPageMale}>{i18n(KeyWordLocalization.TerminationStartPageMale)}</option>
                                        <option value={KeyWordLocalization.TerminationStartPageFemale}>{i18n(KeyWordLocalization.TerminationStartPageFemale)}</option>
                                    </select>
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeeGender} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeeLanguage] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeeLanguage)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.employeeLanguage)}
                                        {...register(fields.employeeLanguage, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeeLanguage} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeeBirthday] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeeBirthday)}</label>
                                    <input type="date" className="form-control" placeholder={i18n(fields.employeeBirthday)}
                                        {...register(fields.employeeBirthday, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeeBirthday} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeePersonalEmail] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeePersonalEmail)}</label>
                                    <input type="email" className="form-control" placeholder={i18n(fields.employeePersonalEmail)}
                                        {...register(fields.employeePersonalEmail, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeePersonalEmail} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeeHRMId] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeeHRMId)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.employeeHRMId)}
                                        {...register(fields.employeeHRMId, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeeHRMId} />
                                </div>
                            </div>
                            <ButtonComponent typeAction="submit" text="Next" ></ButtonComponent>
                        </section>
                        <section> // Position Information
                            <div className="row">
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.supervisorName] ? 'error' : ''}`}>
                                    <label>{i18n(fields.supervisorName)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.supervisorName)}
                                        {...register(fields.supervisorName, Validators({ required: true, minLength: 10 }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.supervisorName} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.supervisorEmail] ? 'error' : ''}`}>
                                    <label>{i18n(fields.supervisorEmail)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.supervisorEmail)}
                                        {...register(fields.supervisorEmail, Validators({ required: true, email: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.supervisorEmail} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.department] ? 'error' : ''}`}>
                                    <label>{i18n(fields.department)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.department)}
                                        {...register(fields.department, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.department} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.worksiteLocations] ? 'error' : ''}`}>
                                    <label>{i18n(fields.worksiteLocations)}</label>
                                    <select className="form-control" placeholder={i18n(fields.worksiteLocations)}
                                        {...register(fields.worksiteLocations, Validators({ required: true }))}>
                                    </select>
                                    <ErrorMessage as="aside" errors={errors} name={fields.worksiteLocations} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.location] ? 'error' : ''}`}>
                                    <label>{i18n(fields.location)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.location)}
                                        {...register(fields.location, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.location} />
                                </div>
                            </div>
                            <ButtonComponent typeAction="submit" text="Next" ></ButtonComponent>
                        </section>
                        <section> // Termination Information
                            <div className="row">
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.employeeStartDate] ? 'error' : ''}`}>
                                    <label>{i18n(fields.employeeStartDate)}</label>
                                    <input type="date" className="form-control" placeholder={i18n(fields.employeeStartDate)}
                                        {...register(fields.employeeStartDate, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.employeeStartDate} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.lastDayWorked] ? 'error' : ''}`}>
                                    <label>{i18n(fields.lastDayWorked)}</label>
                                    <input type="date" className="form-control" placeholder={i18n(fields.lastDayWorked)}
                                        {...register(fields.lastDayWorked, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.lastDayWorked} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.notifiactionOfTerminationDate] ? 'error' : ''}`}>
                                    <label>{i18n(fields.notifiactionOfTerminationDate)}</label>
                                    <input type="date" className="form-control" placeholder={i18n(fields.notifiactionOfTerminationDate)}
                                        {...register(fields.notifiactionOfTerminationDate, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.notifiactionOfTerminationDate} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.terminationDate] ? 'error' : ''}`}>
                                    <label>{i18n(fields.terminationDate)}</label>
                                    <input type="date" className="form-control" placeholder={i18n(fields.terminationDate)}
                                        {...register(fields.terminationDate, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.terminationDate} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.positionAtTermination] ? 'error' : ''}`}>
                                    <label>{i18n(fields.positionAtTermination)}</label>
                                    <input type="text" className="form-control" placeholder={i18n(fields.positionAtTermination)}
                                        {...register(fields.positionAtTermination, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.positionAtTermination} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.elegibleForRehire] ? 'error' : ''}`}>
                                    <label>{i18n(fields.elegibleForRehire)}</label>
                                    <select className="form-control" placeholder={i18n(fields.elegibleForRehire)}
                                        {...register(fields.elegibleForRehire, Validators({ required: true }))}>
                                        <option value={KeyWordLocalization.Yes}>{i18n(KeyWordLocalization.Yes)}</option>
                                        <option value={KeyWordLocalization.No}>{i18n(KeyWordLocalization.No)}</option>
                                    </select>
                                    <ErrorMessage as="aside" errors={errors} name={fields.elegibleForRehire} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.terminationPhase] ? 'error' : ''}`}>
                                    <label>{i18n(fields.terminationPhase)}</label>
                                    <select className="form-control" placeholder={i18n(fields.terminationPhase)}
                                        {...register(fields.terminationPhase, Validators({ required: true }))}>
                                    </select>
                                    <ErrorMessage as="aside" errors={errors} name={fields.terminationPhase} />
                                </div>
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.terminationType] ? 'error' : ''}`}>
                                    <label>{i18n(fields.terminationType)}</label>
                                    <select className="form-control" placeholder={i18n(fields.terminationType)}
                                        {...register(fields.terminationType, Validators({ required: true }))}>
                                    </select>
                                    <ErrorMessage as="aside" errors={errors} name={fields.terminationType} />
                                </div>
                                <ButtonComponent typeAction="submit" text="Next" ></ButtonComponent>
                            </div>
                        </section>
                        <section> // Company Property Information
                            <div className="row">
                                <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.companyPropertyReturned] ? 'error' : ''}`}>
                                    <label>{i18n(fields.companyPropertyReturned)}</label>
                                    <select className="form-control" placeholder={i18n(fields.companyPropertyReturned)}
                                        {...register(fields.companyPropertyReturned, Validators({ required: true }))}>
                                        <option value={KeyWordLocalization.Yes}>{i18n(KeyWordLocalization.Yes)}</option>
                                        <option value={KeyWordLocalization.No}>{i18n(KeyWordLocalization.No)}</option>
                                    </select>
                                    <ErrorMessage as="aside" errors={errors} name={fields.companyPropertyReturned} />
                                </div>
                                {watch(fields.companyPropertyReturned) === KeyWordLocalization.No && <div className={`col-12 my-2 col-md-6 mb-4 form-group ${errors[fields.companyPropertyNotReturned] ? 'error' : ''}`}>
                                    <label>{i18n(fields.companyPropertyNotReturned)}</label>
                                    <textarea className="form-control" placeholder={i18n(fields.companyPropertyNotReturned)}
                                        {...register(fields.companyPropertyNotReturned, Validators({ required: true }))} />
                                    <ErrorMessage as="aside" errors={errors} name={fields.companyPropertyNotReturned} />
                                </div>}
                            </div>
                        </section>
                    </HorizontalSteperComponent>

                </div>
            </div>
        </form>
    </div>
}

export default TerminationStartPage;