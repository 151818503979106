import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../entities/ExceptionEntity";
import TrackingClockInteractionEntity from "../entities/TrackingClockInteractionEntity";
import EventScheduleEntity from "../entities/EventScheduleEntity";
import UserEntity from "../entities/UserEntity";
import TypeEventScheduleEntity from "../entities/TypeEventScheduleEntity";
import ShiftEntity from "../entities/ShiftEntity";
import GroupEntity from "../entities/GroupEntity";
import BusinessUnitEntity from "../entities/BusinessUnitEntity";

export interface SummaryTypeAttendance {
    totalPlanned: number,
    totalReal: number,
    typeEvent: TypeEventScheduleEntity,
    dateToDate: {
        date: Date,
        totalPlanned: number
        totalReal: number
    }[]
}
export interface GetSummaryAttendanceInTimeResponse {
    statusUsers: {
        user: UserEntity,
        todayCalendar: ShiftEntity,
    }[],
    summary: SummaryTypeAttendance[],
}

export default interface TrackingTimeRepository {
    startClock: (eventId: string | undefined, tagType: string | undefined, shiftId: string) => Promise<Either<ExceptionEntity, void>>;
    pauseClock: (reason: string, shiftId: string) => Promise<Either<ExceptionEntity, void>>;
    getReasonsForPause: () => Promise<Either<ExceptionEntity, string[]>>;
    endEvent: (shiftId: string) => Promise<Either<ExceptionEntity, void>>;
    getDayPlannedVsReal: (userId: string, date: Date) => Promise<Either<ExceptionEntity, { planned: EventScheduleEntity, real: TrackingClockInteractionEntity[] }[]>>;
    addTrackingInteractionAsSupervisor: (clockIn: Date, clockOut: Date | null, comment: string, typeId: string, supervisorId: string, shiftId: string) => Promise<Either<ExceptionEntity, void>>;
    editTrackingInteractionAsSupervisor: (trackingTime: TrackingClockInteractionEntity, commentId: string, supervisorId: string) => Promise<Either<ExceptionEntity, void>>;
    getSummaryAttendanceInTimeRange: (businessUnits: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], tagTypes: TypeEventScheduleEntity[], startDate: Date, endDate: Date) => Promise<Either<ExceptionEntity, GetSummaryAttendanceInTimeResponse>>;
}

export const TrackingTimeRepositoryName = "TrackingTimeRepository";