import { Either, isLeft, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import ShiftEntity from "../../../../../domain/entities/ShiftEntity";
import GraphApi from "../../../../settings/GraphApi";
import ShiftModifierHostDto from "../../../../dto/impl/ShiftModifierHostDto";
import EventScheduleEntity, { EventScheduleEntityStatus } from "../../../../../domain/entities/EventScheduleEntity";
import di from "../../../../../di/DependencyInjection";
import MastersProvider, { MastersProviderName } from "../../../../../domain/providers/master/MastersProvider";
import DateParse from "../../../../../ui/utils/DateParse";
import TrackingClockInteractionHostDto from "../../../../dto/impl/TrackingClockInteractionHostDto";
import TrackingClockInteractionEntity from "../../../../../domain/entities/TrackingClockInteractionEntity";
import { DateOperations } from "../../../../../ui/utils/DateOperations";

export const fillShiftDayWithWorkEvents = (startDate: Date, endDate: Date, shiftId: string, shiftModifiers: EventScheduleEntity[]): EventScheduleEntity[] => {
    //first we order events by start time
    const sortedEvents = shiftModifiers.sort((a: EventScheduleEntity, b: EventScheduleEntity) => a.dateStart.getTime() - b.dateStart.getTime());
    //the day starts on the start, all the space between two events is work time
    let lastEndTime = startDate;
    const mastersProvider = di.get<MastersProvider>(MastersProviderName);
    const workTimeType = mastersProvider.Actions.typeSchedules.find((type) => type.id == "17" || type.name == "Work Time");
    if (workTimeType === undefined) return shiftModifiers;

    const events: EventScheduleEntity[] = [];
    let shiftIndex = 1;
    sortedEvents.forEach((event) => {
        if (event.dateStart > lastEndTime) {
            events.push({
                dateStart: lastEndTime,
                dateEnd: event.dateStart,
                id: shiftId + '_shift_' + shiftIndex,
                editable: false,
                name: workTimeType.name,
                type: workTimeType,
                shiftId: shiftId,
                status: EventScheduleEntityStatus.pending,
                description: workTimeType.description,
            });
            shiftIndex++;
        }
        events.push(event);
        lastEndTime = event.dateEnd;
    });

    if (lastEndTime < endDate) {
        events.push({
            dateStart: lastEndTime,
            dateEnd: endDate,
            id: shiftId + '_shift_' + shiftIndex,
            editable: false,
            shiftId: shiftId,
            name: workTimeType.name,
            type: workTimeType,
            status: EventScheduleEntityStatus.pending,
            description: workTimeType.description,
        });
    }

    return events.sort((a, b) => a.dateStart.getTime() - b.dateStart.getTime());
}

const getCurrentEventStatus = (eventsSorted: EventScheduleEntity[], clockings: TrackingClockInteractionEntity[]): EventScheduleEntity[] => {
    const events = [...eventsSorted];
    //if eventsClock is empty, all events are pending
    const now = DateOperations.dateUTC();
    events.forEach((event) => {
        event.status = event.dateEnd < now ? EventScheduleEntityStatus.ended : EventScheduleEntityStatus.pending;
    });
    if (clockings.length == 0) return events;
    if (clockings.length == 1 && clockings[0].details.includes('pause_')) {
        //using the start date of clocking, find the event that is running
        const lastEvent = events.find((event) => clockings[0].clockInTime >= event.dateStart && clockings[0].clockInTime <= event.dateEnd);
        if (lastEvent == null) return events;
        //set all behind last event as ended, and all before as pending
        const index = events.indexOf(lastEvent);
        events.forEach((event, i) => {
            if (i < index) {
                event.status = EventScheduleEntityStatus.ended;
            } else if (i > index) {
                event.status = EventScheduleEntityStatus.pending;
            }
        });
    }
    else {
        //if eventsClock is not empty, we have to find the event related to the last clocking
        const clockSorted = clockings.sort((a, b) => a.clockInTime.getTime() - b.clockInTime.getTime());
        //we have to check event by event, if an event was paused or continue we have to check it
        let currentEvent;
        const lastClock = clockSorted[clockSorted.length - 1];
        if (lastClock.details.includes('pause_')) {
            //the last event was paused, so we have to get the previous event
            const prevLastClock = clockSorted[clockSorted.length - 2];
            currentEvent = events.find((event) => event.id == prevLastClock.details);
            if (currentEvent != null)
                currentEvent.status = EventScheduleEntityStatus.paused;
        } else if (lastClock.clockOutTime != null) {
            return events;
        } else {
            currentEvent = events.find((event) => event.id == lastClock.details);
            if (currentEvent != null)
                currentEvent.status = EventScheduleEntityStatus.running;
        }
        //now I have the current event, so all the events before are on ended status, and all the events after are on pending status
        if (currentEvent != null) {
            const index = events.indexOf(currentEvent);
            events.forEach((event, i) => {
                if (i < index) {
                    event.status = EventScheduleEntityStatus.ended;
                } else if (i > index) {
                    event.status = EventScheduleEntityStatus.pending;
                }
            });
        }
    }
    return events;
}

const setHourDifference = (dateString: string) => {
    const date = new Date();
    const localDate = DateParse.stringToDate(dateString);
    const diff = localDate.getTime() - date.getTime();
    window.localStorage.setItem('time_difference_server', diff.toString());
}


const GetEmployeeTodayCalendarApiImpl = async (employeeId: string): Promise<Either<ExceptionEntity, ShiftEntity>> => {
    const response = await GraphApi.multipleQuery([
        {
            name: 'listCurrentShift_modifiers',
            params: { employee_id: parseInt(employeeId) },
            results: ['activity_type_id', 'end_time', 'shift_id', 'shift_modifier_id', 'start_time', 'tag_type_id']
        },
        {
            name: 'listCurrentShifts',
            params: { employee_id: parseInt(employeeId) },
            results: ['employee_id', 'end_time', 'shift_group', 'shift_id', 'start_time', 'local_time']
        },
        {
            name: 'listCurrentTime_trackings',
            params: { employee_id: parseInt(employeeId) },
            results: ['time_tracking_id', 'tag_type_id', 'shift_id', 'details', 'clock_out_time', 'clock_in_time', 'activity_type_id']
        }

    ]);
    if (isLeft(response)) {
        return response;
    }
    else if (response.right.data.listCurrentShifts.length == 0) {
        return right({
            initHour: new Date(),
            endHour: new Date(),
            id: "1",
            shiftGroup: "1",
            users: [],
            events: []
        });
    }
    setHourDifference(response.right.data.listCurrentShifts[0].local_time);
    const parsedModifiers: EventScheduleEntity[] = response.right.data.listCurrentShift_modifiers.map((item: any) => ShiftModifierHostDto.fromJson(item));

    const clockActivities = response.right.data.listCurrentTime_trackings?.map((item: any) => TrackingClockInteractionHostDto.fromJson(item)) ?? [];

    //fill the day with work events
    const _startDate = DateParse.stringToDate(response.right.data.listCurrentShifts[0].start_time);
    const _endDate = DateParse.stringToDate(response.right.data.listCurrentShifts[0].end_time);
    const _shiftId = response.right.data.listCurrentShifts[0].shift_id;
    const filledDay = fillShiftDayWithWorkEvents(_startDate, _endDate, _shiftId, parsedModifiers);
    const getActivitiesStatus = getCurrentEventStatus(filledDay, clockActivities);
    const answer: ShiftEntity = {
        initHour: new Date(),
        endHour: new Date(),
        id: "1",
        shiftGroup: "1",
        users: [],

        events: getActivitiesStatus,
    };
    return right(answer);
}

export default GetEmployeeTodayCalendarApiImpl;
