import './CalendarWeekComponentStyles.scss';
import { FC, useContext, useEffect, useState } from "react";
import CalendarWeekComponentProps from "./CalendarWeekComponentProps";
import CardEventScheduleComponent from "../cardEventSchedule/CardEventScheduleComponent";
import DateParse from "../../../utils/DateParse";
import CalendarWeekFormComponent from '../../forms/calendarWeekForm/CalendarWeekFormComponent';
import ButtonComponent from '../../button/ButtonComponent';
import Icons from '../../../assets/Icons';
import { ButtonType } from '../../button/ButtonComponentProps';
import TypeEventScheduleEntity from '../../../../domain/entities/TypeEventScheduleEntity';
import MastersContext from '../../../../domain/providers/master/MastersContext';
import MastersContextType from '../../../../domain/providers/master/MastersContextType';
import di from '../../../../di/DependencyInjection';
import GetAllTypeOfEventsUseCase, { GetAllTypeOfEventsUseCaseName } from '../../../../domain/use_cases/calendar/GetAllTypeOfEventsUseCase';
import DropDownComponent from '../../dropDown/DropDownComponent';
import { DropDownType } from '../../dropDown/DropDownComponentProps';
import UserContext from '../../../../domain/providers/user/UserContext';
import UserContextType from '../../../../domain/providers/user/UserContextType';
import EventScheduleEntity from '../../../../domain/entities/EventScheduleEntity';
import { AutoCompleteItem } from '../../forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import ShiftEntity from '../../../../domain/entities/ShiftEntity';
import EventFromShiftUseCase, { EventFromShiftUseCaseName } from '../../../../domain/use_cases/calendar/EventFromShiftUseCase';
import { DateOperations } from '../../../utils/DateOperations';
import ModalsContextType from '../../../../domain/providers/modal/ModalsContextType';
import ModalsContext from '../../../../domain/providers/modal/ModalsContext';
import GetShiftAsEventsUseCase, { GetShiftAsEventsUseCaseName } from '../../../../domain/use_cases/calendar/GetShiftAsEventsUseCase';

const heightCalendar = 40;
const widthCalendar = 250;

const CalendarWeekComponent: FC<CalendarWeekComponentProps> = ({ calendar, currentWeek, onWeekChange,
    loading,
    handleAddEvent, handleEditEvent, handleDeleteEvent,
    handleAddShift, handleEditShift, handleDeleteShift }) => {

    const { user } = useContext(UserContext) as UserContextType;
    const { openModalCustom, addToast } = useContext(ModalsContext) as ModalsContextType;
    const { typeSchedules, workTime } = useContext(MastersContext) as MastersContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [addingEvent, setAddingEvent] = useState<TypeEventScheduleEntity | undefined | null>(undefined); //null is "other event", undefined is not adding event, and TypeEventScheduleEntity is the type of event to add

    const _handleClickOnCell = async (date: Date) => {
        await handleAddEvent?.(addingEvent ?? null, date);
        setAddingEvent(undefined);
    }

    const _calculateYposition = (date: Date): number => {
        const hours = heightCalendar * date?.getHours();
        const minutes = (heightCalendar / 60) * date?.getMinutes();
        return (hours + minutes) * 2 + (heightCalendar * 1.5);
    }

    const _calculateHeight = (dateStart: Date, dateEnd: Date): number => {
        const _calculateYpositionStart = _calculateYposition(dateStart);
        const copyDateEnd = new Date(dateEnd);
        if (dateStart?.getDate() < dateEnd?.getDate() && dateEnd?.getHours() === 0 && dateEnd?.getMinutes() === 0) {
            copyDateEnd.setHours(23);
            copyDateEnd.setMinutes(59);
        }
        const _calculateYpositionEnd = _calculateYposition(copyDateEnd);
        return _calculateYpositionEnd - _calculateYpositionStart - 5;
    }


    const _getHoursOfDay = (day: Date): Date[] => {
        //get from 12:00am to 11:59pm, each 30 minutes
        const hours = [];
        for (let i = 0; i < 48; i++) {
            const hour = new Date(day.getFullYear(), day.getMonth(), day.getDate(), i / 2, i % 2 === 0 ? 0 : 30);
            hours.push(hour);
        }
        return hours;
    }

    const _handleClickIcon = async (type: TypeEventScheduleEntity | null | undefined) => {
        if (addingEvent?.id == type?.id) return setAddingEvent(undefined);
        setAddingEvent(type);
    }

    const _handleAddEvent = async (type: TypeEventScheduleEntity, date: Date | undefined) => {
        await handleAddEvent?.(type, date);
        setAddingEvent(undefined);
    }

    const _handleEditEvent = async (event: EventScheduleEntity) => handleEditEvent?.(event);

    const _getTypeSchedule = async (word: string): Promise<AutoCompleteItem<TypeEventScheduleEntity>[]> => {
        const mapped = typeSchedules.filter((typeSchedule) => !typeSchedule.outstanding).map((typeSchedule) => {
            return {
                label: typeSchedule.name,
                id: typeSchedule.id,
                aditionalValue: typeSchedule,
            }
        });
        return mapped;
    }

    const _loadTypeSchedules = () => {
        di.get<GetAllTypeOfEventsUseCase>(GetAllTypeOfEventsUseCaseName).call();
    }

    const _getShiftsAsEvents = (day: Date): EventScheduleEntity[] => {
        const shifts = calendar?.filter((shift) => DateOperations.isSameDate(shift.initHour, day) || DateOperations.isSameDate(shift.endHour, day));
        if (!shifts) return [];
        const test: any = {};
        shifts.forEach((shift) => {
            test[shift.id] = {
                shift,
                events: di.get<GetShiftAsEventsUseCase>(GetShiftAsEventsUseCaseName).call(shift),
            }
        });
        const events: EventScheduleEntity[] = shifts.flatMap((shift) => di.get<GetShiftAsEventsUseCase>(GetShiftAsEventsUseCaseName).call(shift));
        const filtredEvents: EventScheduleEntity[] = [];
        //remove events with same start and end time
        events.forEach((event) => {
            const found = filtredEvents.find((e) => e.dateStart.getTime() === event.dateStart.getTime() && e.dateEnd.getTime() === event.dateEnd.getTime());
            if (!found) filtredEvents.push(event);
        });
        return filtredEvents;
    }

    const fillWeek = () => {
        const week = calendar?.at(0) ? DateOperations.getWeekDays(calendar[0].initHour) : currentWeek;
        return week;
    }

    const handleOnClickEvent = (event: EventScheduleEntity) => {
        if (addingEvent) return;
        if (event.id?.includes('_shift_')) return handleOnClickShift(event);
        const now = new Date();
        const isEditable = event.dateStart > now || event.dateEnd > now;
        const isDeleteable = event.dateStart > now;
        if ((handleEditEvent || handleDeleteEvent) && DateOperations.isBeforeToday(event.dateStart)) return addToast(i18n(KeyWordLocalization.CalendarWeekComponentErrorEventBeforeToday), 'error', undefined);
        if ((handleEditEvent || handleDeleteEvent) && (isEditable || isDeleteable)) {
            openModalCustom('lg', 'Modify Event', <div>
                {handleEditEvent && isEditable && <button type='button' className='btn btn-light w-100 my-1' onClick={() => handleEditEvent?.(event)} >{i18n(KeyWordLocalization.Edit)}</button>}
                {handleDeleteEvent && isDeleteable && <button type='button' className='btn btn-light w-100 my-1' onClick={() => handleDeleteEvent?.(event)}>{i18n(KeyWordLocalization.Delete)}</button>}
            </div>)
        }
    }

    const _handleAddShift = (day: Date) => {
        if (loading) return;
        handleAddShift?.(day);
    }

    const handleOnClickShift = (event: EventScheduleEntity) => {
        if (addingEvent) return;
        const findShift = calendar.find((shift) => shift.initHour <= event.dateStart && shift.endHour >= event.dateEnd);
        if (!findShift) return;
        const now = new Date();
        const isEditable = findShift.initHour > now || findShift.endHour > now;
        const isDeleteable = findShift.initHour > now;
        if ((handleEditEvent || handleDeleteEvent) && DateOperations.isBeforeToday(event.dateStart)) return addToast(i18n(KeyWordLocalization.CalendarWeekComponentErrorEventBeforeToday), 'error', undefined);
        if ((handleEditShift || handleDeleteShift) && (isDeleteable || isEditable)) {
            openModalCustom('lg', 'Modify Shift', <div>
                {handleEditShift && isEditable && <button type='button' className='btn btn-light w-100 my-1' onClick={() => handleEditShift(findShift!)} >{i18n(KeyWordLocalization.Edit)}</button>}
                {handleDeleteShift && isDeleteable && <button type='button' className='btn btn-light w-100 my-1' onClick={() => handleDeleteShift(findShift!)}>{i18n(KeyWordLocalization.Delete)}</button>}
            </div>)
        }
    }


    const _eventIsInShift = (event: EventScheduleEntity): boolean => {
        if (calendar == undefined) return true;
        let response = false;
        calendar!.forEach((shift) => {
            if (event.dateStart >= shift.initHour && event.dateEnd <= shift.endHour) response = true;
        })
        return response;
    }



    useEffect(() => {
        _loadTypeSchedules();
    }, []);

    const handleClickListed = (e: any) => {
        if (e.target.innerText !== "Other" && e !== null) {
            const selectedItem = typeSchedules.filter(type => type.name === e.target.innerText);
            if (selectedItem.length > 0) handleAddEvent?.(selectedItem[0], undefined)
        }
    }


    return <div className={`calendar_week_component ${addingEvent !== undefined ? 'editing' : ''}`}>
        <div className='d-flex justify-content-between px-3 flex-wrap'>
            {onWeekChange && <CalendarWeekFormComponent onChange={onWeekChange} selectedWeek={currentWeek} />}
            {handleAddEvent && <div className="d-flex flex-wrap flex-grow-1 justify-content-end">
                {typeSchedules.filter((typeSchedule) => typeSchedule.outstanding).map((typeSchedule, index) => <div className={`mx-1 my-2 my-lg-0 adding_button ${addingEvent == typeSchedule ? 'selected_event' : ''}`} key={index}>
                    <ButtonComponent
                        icon={<Icons.Copy />}
                        text={typeSchedule.name}
                        colorLine={typeSchedule.color}
                        disabled={loading}
                        onClick={() => _handleAddEvent(typeSchedule, undefined)}
                        onClickIcon={() => _handleClickIcon(typeSchedule)}
                        type={ButtonType.MAIN} />
                </div>)}
                <div className={`mx-1 my-2 my-lg-0  adding_button ${addingEvent === null ? 'selected_event' : ''}`} onClick={(e) => handleClickListed(e)}>
                    <DropDownComponent
                        onSearch={_getTypeSchedule}
                        text={i18n(KeyWordLocalization.CalendarWeekComponentOtherEvent)}
                        icon={<Icons.Copy />}
                        disabled={loading}
                        onClickIcon={() => _handleClickIcon(null)}
                        type={DropDownType.MAIN}
                        colorLine='#80ACFF' />
                </div>
            </div>}
        </div>

        <div className="calendar_container">
            <div className="calendar_hours">
                <div className='font-bold header_calendar cell p-2 ps-3' style={{ height: heightCalendar * 1.5 }}><strong>{i18n(KeyWordLocalization.CalendarWeekComponentHour)}</strong> </div>
                {_getHoursOfDay(new Date()).map((hour, index) => <div className='d-flex align-items-start justify-content-end' style={{ width: 100, height: heightCalendar, }} key={index}>
                    <div className="hour_column">
                        <div className="hour_title text-end pe-2">
                            {index % 2 === 0 && DateParse.getHourAmPm(hour)}
                        </div>
                    </div>
                </div>)}
            </div>
            <div className="calendar_info">
                {fillWeek().map((dayNormal, index) => {
                    const shiftsDay = calendar.filter((day) => DateOperations.isSameDate(dayNormal, day.initHour) || DateOperations.isSameDate(dayNormal, day.endHour));
                    const eventsOfDay: EventScheduleEntity[] = _getShiftsAsEvents(dayNormal);
                    const dayEditable = !DateOperations.isBeforeToday(dayNormal);
                    return <div className="day_column" style={{ width: widthCalendar * 1.05 }} key={index}>
                        <div className='font-bold header_calendar cell p-2' style={{ height: heightCalendar * 1.5 }}><strong>{DateParse.getDayOfCalendar(dayNormal)} {dayNormal.getDate()}</strong>
                            {shiftsDay.length == 0 && dayEditable && handleAddShift && <div className={`add_shift_button ${loading && 'disabled'}`} onClick={() => _handleAddShift(dayNormal)}>
                                <Icons.Plus /> Add Shift
                            </div>}
                        </div>
                        <div className="cells_container" style={{ width: widthCalendar * 1.05 }}>
                            {_getHoursOfDay(dayNormal).map((hour, index) => <div style={{ height: heightCalendar, width: widthCalendar * 1.05 }} className={`cell ${dayEditable ? 'cell_clickeable hover' : 'cell_no_clickeable'}`} onClick={() => shiftsDay.length > 0 && dayEditable && _handleClickOnCell(hour)} key={index}>
                                <div className="hour_column">
                                    <div className="hour_title "></div>
                                </div>
                            </div>)}
                            {shiftsDay.length == 0 && <div className="wrapper_for_add_shift">
                            </div>}
                        </div>
                        {DateOperations.isSameDate(dayNormal, new Date()) ? <div className="today_line" style={{ top: _calculateYposition(DateOperations.dateUTC()) }}></div> : <div className="dotted_line" style={{ top: _calculateYposition(new Date()) }}></div>}
                        {eventsOfDay.sort((a: EventScheduleEntity, b: EventScheduleEntity) => a.dateStart.getTime() - b.dateStart.getTime()).map((event, index) => {
                            const isRunning = event.dateEnd.getTime() > DateOperations.dateUTC().getTime() && event.dateStart.getTime() < DateOperations.dateUTC().getTime();
                            const isOutOfShift = !_eventIsInShift(event);
                            return (event ?
                                <div key={index} style={{ resize: dayEditable ? 'vertical' : 'none', zIndex: event.type.id == workTime?.id ? '0' : index, left: widthCalendar * 0.025, width: widthCalendar, top: _calculateYposition(event?.dateStart), height: _calculateHeight(event?.dateStart, event?.dateEnd) }} className={`event_card ${isRunning && 'is_running'}`} >
                                    <CardEventScheduleComponent event={event} onClick={handleOnClickEvent} isOutOfShift={isOutOfShift} />
                                </div> : <></>
                            )
                        }
                        )}
                    </div>
                })}

            </div>
        </div>
    </div >
}

CalendarWeekComponent.defaultProps = {
    fullClickeable: false,
    loading: false,
}

export default CalendarWeekComponent;