import "./TrackingTimeStartTaskComponentStyles.scss";
import { FC, useContext, useState } from "react";
import TrackingTimeStartTaskComponentProps from "./TrackingTimeStartTaskComponentProps";
import { isLeft } from "fp-ts/lib/Either";
import di from "../../../../../di/DependencyInjection";
import ModalsContext from "../../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../../domain/providers/modal/ModalsContextType";
import { StartClockTrackingUseCase, StartClockTrackingUseCaseName } from "../../../../../domain/use_cases/trackingTime/StartClockTrackingUseCase";
import ButtonComponent from "../../../button/ButtonComponent";
import CardEventScheduleComponent from "../../../calendar/cardEventSchedule/CardEventScheduleComponent";
import Icons from "../../../../assets/Icons";

const TrackingTimeStartTaskComponent: FC<TrackingTimeStartTaskComponentProps> = ({ event }) => {
    const { closeModalCustom } = useContext(ModalsContext) as ModalsContextType;
    const [loading, setLoading] = useState(false);

    const _handleOnContinue = async () => {
        if (event === undefined || loading) return;
        setLoading(true);
        const response = await di.get<StartClockTrackingUseCase>(StartClockTrackingUseCaseName).call(event);
        if (isLeft(response)) console.error(response.left.code ?? "Error starting task");
        else closeModalCustom();
        setLoading(false);
    }

    return <div className="tracking_time_start_component">

        <div className="onhold_left_container">
            <div className=""><img src="/assets/images/start_activity.png" className='img-fluid' alt="" /></div>
        </div>
        <div className="onhold_right_container">

            <p className="onhold_title">Start Activity</p>
            <p className="onhold_subtitle">Your activity needs to be started</p>
            <div className="tracking_card">
                <CardEventScheduleComponent event={event} />
            </div>

            <ButtonComponent icon={<Icons.Play height={20} width={20} />} onClick={_handleOnContinue} text={`Continue to "${event?.name}"`}></ButtonComponent>
        </div>

    </div>
}

export default TrackingTimeStartTaskComponent;