import { useEffect, useRef, useState } from 'react';

import { Button, ContentLayout, Grid } from '@cloudscape-design/components';

import { ContactTable } from './ContactTable';
import di from '../../../di/DependencyInjection';
import { GetAISummariesUseCase, GetAISummariesUseCaseName } from '../../../domain/use_cases/ai/GetAISummariesUseCase';
import { GetAISummaryByRecordingUseCase, GetAISummaryByRecordingUseCaseName } from '../../../domain/use_cases/ai/GetAISummaryByRecordingUseCase';
import { isRight } from 'fp-ts/lib/Either';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes/RoutesComponent';
import { GetAICustomersUseCase, GetAICustomersUseCaseName } from '../../../domain/use_cases/ai/GetAICustomersUseCase';
import { GetAIAgentsUseCase, GetAIAgentsUseCaseName } from '../../../domain/use_cases/ai/GetAIAgentsUseCase';
import AutoCompleteComponent from '../../components/forms/autocomplete/AutoCompleteComponent';
import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { FieldValues, useForm } from 'react-hook-form';
import { AutoCompleteItem } from '../../components/forms/autocompleteWithCheck/AutoCompleteWithCheckProps';
import { GetAIPersonUseCase, GetAIPersonUseCaseName } from '../../../domain/use_cases/ai/GetAIPersonUseCase';
import LoadingComponent from '../../components/LoadingComponent/LoadingComponent';

interface HomePageProps {
}

const AiPage: React.FC<HomePageProps> = () => {

    const [ data, setData ] = useState<any[]>([]);
    const [ urlParamsAgents, setUrlParamsAgents ] = useState<string>("");
    const [ urlParamsCustomer, setUrlParamsCustomer ] = useState<string>("");
    const [ urlParams, setUrlParams ] = useState<string>("");

    const hasFetchedData = useRef(false);

    const [ customers, setCustomers ] = useState<any[]>([]);
    const [ agents, setAgents ] = useState<any[]>([]);

    const [ itemSelected, setItemSelected ] = useState('');

    const [ filterSelected, setFilterSelected ] = useState('all');

    const [ isLoading, setIsLoading ] = useState(false);

    const navigate = useNavigate();
    
    const _handleSearch = () => {
        navigate(routes.ai_search.path);
    }
    
    const _getCallList = async () => await di.get<GetAISummariesUseCase>(GetAISummariesUseCaseName)._aiRepository.getAISumaries(urlParams);

    const _getCustomers = async () => await di.get<GetAICustomersUseCase>(GetAICustomersUseCaseName)._aiRepository.getAICustomers();
    const _getAgents = async () => await di.get<GetAIAgentsUseCase>(GetAIAgentsUseCaseName)._aiRepository.getAIAgents();

    const firstCall = async () => {
        setIsLoading(true);
        const response = await _getCallList();
        const responseCustomers = await _getCustomers();
        const responseAgents = await _getAgents();
        setIsLoading(false);

        let oldData: any = [];

        if (urlParams && urlParams !== '') {
            oldData = data;
        }

        if (isRight(responseCustomers)) {
            try {
                let result = JSON.parse(responseCustomers.right as string);
                setCustomers(result.data);
            } catch (error) {
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        if (isRight(responseAgents)) {
            try {
                let result = JSON.parse(responseAgents.right as string);
                setAgents(result.data);
            } catch (error) {
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        
        if (isRight(response)) {
            try {
                let result = JSON.parse(response.right as string);
                setData([...result["Records"], ...oldData]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                let url = ``
                if (result["timestampFrom"] && result["StartKey"]) {
                    url = (`count=100&timestampFrom=${result["timestampFrom"]}&startKey=${result["StartKey"]}`);
                    
                }
                setUrlParams(url);
            } catch (error) {
                setData([]);
                setCustomers([]);
                setAgents([]);
                setUrlParamsAgents(``);
                setUrlParamsCustomer(``);
                setUrlParams(``);
            }
            
        }
        

    }

    const onLoadTranscirption = () => {
        
        if(filterSelected === 'customer'){
            handleSelectedCustomer(itemSelected);
        } else if(filterSelected === 'agent') {
            handleSelectedAgent(itemSelected);
        } else if (filterSelected === 'all') {
            firstCall();
        }
    }

    const formInfo: UseFormReturn<FieldValues, any> = useForm({
        // Your useForm configuration options...
    });

    const handleInputItemsCustomers = async (): Promise<any[]> => {
        let data = customers.map(item => {
            return {
                label: item
            }
        })
        return data;
    }

    const handleSelectedCustomer = async (dataValue: any = []) => {
        let person = dataValue[0]?.label ? dataValue[0].label : dataValue;
        if (person !== itemSelected) {
            setData([]);
        };
        setItemSelected(person);
        setFilterSelected('customer');

        let oldData: any = [];
        if (urlParamsCustomer !== '') {
            oldData = data;
        }

        let oldUrl = '';
        if (person === itemSelected) {
            oldUrl = urlParamsCustomer;
        } else {
            oldData = [];
        }

        setIsLoading(true);
        
        const _getPerson = async () => await di.get<GetAIPersonUseCase>(GetAIPersonUseCaseName)._aiRepository.getAIPerson(person, 'customer', oldUrl);
        const response = await _getPerson();
        
        setIsLoading(false);

        if (isRight(response)) {
            let result = JSON.parse(response.right as string);
            try {
                setData([...result['Records'], ...oldData]);
                setUrlParams(``);
                setUrlParamsAgents(``);
                let url = ``;
                if (result["timestampFrom"] && result["StartKey"]) {
                    url = (`count=100&timestampFrom=${result["timestampFrom"]}&startKey=${result["StartKey"]}`);   
                }
                
                setUrlParamsCustomer(url);
            } catch (error) {
                setUrlParamsCustomer('');
                setData([]);
            }
            
        }
    }

    const handleInputItemsAgents = async (): Promise<any[]> => {
        let data = agents.map(item => {
            return {
                label: item
            }
        })
        return data;
    }

    const handleSelectedAgent = async (dataValue: any = []) => {
        
        let person = dataValue[0]?.label ? dataValue[0].label : dataValue;
        if (person !== itemSelected) {
            setData([]);
        };
        setItemSelected(person);
        setFilterSelected('agent');

        let oldData: any = [];

        if (urlParamsAgents !== '') {
            oldData = data;
        }

        let oldUrl = '';
        if (person === itemSelected) {
            oldUrl = urlParamsAgents;
        } else {
            oldData = [];
        }

        setIsLoading(true);

        const _getPerson = async () => await di.get<GetAIPersonUseCase>(GetAIPersonUseCaseName)._aiRepository.getAIPerson(person, 'agent', oldUrl);
        const response = await _getPerson();

        setIsLoading(false);

        if (isRight(response)) {
            let result = JSON.parse(response.right as string);
            try {
                setUrlParams(``);
                setUrlParamsCustomer(``);
                setData([...result['Records'], ...oldData]);
                let url = '';
                if (result["timestampFrom"] && result["StartKey"]) {
                    url = (`count=100&timestampFrom=${result["timestampFrom"]}&startKey=${result["StartKey"]}`);
                }
                setUrlParamsAgents(url);
                
                
            } catch (error) {
                setData([]);
                setUrlParamsAgents('');
            }
        }
    }
    
    if (!hasFetchedData.current) {
        firstCall();
        hasFetchedData.current = true;  // Mark that the data has been fetched
    }

    return (
        <div style={{padding: "20px"}}>
            <ContentLayout
                headerBackgroundStyle={"transparent"}
            >
                    {
                        (customers.length > 0 && agents.length> 0)  &&
                        <>
                            <div className='search_section'>
                                <div className='search_section_inner'>
                                    <div onClick={() => {
                                        _handleSearch();
                                    }}>Search</div>
                                    <div onClick={() => {
                                        _handleSearch();
                                    }} style={{marginLeft: "5px"}}>
                                        <Icons.Search />
                                    </div>
                                </div>
                                
                            </div>
                            <div className='filter_by_person'>
                            <div>
                                <p>Select an Agent or Customer to filter</p>
                            </div>
                            <div style={{display: 'flex'}}>
                                <div>
                                    Customers
                                    <AutoCompleteComponent
                                        formInfo={formInfo} 
                                        onSearch={handleInputItemsCustomers}
                                        keyName='Roles'
                                        onSelect={handleSelectedCustomer}
                                        placeholder=''
                                    />
                                </div>
                                <div style={{width: '20px'}}></div>
                                <div>
                                    Agents
                                    <AutoCompleteComponent
                                        formInfo={formInfo} 
                                        onSearch={handleInputItemsAgents}
                                        keyName='Roles'
                                        onSelect={handleSelectedAgent}
                                        placeholder=''
                                    />
                                </div>
                            </div>
                            <p className='subtitle_filter'>Item selected: <span>{itemSelected}</span></p>
                            <div className='clean_button' onClick={() => {
                                    firstCall();
                                    setItemSelected('');
                                    setFilterSelected('all');
                                }}>Reset Filter</div>
                            </div>
                        </>
                        
                    }
                <div style={{marginBottom: "20px"}}></div>
                {!isLoading  ? 
                <Grid
                    gridDefinition={[
                        {colspan: { default:12} },
                        {colspan: { default:12} }
                    ]}
                >
                    <ContactTable
                    data={data as any}
                    />
                    {(urlParams  + urlParamsAgents + urlParamsCustomer) !== '' &&
                    <Button
                        variant="primary"
                        onClick={() => onLoadTranscirption()}
                    >
                        load more
                    </Button>
                    }
                    
                    
                </Grid>
                :
                <LoadingComponent />
                }
            </ContentLayout>
        </div>
    );
};

export default AiPage;
