import { injectable } from "inversify";
import TrackingTimeRepository, { GetSummaryAttendanceInTimeResponse } from "../../../domain/repositories/TrackingTimeRepository";
import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../domain/entities/ExceptionEntity";
import StartTrackingClockApiImpl from "./api/impl/StartTrackingClockApiImpl";
import EndEventTrackingClockApiImpl from "./api/impl/EndEventTrackingClockApiImpl";
import GetReasonsForPauseTrackingClockApiImpl from "./api/impl/GetReasonsForPauseTrackingClockApiImpl";
import PauseTrackingClockApiImpl from "./api/impl/PauseTrackingClockApiImpl";
import TrackingTimeRepositoryFake from "./TrackingTimeRepositoryFake";
import TrackingClockInteractionEntity from "../../../domain/entities/TrackingClockInteractionEntity";
import EventScheduleEntity from "../../../domain/entities/EventScheduleEntity";
import GetDayPlannedVsRealApiImpl from "./api/impl/GetDayPlannedVsRealApiImpl";
import EditTrackingInteractionAsSupervisorApiImpl from "./api/impl/EditTrackingInteractionAsSupervisorApiImpl";
import AddTrackingInteractionAsSupervisorApiImpl from "./api/impl/AddTrackingInteractionAsSupervisorApiImpl";
import GetSummaryAttendanceInTimeRangeApiImpl from "./api/impl/GetSummaryAttendanceInTimeRangeApiImpl";
import TypeEventScheduleEntity from "../../../domain/entities/TypeEventScheduleEntity";
import BusinessUnitEntity from "../../../domain/entities/BusinessUnitEntity";
import GroupEntity from "../../../domain/entities/GroupEntity";
import UserEntity from "../../../domain/entities/UserEntity";

@injectable()
export default class TrackingTimeRepositoryDev implements TrackingTimeRepository {
    getSummaryAttendanceInTimeRange = (businessUnits: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], tagTypes: TypeEventScheduleEntity[], startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, GetSummaryAttendanceInTimeResponse>> => GetSummaryAttendanceInTimeRangeApiImpl(businessUnits, supervisors, employees, groups, tagTypes, startDate, endDate);
    addTrackingInteractionAsSupervisor = (clockIn: Date, clockOut: Date | null, comment: string, typeId: string, supervisorId: string, shiftId: string): Promise<Either<ExceptionEntity, void>> => AddTrackingInteractionAsSupervisorApiImpl(clockIn, clockOut, comment, typeId, supervisorId, shiftId);
    editTrackingInteractionAsSupervisor = (trackingTime: TrackingClockInteractionEntity, commentId: string, supervisorId: string): Promise<Either<ExceptionEntity, void>> => EditTrackingInteractionAsSupervisorApiImpl(trackingTime, commentId, supervisorId);
    startClock = (eventId: string | undefined, tagType: string | undefined, shiftId: string): Promise<Either<ExceptionEntity, void>> => StartTrackingClockApiImpl(eventId, tagType, shiftId);
    pauseClock = (reason: string, shiftId: string): Promise<Either<ExceptionEntity, void>> => PauseTrackingClockApiImpl(reason, shiftId);
    getReasonsForPause = (): Promise<Either<ExceptionEntity, string[]>> => GetReasonsForPauseTrackingClockApiImpl();
    endEvent = (shiftId: string): Promise<Either<ExceptionEntity, void>> => EndEventTrackingClockApiImpl(shiftId);
    getDayPlannedVsReal = (userId: string, date: Date): Promise<Either<ExceptionEntity, { planned: EventScheduleEntity, real: TrackingClockInteractionEntity[] }[]>> => GetDayPlannedVsRealApiImpl(userId, date);
}