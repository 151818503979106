import { Either, isLeft, right } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import { GetSummaryAttendanceInTimeResponse, SummaryTypeAttendance } from "../../../../../domain/repositories/TrackingTimeRepository";
import GraphApi from "../../../../settings/GraphApi";
import DateParse from "../../../../../ui/utils/DateParse";
import TypeEventScheduleEntity from "../../../../../domain/entities/TypeEventScheduleEntity";
import { DateOperations } from "../../../../../ui/utils/DateOperations";
import BusinessUnitEntity from "../../../../../domain/entities/BusinessUnitEntity";
import UserEntity from "../../../../../domain/entities/UserEntity";
import GroupEntity from "../../../../../domain/entities/GroupEntity";

const GetSummaryAttendanceInTimeRangeApiImpl = async (businessUnit: BusinessUnitEntity[], supervisors: UserEntity[], employees: UserEntity[], groups: GroupEntity[], tagTypes: TypeEventScheduleEntity[], startDate: Date, endDate: Date): Promise<Either<ExceptionEntity, GetSummaryAttendanceInTimeResponse>> => {
    const response = await GraphApi.multipleQuery([
        {
            name: "getShiftMetrics",
            params: {
                metricsInput: {
                    employee_id: employees.map(user => parseInt(user.id)),
                    start_date: DateParse.formatDateForApi(startDate),
                    end_date: DateParse.formatDateForApi(endDate),
                }
            },
            results: ["date", "hours_per_day"],
        },
        {
            name: "getShift_modifiersMetrics",
            params: {
                metricsInput: {
                    employee_id: employees.map(user => parseInt(user.id)),
                    start_date: DateParse.formatDateForApi(startDate),
                    end_date: DateParse.formatDateForApi(endDate),
                },
            },
            results: ["date", "hours_per_day", "tag_type_id"],
        },
        {
            name: "getTimeTrackingMetrics",
            params: {
                metricsInput: {
                    employee_id: employees.map(user => parseInt(user.id)),
                    start_date: DateParse.formatDateForApi(startDate),
                    end_date: DateParse.formatDateForApi(endDate),
                },
            },
            results: ["date", "hours_per_day", "tag_type_id"],
        },
    ], true);
    if (isLeft(response)) return response;

    //sort by types 
    const orderByTypes: {
        [key: string]: SummaryTypeAttendance;
    } = {};

    tagTypes.forEach((type) => {
        orderByTypes[type.id] = {
            totalPlanned: 0,
            totalReal: 0,
            typeEvent: type,
            dateToDate: []
        };
    });

    const workTimeType = tagTypes.find((type) => type.id == "1" || type.name == "Work Time");
    const _createType = (tagTypeId: string) => {
        if (orderByTypes[tagTypeId] == undefined) {
            orderByTypes[tagTypeId] = {
                dateToDate: [],
                totalPlanned: 0,
                totalReal: 0,
                typeEvent: tagTypes.find(type => type.id == tagTypeId) ?? workTimeType!,
            }
        }
    }

    //first planned modifiers
    response.right.data.getShift_modifiersMetrics.forEach((modifier: any) => {
        _createType(modifier.tag_type_id);
        orderByTypes[modifier.tag_type_id].totalPlanned += modifier.hours_per_day;
        const _currentDate = DateParse.stringToDate(modifier.date);
        const _currentDateToDate = orderByTypes[modifier.tag_type_id].dateToDate.find((dateToDate) => DateOperations.isSameDate(dateToDate.date, _currentDate));
        if (_currentDateToDate) {
            _currentDateToDate.totalPlanned += modifier.hours_per_day;
        } else {
            orderByTypes[modifier.tag_type_id].dateToDate.push({
                date: _currentDate,
                totalPlanned: modifier.hours_per_day,
                totalReal: 0,
            });
        }
    });

    //now the work time planned
    response.right.data.getShiftMetrics.forEach((shift: any) => {
        const _currentDate = DateParse.stringToDate(shift.date);
        const otherTasksInThisDay = response.right.data.getShift_modifiersMetrics.filter((modifier: any) => modifier.date == shift.date && modifier.tag_type_id != workTimeType!.id).reduce((acc: number, modifier: any) => acc + modifier.hours_per_day, 0);
        orderByTypes[workTimeType!.id].totalPlanned += (shift.hours_per_day - otherTasksInThisDay);

        const _currentDateToDate = orderByTypes[workTimeType!.id].dateToDate.find((dateToDate) => DateOperations.isSameDate(dateToDate.date, _currentDate));
        if (_currentDateToDate) {
            _currentDateToDate.totalPlanned += shift.hours_per_day;
        } else {
            orderByTypes[workTimeType!.id].dateToDate.push({
                totalPlanned: shift.hours_per_day - otherTasksInThisDay,
                totalReal: 0,
                date: _currentDate,
            });
        }
    });

    //second real time
    response.right.data.getTimeTrackingMetrics.forEach((time: any) => {
        if (time != null) {
            _createType(time.tag_type_id);
            orderByTypes[time.tag_type_id].totalReal += time.hours_per_day;
            const _currentDate = DateParse.stringToDate(time.date);
            const _currentDateToDate = orderByTypes[time.tag_type_id].dateToDate.find((dateToDate) => DateOperations.isSameDate(dateToDate.date, _currentDate));
            if (_currentDateToDate) {
                _currentDateToDate.totalReal += time.hours_per_day;
            } else {
                orderByTypes[time.tag_type_id].dateToDate.push({
                    date: _currentDate,
                    totalPlanned: 0,
                    totalReal: time.hours_per_day,
                });
            }
        }
    });

    // //now fill all dates and order it
    const convertedToArray = Object.values(orderByTypes);

    Array.from({ length: (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1 }, (_, index) => {
        const _currentDate = new Date(startDate);
        _currentDate.setDate(_currentDate.getDate() + index);
        convertedToArray.forEach((type) => {
            if (type.dateToDate.find((dateToDate) => DateOperations.isSameDate(dateToDate.date, _currentDate)) == undefined) {
                type.dateToDate.push({
                    date: _currentDate,
                    totalPlanned: 0,
                    totalReal: 0,
                });
            }
        });
    });

    // //sort by date
    convertedToArray.forEach((type) => {
        type.dateToDate.sort((a, b) => a.date.getTime() - b.date.getTime());
    });

    const result: GetSummaryAttendanceInTimeResponse = {
        statusUsers: [],
        summary: convertedToArray,
    };

    return right(result);
}

export default GetSummaryAttendanceInTimeRangeApiImpl;
