import { FC, useEffect, useRef, useState } from 'react';
import AutoCompleteWithCheckProps, { AutoCompleteItem } from './AutoCompleteWithCheckProps';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ErrorMessage } from '@hookform/error-message';
import Validators from '../../../utils/Validators';
import './AutoCompleteWithCheckComponentStyles.scss';

const AutoCompleteWithCheckComponent: FC<AutoCompleteWithCheckProps<unknown>> = ({ keyName, label, rounded, formInfo, onSearch, onChange, required, disabled, multiple }) => {
  const { formState: { errors }, register, watch, setValue, getValues } = formInfo;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const selected:AutoCompleteItem<unknown>[] = watch(keyName) == "" ? [] : watch(keyName);
  const [options, setOptions] = useState<AutoCompleteItem<unknown>[]>([]);
  const refInput = useRef<any>();

  let _timerTap: any;
  const _handleChangeText = (data: string) => {
    clearTimeout(_timerTap);
    setIsLoading(true);
    _timerTap = setTimeout(() => _handleSearch(data), 2000);
  }

  const _handleSearch = async (word: string) => {
    const response = await onSearch(word);
    setOptions(response);
    setIsLoading(false);
  }

  const _getPlaceHolder = () => {
    if (selected == undefined ||  selected?.length == 0) return label;
    return selected?.map((sel: any) => sel.label).join(', ');
  }

  const _handlePickOption = (data: any) => {
    const value = watch(keyName);
    let newValue;
    if(data.length == 0) return;
    if (value == "" || !multiple) {
      setValue(keyName, data);
    } else if (value.some((val: any) => val.id == data[0].id)) {
      newValue = value.filter((val: any) => val.id != data[0].id);
      setValue(keyName, newValue);
    }
    else {
      newValue = value.concat(data);
      setValue(keyName, newValue);
    }
    onChange && typeof onChange === 'function' && onChange(data[0]);
    refInput.current.clear();
  }

  const handleOnFocus = () => {
    
    setOptions([]);
    setIsLoading(true);
    _handleSearch('');
  }
  
  useEffect(() => {
    _handleSearch('');
  }, []);


  return <div className={`form-group auto_complete_with_check_component ${errors[keyName] ? 'error' : ''}`}>
    <label>{label}</label>
    <Typeahead options={options}
      disabled={disabled}
      onInputChange={_handleChangeText}
      onChange={_handlePickOption}
      multiple={multiple}
      onFocus={handleOnFocus}
      ref={refInput}
      placeholder={_getPlaceHolder()}
      // selected={options.filter((option) => selected?.includes(option.id))}
      isLoading={isLoading}
      id={`${keyName}_autocomplete`}
      renderMenuItemChildren={(option: any) => <div className='item_autocomplete'>
        <input type="checkbox" name="" className='me-2' checked={selected?.some((sel: any) => sel.id == option.id)} readOnly/>
        {option.image && <img className={`option_image ${rounded && 'rounded_image'}`} src={option.image} alt="" />}
        {option.label}
      </div>}
    />
    <input type="hidden"  {...register(keyName, Validators({ required: required }))} />
    <ErrorMessage as="aside" errors={errors} name={keyName} />
  </div>

};

AutoCompleteWithCheckComponent.defaultProps = {
  required: false,
  disabled: false,
  multiple: true,
  rounded: false,
}

export default AutoCompleteWithCheckComponent;
