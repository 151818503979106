import { injectable } from "inversify";
import AIRepository from "../../../domain/repositories/AIRepository";
import GetAISummariesByIdApiImpl from "./api/impl/GetAISummariesApiImpl";
import GetAISummaryByRecordingApiImpl from "./api/impl/GetAISummaryByRecordingApiImpl";
import GetAIEmbedDashboardApiImpl from "./api/impl/GetAIEmbedDashboardApiImpl";
import SearchAISummariesApiImpl from "./api/impl/SearchAISummariesApiImpl";
import SearchAISummariesLanguagesApiImpl from "./api/impl/SearchAISummariesLanguagesApiImpl";
import SearchAISummariesEntitiesApiImpl from "./api/impl/SearchAISummariesEntitiesApiImpl";
import GetAIAgentsApiImpl from "./api/impl/GetAIAgentsApiImpl";
import GetAICustomersApiImpl from "./api/impl/GetAICustomersApiImpl";
import GetAISummaryByUserApiImpl from "./api/impl/GetAISummaryByCustomerApiImpl";
import UpdateAISummaryByRecordingApiImpl from "./api/impl/UpdateAISummaryByRecordingApiImpl";
@injectable()
class AIRepositoryDev implements AIRepository {
    getAISumaries = async (params: string): Promise<any> =>  GetAISummariesByIdApiImpl(params);
    getAISumaryByRecord = async (record: string): Promise<any> => GetAISummaryByRecordingApiImpl(record);
    updateAISumaryByRecord = async (record: string, content: any): Promise<any> => UpdateAISummaryByRecordingApiImpl(record, content);
    getAIEmbedURL = async (): Promise<any> => GetAIEmbedDashboardApiImpl();
    searchAISummaries = async (query: any): Promise<any> => SearchAISummariesApiImpl(query);
    searchAISummariesLanguages = async (): Promise<any> => SearchAISummariesLanguagesApiImpl();
    searchAISummariesEntities = async (): Promise<any> => SearchAISummariesEntitiesApiImpl();
    getAIAgents = async(): Promise<any> => GetAIAgentsApiImpl();
    getAICustomers = async(): Promise<any> => GetAICustomersApiImpl();
    getAIPerson = async(person: string, type: string, params: string): Promise<any> => GetAISummaryByUserApiImpl(person, type, params);
}

export default AIRepositoryDev;