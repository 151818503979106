import { FC, useContext, useState } from "react";
import DataLoaderTableComponentProps from "./DataLoaderTableComponentProps";
import DateParse from "../../../../../utils/DateParse";
import ShiftEntity from "../../../../../../domain/entities/ShiftEntity";
import di from "../../../../../../di/DependencyInjection";
import GetShiftAsEventsUseCase, { GetShiftAsEventsUseCaseName } from "../../../../../../domain/use_cases/calendar/GetShiftAsEventsUseCase";
import CardEventScheduleComponent from "../../../../../components/calendar/cardEventSchedule/CardEventScheduleComponent";
import { DateOperations } from "../../../../../utils/DateOperations";
import LocalizationContext from "../../../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../../../domain/providers/localization/LocalizationContextType";

const DataLoaderTableComponent: FC<DataLoaderTableComponentProps> = ({ data, week }) => {
    const {i18n} = useContext(LocalizationContext) as LocalizationContextType;
    const _getShiftAsEvents = (shift: ShiftEntity) => di.get<GetShiftAsEventsUseCase>(GetShiftAsEventsUseCaseName).call(shift);
    if (data.length == 0 || week.length == 0) return <></>
    return <div className="data_loader_table_component">
        <div className="w-100 overflow-auto">
            <table className="table">
                <thead >
                    <tr>
                        <th>itel email</th>
                        {week.map((day, index) => <th key={index}>{DateParse.getDayOfCalendar(day)} {day.getDate()}</th>)}
                        <th>Errors</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => <tr key={index}>
                        <td>{item.email}</td>
                        {week.map((day, index) => <td key={index}>
                            {item.shifts.flatMap((shift) => _getShiftAsEvents(shift)).filter((event) => DateOperations.isSameDate(event.dateStart, day) || DateOperations.isSameDate(event.dateEnd, day)).map((event, index) => <div key={index} className="event my-2">
                                <CardEventScheduleComponent event={event} />
                            </div>)}
                        </td>)}
                        <td>{item.error && <div className="text-danger">{i18n(item.error)}</div>}</td>
                    </tr>)}
                </tbody>
            </table>
        </div>
    </div>
}

export default DataLoaderTableComponent;