import { FC, useContext, useEffect, useState } from "react";
import { To, useLocation, useNavigate, useParams } from "react-router-dom";
import { Either, isRight, left, right } from "fp-ts/lib/Either";
import di from "../../../../di/DependencyInjection";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import NotFoundComponent from "../../../components/notFound/NotFoundComponent";
import { DateOperations } from "../../../utils/DateOperations";
import ModalsContext from "../../../../domain/providers/modal/ModalsContext";
import ModalsContextType from "../../../../domain/providers/modal/ModalsContextType";
import CalendarWeekComponent from "../../../components/calendar/calendarWeek/CalendarWeekComponent";
import TypeEventScheduleEntity from "../../../../domain/entities/TypeEventScheduleEntity";
import UserContext from "../../../../domain/providers/user/UserContext";
import UserContextType from "../../../../domain/providers/user/UserContextType";
import EventScheduleEntity from "../../../../domain/entities/EventScheduleEntity";
import ShiftEntity from "../../../../domain/entities/ShiftEntity";
import UserNameWithPhotoComponent from "../../../components/user/userNameWithPhoto/UserNameWithPhotoComponent";
import UserEntity from "../../../../domain/entities/UserEntity";
import ExceptionEntity from "../../../../domain/entities/ExceptionEntity";
import CreateEventFormComponent from "../../../components/calendar/createEventForm/CreateEventFormComponent";
import LocalizationContext from "../../../../domain/providers/localization/LocalizationContext";
import LocalizationContextType from "../../../../domain/providers/localization/LocalizationContextType";
import KeyWordLocalization from "../../../providers/localization/dictionaries/KeyWordLocalization";
import ButtonComponent from "../../../components/button/ButtonComponent";
import AsignShiftUseCase, { AsignShiftUseCaseName } from "../../../../domain/use_cases/shift/AsignShiftUseCase";
import { getDayOfCalendar } from "../../../utils/DateParse";
import GetUserCalendarOfDaysUseCase, { GetUserCalendarOfDaysUseCaseName } from "../../../../domain/use_cases/calendar/GetUserCalendarOfDaysUseCase";
import Icons from "../../../assets/Icons";
import CreateShiftFormComponent from "../../../components/calendar/createShiftForm/CreateShiftFormComponent";
import ConfirmModalComponent from "../../../components/modals/confirmModal/ConfirmModalComponent";
import { ChangesOfCalendarWeekParam } from "../../../../domain/repositories/ShiftRepository";
import UpdateUserWeekUseCase, { UpdateUserWeekUseCaseName } from "../../../../domain/use_cases/shift/UpdateUserWeekUseCase";


const changes: ChangesOfCalendarWeekParam = {
    eventsChanged: [],
    shiftsChanged: [],
    eventsDeleted: [],
    shiftsDeleted: [],
    eventsCreated: [],
    shiftsCreated: [],
};
const CalendarShiftPage: FC<{}> = () => {
    const { user } = useContext(UserContext) as UserContextType;
    const { addToast, openModalDelete } = useContext(ModalsContext) as ModalsContextType;
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const location = useLocation();
    const navigate = useNavigate();

    const { shiftsCreating, weekSelected, userEditing } = location.state as {
        shiftsCreating: ShiftEntity[] | undefined,
        weekSelected: Date[],
        userEditing: UserEntity | undefined,
    };

    const weeksToSelect = weekSelected.map(week => {
        return ({
            label: `${getDayOfCalendar(week)} ${week.getDate()}`,
            date: week
        }
        )
    });

    const { shiftId } = useParams<{ shiftId: string }>();
    const { openModalCustom } = useContext(ModalsContext) as ModalsContextType;

    const [calendar, setCalendar] = useState<ShiftEntity[] | undefined>(undefined); //undefined: loading, null: not found
    const [editingMode, setEditingMode] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const _handleCreateShift = async () => {
        if (loading) return;
        if (shiftsCreating == undefined) return setLoading(false);
        if (_checkIfEventIsOutShift()) {
            addToast(i18n(KeyWordLocalization.CalendarShiftPageEventsOutShift), 'error', 5000);
            setLoading(false);
            return;
        }
        else setLoading(true);
        const response = await di.get<AsignShiftUseCase>(AsignShiftUseCaseName).call(shiftsCreating[0]!.users, calendar!, false);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.AddShiftComponentAssignedShift), 'success', 5000);
            setLoading(false);
            navigate(-1 as To, {
                replace: true,
            });
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), 'error', 5000);
            setLoading(false);
        }


    }

    const _checkIfEventIsOutShift = (): boolean => {
        const someEventOutShift = calendar?.some(day => {
            const events = day.events;
            const initHour = day.initHour;
            const endHour = day.endHour;
            const eventsOutShift = events.filter((event) => event.id != '_delete').some(event => event.dateStart < initHour || event.dateEnd > endHour);
            return eventsOutShift;
        });
        return someEventOutShift ?? false;
    }

    const _getCalendarOfShift = async () => {
        if (userEditing === undefined || weekSelected == undefined) return;
        const startDate = weekSelected[0];
        const endDate = weekSelected[weekSelected.length - 1];
        const response = await di.get<GetUserCalendarOfDaysUseCase>(GetUserCalendarOfDaysUseCaseName).call(userEditing.id, startDate, endDate);
        if (isRight(response)) {
            return setCalendar(response.right);
        }
        else return setCalendar([]);
    }

    const _clearChanges = () => {
        changes.eventsChanged = [];
        changes.eventsCreated = [];
        changes.eventsDeleted = [];
        changes.shiftsChanged = [];
        changes.shiftsCreated = [];
        changes.shiftsDeleted = [];
    }
    // const _seeAllUsers = async () => openModalCustom("lg", i18n(KeyWordLocalization.CalendarShiftPageSearchUsersTitle, { shiftId: shiftId }), <SearchUsersModalComponent handleSearch={_handleSearchUsersOfShift} items={shiftsCreating} editable={false} />);

    const _boolCanAddEvent = user?.permissions?.shift?.calendar?.create && (shiftsCreating != undefined || editingMode && (userEditing != undefined));
    const _boolCanEditEvent = user?.permissions?.shift?.calendar?.update && (shiftsCreating != undefined || editingMode && (userEditing != undefined));
    const _boolCanDeleteEvent = user?.permissions?.shift?.calendar?.delete && (shiftsCreating != undefined || editingMode && (userEditing != undefined));
    const _boolCanAddShift = user?.permissions?.shift?.calendar?.create && (shiftsCreating != undefined || editingMode && (userEditing != undefined));
    const _boolCanEditShift = user?.permissions?.shift?.calendar?.update && (shiftsCreating != undefined || editingMode && (userEditing != undefined));
    const _boolCanDeleteShift = user?.permissions?.shift?.calendar?.delete && (shiftsCreating != undefined || editingMode && (userEditing != undefined));

    const _handleAddEventToCalendar = async (eventType: TypeEventScheduleEntity | null, date: Date | undefined): Promise<void> => {
        if (date != null && DateOperations.isBeforeToday(date)) return;
        const _handleCreateEvent = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
            const currentShift = calendar!.find((day) => day.initHour.getTime() <= event.dateStart.getTime() && day.endHour.getTime() >= event.dateEnd.getTime());
            if (currentShift == undefined) {
                return left({ code: KeyWordLocalization.CalendarShiftPageEventsOutShift });
            }
            event.shiftId = currentShift.id;
            currentShift.events.push((event));
            changes.eventsCreated.push(event);
            setCalendar([...calendar!]);
            return right(event);
        }
        const disabledPreviousDays = weeksToSelect.filter(day => !DateOperations.isBeforeToday(day.date));
        openModalCustom("lg", i18n(KeyWordLocalization.CalendarShiftPageCreateEvent), <CreateEventFormComponent handleAddEvent={_handleCreateEvent} type={eventType} date={date} dates={disabledPreviousDays} />);
    }

    const _handleEditEventToCalendar = async (eventToEdit: EventScheduleEntity): Promise<void> => {
        const _handleEditEvent = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
            const currentShift = calendar!.find((day) => day.initHour.getTime() <= event.dateStart.getTime() && day.endHour.getTime() >= event.dateEnd.getTime());
            if (currentShift == undefined) {
                return left({ code: KeyWordLocalization.CalendarShiftPageEventsOutShift });
            }
            event.shiftId = currentShift.id;
            changes.eventsChanged.push(event);
            const copyCalendar = [...calendar!];
            setCalendar(copyCalendar);
            return right(event);
        };
        const disabledPreviousDays = weeksToSelect.filter(day => !DateOperations.isBeforeToday(day.date));
        openModalCustom("lg", i18n(KeyWordLocalization.CalendarShiftPageEditEvent), <CreateEventFormComponent handleEditEvent={_handleEditEvent} date={eventToEdit.dateStart} editEvent={eventToEdit} dates={disabledPreviousDays} />);
    }

    const _handleDeleteEventInternal = async (event: EventScheduleEntity): Promise<Either<ExceptionEntity, EventScheduleEntity>> => {
        const copyCalendar = calendar!.map(day => {
            day.events = day.events.filter(e => !(e.dateStart.getTime() == event.dateStart.getTime() && e.dateEnd.getTime() == event.dateEnd.getTime() && e.id == event.id && e.type.id == event.type.id));
            return day;
        });

        changes.eventsCreated = changes.eventsCreated.filter(e => !(e.dateStart.getTime() == event.dateStart.getTime() && e.dateEnd.getTime() == event.dateEnd.getTime() && e.id == event.id && e.type.id == event.type.id));
        changes.eventsChanged = changes.eventsChanged.filter(e => !(e.dateStart.getTime() == event.dateStart.getTime() && e.dateEnd.getTime() == event.dateEnd.getTime() && e.id == event.id && e.type.id == event.type.id));
        changes.eventsDeleted.push(event);
        setCalendar(copyCalendar);
        return right(event);
    };

    const _handleDeleteEvent = async (event: EventScheduleEntity): Promise<void> => {
        openModalCustom("sm", i18n(KeyWordLocalization.CalendarShiftPageDeleteEventTitle), <ConfirmModalComponent onConfirm={() => _handleDeleteEventInternal(event)} text={i18n(KeyWordLocalization.CalendarShiftPageDeleteEventDescription)} />, true);
    }

    const _handleAddShiftToCalendar = async (date: Date): Promise<void> => {
        const onShiftCreated = async (shift: ShiftEntity): Promise<Either<ExceptionEntity, ShiftEntity>> => {
            const copyCalendar = [...calendar!, shift];
            changes.shiftsCreated.push(shift);
            const ordered = copyCalendar.sort((a, b) => a.initHour.getTime() - b.initHour.getTime());
            setCalendar(ordered);
            return right(shift);
        };

        openModalCustom("lg", i18n(KeyWordLocalization.CalendarShiftPageEditEvent), <CreateShiftFormComponent handleAddShift={onShiftCreated} date={date} />);
    }

    const _handleEditShiftToCalendar = async (shift: ShiftEntity): Promise<void> => {
        const _handleEditEvent = async (shift: ShiftEntity): Promise<Either<ExceptionEntity, ShiftEntity>> => {
            const copyCalendar = [...calendar!];
            changes.shiftsChanged.push(shift);
            setCalendar(copyCalendar);
            deleteEventsOutOfShiftEdit(shift);
            return right(shift);
        };
        openModalCustom("lg", i18n(KeyWordLocalization.CalendarShiftPageEditShift), <CreateShiftFormComponent handleEditShift={_handleEditEvent} shift={shift} />);
    }

    const deleteEventsOutOfShiftEdit = (shift: ShiftEntity) => {
        shift.events.forEach(event => {
            const isBefore = event.dateStart.getTime() <= shift.initHour.getTime() && event.dateEnd.getTime() <= shift.initHour.getTime();
            const isAfter = event.dateStart.getTime() >= shift.endHour.getTime() && event.dateEnd.getTime() >= shift.endHour.getTime();
            if (isBefore || isAfter) {
                _handleDeleteEventInternal(event);
            } else {
            }
        });
    }

    const _handleDeleteShift = async (shift: ShiftEntity): Promise<void> => {
        const _handleDeleteShiftInternal = async (): Promise<Either<ExceptionEntity, ShiftEntity>> => {
            const copyCalendar = calendar!.filter(day => day.initHour.getTime() != shift.initHour.getTime() && day.endHour.getTime() != shift.endHour.getTime());
            changes.shiftsDeleted.push(shift);
            changes.eventsChanged = changes.eventsChanged.filter(e => shift.initHour.getTime() <= e.dateStart.getTime() && shift.endHour.getTime() >= e.dateEnd.getTime());
            changes.eventsCreated = changes.eventsCreated.filter(e => shift.initHour.getTime() <= e.dateStart.getTime() && shift.endHour.getTime() >= e.dateEnd.getTime());
            setCalendar(copyCalendar);
            return right(shift);
        };
        //TODO change alert for confirm modal
        openModalCustom("sm", i18n(KeyWordLocalization.CalendarShiftPageDeleteShiftTitle), <ConfirmModalComponent onConfirm={_handleDeleteShiftInternal} text={i18n(KeyWordLocalization.CalendarShiftPageDeleteShiftDescription)} />, true);
    }

    const _handleChangesOfCalendar = async () => {
        if (loading) return;
        if (changes.eventsChanged.length == 0 &&
            changes.eventsCreated.length == 0 &&
            changes.eventsDeleted.length == 0 &&
            changes.shiftsChanged.length == 0 &&
            changes.shiftsCreated.length == 0 &&
            changes.shiftsDeleted.length == 0
        ) {
            setLoading(false);
            return setEditingMode(false);
        }
        else setLoading(true);
        //TODO add code of edited events
        if (_checkIfEventIsOutShift()) {
            addToast(i18n(KeyWordLocalization.CalendarShiftPageEventsOutShift), 'error', 5000);
            setLoading(false);
            return;
        }
        console.log('changes', changes);
        const response = await di.get<UpdateUserWeekUseCase>(UpdateUserWeekUseCaseName).call(userEditing!.email, changes);
        console.log('changes response', response);
        if (isRight(response)) {
            addToast(i18n(KeyWordLocalization.CalendarShiftPageChangesSaved), 'success', undefined);
            _clearChanges();
            _getCalendarOfShift();
            setEditingMode(false);
        } else {
            addToast(i18n(response.left.code ?? KeyWordLocalization.UnknownError), 'error', undefined);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (shiftsCreating != undefined) {
            // shiftsCreating.forEach((shift, index) => {
            //     shift.id = 'fake_'+index.toString();
            // });
            setCalendar(shiftsCreating);
            _clearChanges();
        }
    }, [shiftsCreating]);

    useEffect(() => {
        _getCalendarOfShift();
        _clearChanges();
    }, [userEditing, weekSelected]);

    if (shiftsCreating === undefined && calendar === undefined) return <LoadingComponent />
    if (shiftsCreating === null && calendar === null) return <NotFoundComponent />

    return <div className="calendar_shift_page">
        <div className="page_content">
            <div className="d-flex flex-wrap">
                {userEditing && <div className="mx-3">
                    <UserNameWithPhotoComponent user={userEditing} showRole={false} /> </div>}
                {shiftsCreating?.at(0)?.users?.map((user, index) => index < 3 ? <div className="mx-3" key={index}>
                    <UserNameWithPhotoComponent user={user} showRole={false} /> </div> : '')}
                <div className="d-flex align-items-center">
                    {/* <span className="text_underline text_blue text_bold hover" onClick={_seeAllUsers}>{i18n(KeyWordLocalization.CalendarShiftPageSeeAll)}</span> */}
                </div>
            </div>
            {calendar !== undefined ? <CalendarWeekComponent
                loading={loading}
                calendar={calendar}
                handleAddEvent={_boolCanAddEvent ? _handleAddEventToCalendar : undefined}
                handleEditEvent={_boolCanEditEvent ? _handleEditEventToCalendar : undefined}
                handleDeleteEvent={_boolCanDeleteEvent ? _handleDeleteEvent : undefined}
                handleAddShift={_boolCanAddShift ? _handleAddShiftToCalendar : undefined}
                handleEditShift={_boolCanEditShift ? _handleEditShiftToCalendar : undefined}
                handleDeleteShift={_boolCanDeleteShift ? _handleDeleteShift : undefined}
                fullClickeable={true} currentWeek={weekSelected} /> : <LoadingComponent />}
            {shiftsCreating != undefined ? <div className={`floating_button`}>
                <ButtonComponent isLoading={loading} icon={<Icons.Plus />} onClick={_handleCreateShift} text={i18n(KeyWordLocalization.CalendarShiftPageCreateShift)} />
            </div> : ''}
            {userEditing && !editingMode && <div className="floating_button" style={{ zIndex: 400 }}>
                <ButtonComponent isLoading={loading} icon={<Icons.EditPencil />} onClick={() => setEditingMode(true)} text={i18n(KeyWordLocalization.CalendarShiftPageEdit)} />
            </div>}
            {userEditing && editingMode && <div className="floating_button" style={{ zIndex: 400 }}>
                <ButtonComponent isLoading={loading} icon={<Icons.CheckRounded />} onClick={_handleChangesOfCalendar} text={i18n(KeyWordLocalization.CalendarShiftPageSaveEditing)} />
            </div>}
        </div>
    </div >
}

export default CalendarShiftPage;