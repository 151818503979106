import { Either } from "fp-ts/lib/Either";
import ExceptionEntity from "../../../../../domain/entities/ExceptionEntity";
import GraphApi from "../../../../settings/GraphApi";
import DateParse from "../../../../../ui/utils/DateParse";

const StartTrackingClockApiImpl = async (detailedActivity: string | undefined, tagType: string | undefined, shiftId: string): Promise<Either<ExceptionEntity, void>> => {
    const response = await GraphApi.multipleMutation([{
        name: 'updateTime_tracking',
        params: {
            updateTime_trackingInput: {
                shift_id: parseInt(shiftId),
                clock_out_time: DateParse.formatDate(new Date()),
            }
        },
        results: ['activity_type_id', 'clock_in_time', 'clock_out_time', 'details', 'shift_id', 'tag_type_id', 'time_tracking_id']
    },
    {
        name: 'createTime_tracking',
        params: { createTime_trackingInput: { activity_type_id: 7, clock_in_time: DateParse.formatDate(new Date()), clock_out_time: "", details: detailedActivity ?? "", shift_id: shiftId, tag_type_id: parseInt(tagType ?? '1') }, },
        results: ['clock_in_time', 'activity_type_id', 'clock_out_time', 'details', 'shift_id', 'tag_type_id', 'time_tracking_id']
    }
    ]);
    return response;
}

export default StartTrackingClockApiImpl;