import './CardEventScheduleComponentStyles.scss';
import { FC, useContext } from "react";
import CardEventScheduleComponentProps from "./CardEventScheduleComponentProps";
import DateParse from "../../../utils/DateParse";
import Icons from '../../../assets/Icons';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';

const CardEventScheduleComponent: FC<CardEventScheduleComponentProps> = ({ event, onClick, isOutOfShift }) => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    return <div className={`card_event_schedule_component ${isOutOfShift == true && 'out_of_shift'} ${onClick && 'hover'}`} onClick={() => onClick?.(event!)}>
        <div className="content">
            <div className="line_event" style={{ background: event?.type?.color }}> </div>
            {/* <h1>{event?.id}</h1> */}

            <div className="">
                <div className="w-100 d-flex justify-content-between">
                    <div className="flex-grow-1 me-2 text_ellipsis"><strong>{event?.name}</strong></div>
                    <span className='text-end'>{DateParse.timeWithPmAm(event?.dateStart as Date)} - {DateParse.timeWithPmAm(event?.dateEnd as Date)}</span>
                </div>
                {isOutOfShift && <div className="text-danger d-flex align-items-center">
                    <Icons.Error />
                    The event is out of the shifts
                </div>}
                {/* {event?.description && <div className="mt-2">{event?.description}</div>} */}
                <div className="d-flex mt-1 billable_container">
                    <div className="me-2 d-flex align-items-center">{event?.type.billable ? <Icons.Money /> : <Icons.MoneyOff />} <span className='mx-2'>{i18n(event?.type.billable ? 'Billable' : 'No Billable')}</span> </div>
                    <div className="me-2 d-flex align-items-center">{event?.type.payable ? <Icons.Money /> : <Icons.MoneyOff />} <span className='mx-2'>{i18n(event?.type.payable ? 'Payable' : 'No Payable')}</span> </div>
                </div>
            </div>
        </div>
    </div >
}

CardEventScheduleComponent.defaultProps = {
    isOutOfShift: false,
}
export default CardEventScheduleComponent;