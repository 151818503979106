//import { Table } from "react-bootstrap";
import React from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { Table, PropertyFilter, Header } from '@cloudscape-design/components';

import "./ContactTable.css";


const COLUMN_DEFINITIONS = [
  {
    id: "moment",
    header: "Moment",
    cell: (d: any) => d.moment,
    isRowHeader: true,
    sortingField: "moment",
    minWidth:150
  },
  {
    id: "theme",
    header: "Theme",
    cell: (d: any) => d.theme,
    isRowHeader: true,
    sortingField: "theme",
    minWidth:150
  },
  {
    id: "speaker",
    header: "Speaker",
    cell: (d: any) => d.speaker,
    isRowHeader: true,
    sortingField: "speaker",
    minWidth:150
  },
  {
    id: "teams",
    header: "Teams",
    cell: (d: any) => d.teams,
    isRowHeader: true,
    sortingField: "teams",
    minWidth:150
  },
  {
    id: "types",
    header: "Types",
    cell: (d: any) => d.types,
    isRowHeader: true,
    sortingField: "types",
    minWidth:150
  },
  {
    id: "audio",
    header: "Audio",
    cell: (d: any) => d.audio,
    isRowHeader: true,
    sortingField: "audio",
    minWidth:150
  },
];

const getMatchesCountText = function getMatchesCountText(count: any) {
  return count === 1 ? `1 match` : `${count} matches`;
}


type ContactTableProps = {
    data: Array<any>;
    loading?: boolean;
    header?: React.ReactNode; // Adjust this type based on what `header` is supposed to be
    variant?: any; // Adjust the variants accordingly,
    tableTitle?: string;
  };



export const ContactTableMoments: React.FC<ContactTableProps> = ({ data = [], loading = false, header, variant='container', tableTitle }) => {

  const preferences: any = {
      "pageSize": 30,
      "wrapLines": false,
      "stripedRows": false,
      "contentDensity": "comfortable",
      "visibleContent": [
          "moment",
          "theme",
          "speaker",
          "teams",
          "types",
          "audio",
          "enable",
          "actions"

      ]
  }


  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    data,
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: "moment",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Moment",
            groupValuesLabel: "Moment"
          },
          {
            key: "theme",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Theme",
            groupValuesLabel: "Theme"
          },
          {
            key: "speaker",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Speaker",
            groupValuesLabel: "Speaker"
          },
          {
            key: "teams",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Teams",
            groupValuesLabel: "Teams"
          },
          {
            key: "type",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Type",
            groupValuesLabel: "Type"
          },
          {
            key: "audio",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Audio",
            groupValuesLabel: "Audio"
          },
          {
            key: "enable",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Enable",
            groupValuesLabel: "Enable"
          },
        ],
        empty: (
          <div>No Calls.</div>
        ),
        noMatch: (
          <div>No matches.</div>
        )
      },
      pagination: { pageSize: 100000000 },
      sorting: {},
      selection: {},
    });

    console.log(items);
    

    const handleNavigationToRecord = (record: string) => {
      
    }

    return (
      <Table
        {...collectionProps}
        header={
          <Header
          >
            {tableTitle}
          </Header>
        }
        variant={variant}
        columnDefinitions={COLUMN_DEFINITIONS}
        columnDisplay={preferences.contentDisplay}
        items={items}
        //pagination={<Pagination {...paginationProps} />}
        resizableColumns={false}
        loadingText="Loading Calls"
        // onSelectionChange={onClick}
        // onRowClick={onClick}
        // selectionType="single"
        stickyHeaderVerticalOffset={20}
        stickyColumns={{ first: 0, last: 0 }}
        onRowClick={(e) => handleNavigationToRecord(e.AT_TARGET.toString())}
        filter={
          <div style={{margin: "20px"}}>
        <PropertyFilter
            {...propertyFilterProps}
            /*onChange={({ detail }) => {
              //setCallQuery(detail);
            }}*/
            //query={callQuery}
            i18nStrings={{
              filteringAriaLabel: "your choice",
              dismissAriaLabel: "Dismiss",
              filteringPlaceholder: "Find calls",
              groupValuesText: "Values",
              groupPropertiesText: "Properties",
              operatorsText: "Operators",
              operationAndText: "and",
              operationOrText: "or",
              operatorLessText: "Less than",
              operatorLessOrEqualText: "Less than or equal",
              operatorGreaterText: "Greater than",
              operatorGreaterOrEqualText:
                "Greater than or equal",
              operatorContainsText: "Contains",
              operatorDoesNotContainText: "Does not contain",
              operatorEqualsText: "Equals",
              operatorDoesNotEqualText: "Does not equal",
              editTokenHeader: "Edit filter",
              propertyText: "Property",
              operatorText: "Operator",
              valueText: "Value",
              cancelActionText: "Cancel",
              applyActionText: "Apply",
              allPropertiesLabel: "All properties",
              tokenLimitShowMore: "Show more",
              tokenLimitShowFewer: "Show fewer",
              clearFiltersText: "Clear filters",
              removeTokenButtonAriaLabel: token =>
                `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
              enteredTextLabel: text => `Use: "${text}"`
            }}
            countText={getMatchesCountText(filteredItemsCount)}
            expandToViewport={true}
          />
          </div>
          
        }
        visibleColumns={['jobName', ...preferences.visibleContent]}
      />
    );
};
