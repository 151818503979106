//import { Table } from "react-bootstrap";
import React from "react";
import { useCollection } from '@cloudscape-design/collection-hooks';
import { Table, TextFilter, Pagination, CollectionPreferences, PropertyFilter, Header } from '@cloudscape-design/components';
import Popover from "@cloudscape-design/components/popover";
import Button from "@cloudscape-design/components/button";
import StatusIndicator from "@cloudscape-design/components/status-indicator";
import Icon from "@cloudscape-design/components/icon";
import Link from "@cloudscape-design/components/link";
import { ContactTablePreferences } from "./ContactTablePreferences";


import "./ContactTable.css";
import { useNavigate } from "react-router-dom";
import { SentimentIcon } from "./components/SentimentIcon";
import { TrendIcon } from "./components/TrendIcon";
import { Formatter } from "./components/format";


const COLUMN_DEFINITIONS = [
  {
    id: "timestamp",
    header: "Timestamp",
    sortingField: "timestamp",
    cell: (d: any) => {
      return (
        <div style={{width: '100%', textAlign: 'center'}}>
          <Link href={`/aisummary/${d.key.split("parsedFiles/")[1].split(".")[0]}/${d.key.split("parsedFiles/")[1].split(".")[1]}`}>{Formatter.Timestamp(d.timestamp)}</Link>
        </div>
      )      
    },
    minWidth: 160
  },
  {
    id: "jobName",
    header: "Job Name",
    cell: (d: any) => {
      return (
        <Link href={`/aisummary/${d.key.split("parsedFiles/")[1].split(".")[0]}/${d.key.split("parsedFiles/")[1].split(".")[1]}`}>{d.jobName}</Link>
      )      
    },
    isRowHeader: true,
    sortingField: "jobName",
  },
  {
    id: "guid",
    header: "GUID",
    cell: (d: any) => d.guid,
    isRowHeader: true,
    sortingField: "guid",
    minWidth:150
  },
  {
    id: "agent",
    header: "Agent",
    cell: (d: any) => d.agent,
    isRowHeader: true,
    sortingField: "agent",
    minWidth:150
  },
  {
    id: "customer",
    header: "Customer",
    cell: (d: any) => d.customer,
    isRowHeader: true,
    sortingField: "customer",
    minWidth:150
  },
  {
    id: "queue",
    header: "Queue",
    cell: (d: any) => d.queue,
    isRowHeader: true,
    sortingField: "queue",
    minWidth:150
  },
  {
    id: "summary_resolved",
    header: "Resolved",
    cell: (d: any) => d.summary_resolved,
    isRowHeader: true,
    sortingField: "summary_resolved",
    minWidth:170
  },
  {
    id: "summary_topic",
    header: "Topic",
    cell: (d: any) => d.summary_topic,
    isRowHeader: true,
    sortingField: "summary_topic",
    Width:150
  },
  {
    id: "summary_product",
    header: "Product",
    cell: (d: any) => d.summary_product,
    isRowHeader: true,
    sortingField: "summary_product",
    minWidth:150
  },
  {
    id: "summary_summary",
    header: "Summary",
    cell: (d: any) => {
      if (d.summary_summary !== undefined) {
        return (
          <Popover
            dismissButton={false}
            position="top"
            size="large"
            triggerType="text"
            content={d.summary_summary}
          >
            {(d.summary_summary.length > 20 ? d.summary_summary.substring(0, 20) + "..." : d.summary_summary)}
          </Popover>
          /*
          <ExpandableSection headerText={(d.summary_summary.length > 50 ? d.summary_summary.substring(0,50) + "..." : d.summary_summary )}>
            {d.summary_summary}
          </ExpandableSection>*/
        )
      }
      return 'n/a';
    },
    isRowHeader: true,
    sortingField: "summary_summary",
    minWidth:200
  },
  {
    id: "callerSentimentScore",
    header: "Cust Sent",
    sortingField: "callerSentimentScore",
    cell: (d: any) => (
      <div className="d-flex justify-content-evenly">
        <SentimentIcon score={d["callerSentimentScore"]} />
        <TrendIcon trend={d["callerSentimentChange"]} />
      </div>
    ),
    minWidth: 130
  },
  {
    id: "langCode",
    header: <div className="col-header-wrapper text-left">Lang Code</div>,
    cell: (d: any) => d.lang,
    minWidth: 130,
  },
  {
    id: "duration",
    header: <div className="col-header-wrapper text-left">Duration</div>,
    cell: (d: any) => Formatter.Time(d.duration),
    minWidth: 130,
  }
];

const getMatchesCountText = function getMatchesCountText(count: any) {
  return count === 1 ? `1 match` : `${count} matches`;
}


type ContactTableProps = {
    data: Array<any>;
    loading?: boolean;
    header?: React.ReactNode; // Adjust this type based on what `header` is supposed to be
    variant?: any; // Adjust the variants accordingly
  };



export const ContactTable: React.FC<ContactTableProps> = ({ data = [], loading = false, header, variant='container' }) => {

  
  const navigate = useNavigate();

  const preferences: any = {
      "pageSize": 30,
      "wrapLines": false,
      "stripedRows": false,
      "contentDensity": "comfortable",
      "visibleContent": [
          "timestamp",
          "jobName",
          "agent",
          "callerSentimentScore",
          "langCode",
          "duration"
      ]
  }


  const { items, actions, filteredItemsCount, collectionProps, paginationProps, propertyFilterProps } = useCollection(
    data,
    {
      propertyFiltering: {
        filteringProperties: [
          {
            key: "timestamp",
            defaultOperator: '>',
            operators: ['<', '<=', '>', '>='].map(operator => ({
              operator,
              match: 'datetime',
            })),
            propertyLabel: "Timestamp",
            groupValuesLabel: "Timestamps"
          },
          {
            key: "jobName",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Job Name",
            groupValuesLabel: "Job Names"
          },
          {
            key: "guid",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "GUID",
            groupValuesLabel: "GUIDs"
          },
          {
            key: "agent",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Agent",
            groupValuesLabel: "Agents"
          },
          {
            key: "customer",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Customer",
            groupValuesLabel: "Customers"
          },
          {
            key: "queue",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Queue",
            groupValuesLabel: "Queues"
          },
          {
            key: "summary_resolved",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Resolved",
            groupValuesLabel: "Resolved"
          },
          {
            key: "summary_topic",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Topic",
            groupValuesLabel: "Topics"
          },
          {
            key: "summary_product",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Product",
            groupValuesLabel: "Products"
          },
          {
            key: "lang",
            operators: ["=", "!=", ":", "!:"],
            propertyLabel: "Language Code",
            groupValuesLabel: "Languages Codes"
          },
          {
            key: "duration",
            defaultOperator: '>',
            operators: ['<', '<=', '>', '>='],
            propertyLabel: "Duration",
            groupValuesLabel: "Durations"
          }
        ],
        empty: (
          <div>No Calls.</div>
        ),
        noMatch: (
          <div>No matches.</div>
        )
      },
      pagination: { pageSize: 100000000 },
      sorting: {},
      selection: {},
    });

    const handleNavigationToRecord = (record: string) => {
      
      /**
       * 
       * 
      navigate("/airecording", {
          state: { recording: record}
      });
       */
  }

  return (
    <Table
      {...collectionProps}
      header={
        <Header
        >
          Call List
        </Header>
      }
      variant={variant}
      columnDefinitions={COLUMN_DEFINITIONS}
      columnDisplay={preferences.contentDisplay}
      items={items}
      //pagination={<Pagination {...paginationProps} />}
      resizableColumns={true}
      loadingText="Loading Calls"
      // onSelectionChange={onClick}
      // onRowClick={onClick}
      // selectionType="single"
      stickyHeaderVerticalOffset={20}
      stickyColumns={{ first: 2, last: 0 }}
      onRowClick={(e) => handleNavigationToRecord(e.AT_TARGET.toString())}
      filter={
        <div style={{margin: "20px"}}>
      <PropertyFilter
          {...propertyFilterProps}
          /*onChange={({ detail }) => {
            //setCallQuery(detail);
          }}*/
          //query={callQuery}
          i18nStrings={{
            filteringAriaLabel: "your choice",
            dismissAriaLabel: "Dismiss",
            filteringPlaceholder: "Find calls",
            groupValuesText: "Values",
            groupPropertiesText: "Properties",
            operatorsText: "Operators",
            operationAndText: "and",
            operationOrText: "or",
            operatorLessText: "Less than",
            operatorLessOrEqualText: "Less than or equal",
            operatorGreaterText: "Greater than",
            operatorGreaterOrEqualText:
              "Greater than or equal",
            operatorContainsText: "Contains",
            operatorDoesNotContainText: "Does not contain",
            operatorEqualsText: "Equals",
            operatorDoesNotEqualText: "Does not equal",
            editTokenHeader: "Edit filter",
            propertyText: "Property",
            operatorText: "Operator",
            valueText: "Value",
            cancelActionText: "Cancel",
            applyActionText: "Apply",
            allPropertiesLabel: "All properties",
            tokenLimitShowMore: "Show more",
            tokenLimitShowFewer: "Show fewer",
            clearFiltersText: "Clear filters",
            removeTokenButtonAriaLabel: token =>
              `Remove token ${token.propertyKey} ${token.operator} ${token.value}`,
            enteredTextLabel: text => `Use: "${text}"`
          }}
          countText={getMatchesCountText(filteredItemsCount)}
          expandToViewport={true}
        />
        </div>
        
      }
      visibleColumns={['jobName', ...preferences.visibleContent]}
    />
  );
};
