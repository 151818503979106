/********************************************************************************
 * File Header - Dependency Injection Configuration
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: August 9, 2023
 *
 * Description:
 * This file contains all the dependency injection configuration for the application.
 ********************************************************************************/

import { Container } from "inversify";
import "reflect-metadata";
import SignInUseCase, { SignInUseCaseName } from "../domain/use_cases/auth/SignInUseCase";
import AuthRepository, { AuthRepositoryName } from "../domain/repositories/AuthRepository";
import AuthRepositoryFake from "../data/repositories/auth/AuthRepositoryFake";
import UserProvider, { UserProviderName } from "../domain/providers/user/UserProvider";
import UserProviderImpl from "../ui/providers/user/UserProviderImpl";
import LocalizationProvider, { LocalizationProviderName } from "../domain/providers/localization/LocalizationProvider";
import LocalizationProviderImpl from "../ui/providers/localization/LocalizationProviderImpl";
import ModalsProvider, { ModalsProviderName } from "../domain/providers/modal/ModalsProvider";
import ModalsProviderImpl from "../ui/providers/modals/ModalsProviderImpl";
import GetCurrentUserUseCase, { GetCurrentUserUseCaseName } from "../domain/use_cases/auth/GetCurrentUserUseCase";
import LoadUseCase, { LoadUseCaseName } from "../domain/use_cases/default/LoadUseCase";
import SignUpUseCase, { SignUpUseCaseName } from "../domain/use_cases/auth/SignUpUseCase";
import AuthRepositoryImpl from "../data/repositories/auth/AuthRepositoryImpl";
import ConfirmUserUseCase, { ConfirmUserUseCaseName } from "../domain/use_cases/auth/ConfirmUserUseCase";
import DeleteUserUseCase, { DeleteUserUseCaseName } from "../domain/use_cases/auth/DeleteUserUseCase";
import SendConfirmCodeUseCase, { SendConfirmCodeUseCaseName } from "../domain/use_cases/auth/SendConfirmCodeUseCase";
import SendRecoveryPasswordCodeUseCase, { SendRecoveryPasswordCodeUseCaseName } from "../domain/use_cases/auth/SendRecoveryPasswordCodeUseCase";
import SignOutUseCase, { SignOutUseCaseName } from "../domain/use_cases/auth/SignOutUseCase";
import UpdatePasswordByRecoveryUseCase, { UpdatePasswordByRecoveryUseCaseName } from "../domain/use_cases/auth/UpdatePasswordByRecoveryUseCase";
import SignInWithActiveDirectoryUseCase, { SignInWithActiveDirectoryUseCaseName } from "../domain/use_cases/auth/SignInWithActiveDirectoryUseCase";
import AuthRepositoryDev from "../data/repositories/auth/AuthRepositoryDev";
import UserRepository, { UserRepositoryName } from "../domain/repositories/UserRepository";
import UserRepositoryImpl from "../data/repositories/user/UserRepositoryImpl";
import UserRepositoryFake from "../data/repositories/user/UserRepositoryFake";
import UserRepositoryDev from "../data/repositories/user/UserRepositoryDev";
import SearchUserUseCase, { SearchUserUseCaseName } from "../domain/use_cases/user/SearchUserUseCase";

import GetAllRolesUseCase, { GetAllRolesUseCaseName } from "../domain/use_cases/roles/GetAllRolesUseCase";

import GetRoleInfoByIdUseCase, { GetRoleInfoByIdUseCaseName } from "../domain/use_cases/roles/GetRoleInfoByIdUseCase";


import UpdateUserRoleIdUseCase, { UpdateUserRoleIdUseCaseName } from "../domain/use_cases/user/UpdateUserRoleIdCase";

import SearchAllUsersUseCase, { SearchAllUsersUseCaseName } from "../domain/use_cases/allUsers/SearchAllUserUseCase";
import AllUserRepository, { AllUserRepositoryName } from "../domain/repositories/AllUsersRepository";
import AllUserRepositoryFake from "../data/repositories/allUsers/AllUserRepositoryFake";

import CreateEventInCalendarUseCase, { CreateEventInCalendarUseCaseName } from "../domain/use_cases/calendar/CreateEventInCalendarUseCase";
import EditEventInCalendarUseCase, { EditEventInCalendarUseCaseName } from "../domain/use_cases/calendar/EditEventInCalendarUseCase";
import { GetUserInfoByIdUseCase, GetUserInfoByIdUseCaseName } from "../domain/use_cases/user/GetUserInfoByIdUseCase";
import GetUserTodayCalendarUseCase from "../domain/use_cases/calendar/GetUserTodayCalendarUseCase";
import CalendarRepository, { CalendarRepositoryName } from "../domain/repositories/CalendarRepository";
import SearchSupervisorUseCase, { SearchSupervisorUseCaseName } from "../domain/use_cases/user/SearchSupervisorUseCase";
import GetUserCalendarOfDaysUseCase, { GetUserCalendarOfDaysUseCaseName } from "../domain/use_cases/calendar/GetUserCalendarOfDaysUseCase";
import BusinessUnitRepository, { BusinessUnitRepositoryName } from "../domain/repositories/BusinessUnitRepository";
import BusinessUnitRepositoryFake from "../data/repositories/businessUnit/BusinessUnitRepositoryFake";
import BusinessUnitRepositoryDev from "../data/repositories/businessUnit/BusinessUnitRepositoryDev";
import BusinessUnitRepositoryImpl from "../data/repositories/businessUnit/BusinessUnitRepositoryImpl";
import CalendarRepositoryFake from "../data/repositories/calendar/CalendarRepositoryFake";
import CalendarRepositoryDev from "../data/repositories/calendar/CalendarRepositoryDev";
import CalendarRepositoryImpl from "../data/repositories/calendar/CalendarRepositoryImpl";
import GroupRepository, { GroupRepositoryName } from "../domain/repositories/GroupRepository";
import GroupRepositoryFake from "../data/repositories/group/GroupRepositoryFake";
import GroupRepositoryDev from "../data/repositories/group/GroupRepositoryDev";
import GroupRepositoryImpl from "../data/repositories/group/GroupRepositoryImpl";
import RoleRepository, { RoleRepositoryName } from "../domain/repositories/RoleRepository";
import RoleRepositoryFake from "../data/repositories/role/RoleRepositoryFake";
import RoleRepositoryDev from "../data/repositories/role/RoleRepositoryDev";
import { RoleRepositoryImpl } from "../data/repositories/role/RoleRepositoryImpl";
import ShiftRepository, { ShiftRepositoryName } from "../domain/repositories/ShiftRepository";
import { ShiftRepositoryFake } from "../data/repositories/shift/ShiftRepositoryFake";
import { ShiftRepositoryDev } from "../data/repositories/shift/ShiftRepositoryDev";
import { ShiftRepositoryImpl } from "../data/repositories/shift/ShiftRepositoryImpl";
import TrackingTimeRepository, { TrackingTimeRepositoryName } from "../domain/repositories/TrackingTimeRepository";
import TrackingTimeRepositoryFake from "../data/repositories/trackingTime/TrackingTimeRepositoryFake";
import TrackingTimeRepositoryDev from "../data/repositories/trackingTime/TrackingTimeRepositoryDev";
import TrackingTimeRepositoryImpl from "../data/repositories/trackingTime/TrackingTimeRepositoryImpl";
import TrackingTimeProvider, { TrackingTimeProviderName } from "../domain/providers/trackingTime/TrackingTimeProvider";
import TrackingTimeProviderImpl from "../ui/providers/tracking/TrackingTimeProviderImpl";
import GetTodayTrackingCalendarUseCase, { GetTodayTrackingCalendarUseCaseName } from "../domain/use_cases/trackingTime/GetTodayTrackingCalendarUseCase";
import { EndEventClockUseCase, EndEventClockUseCaseName } from "../domain/use_cases/trackingTime/EndEventClockUseCase";
import GetReasonsToStopTrackingUseCase, { GetReasonsToStopTrackingUseCaseName } from "../domain/use_cases/trackingTime/GetReasonsToStopTrackingUseCase";
import CreateEventForShiftCalendarUseCase, { CreateEventForShiftCalendarUseCaseName } from "../domain/use_cases/shift/CreateEventForShiftCalendarUseCase";
import GetShiftCalendarOfDaysUseCase, { GetShiftCalendarOfDaysUseCaseName } from "../domain/use_cases/shift/GetShiftCalendarOfDaysUseCase";
import SearchShiftsWithUsersGroupByStatusUseCase, { SearchShiftsWithUsersGroupByStatusUseCaseName } from "../domain/use_cases/shift/SearchShiftsWithUsersGroupByStatusUseCase";
import SearchUsersOfShiftUseCase, { SearchUsersOfShiftUseCaseName } from "../domain/use_cases/shift/SearchUsersOfShiftUseCase";
import SearchUsersWithShiftUseCase, { SearchUsersWithShiftUseCaseName } from "../domain/use_cases/shift/SearchUsersWithShiftUseCase";
import SearchAllMyGroupsUseCase, { SearchAllMyGroupsUseCaseName } from "../domain/use_cases/group/SearchAllMyGroupsUseCase";
import CreateGroupUseCase, { CreateGroupUseCaseName } from "../domain/use_cases/group/CreateGroupUseCase";
import MastersProvider, { MastersProviderName } from "../domain/providers/master/MastersProvider";
import MastersProviderImpl from "../ui/providers/masters/MastersProviderImpl";
import GetAllTypeOfEventsUseCase, { GetAllTypeOfEventsUseCaseName } from "../domain/use_cases/calendar/GetAllTypeOfEventsUseCase";
import UpdateRoleUseCaseCase, { UpdateRoleUseCaseCaseName } from "../domain/use_cases/roles/UpdateRoleInfoUseCase";
import DeleteRoleByIdUseCase, { DeleteRoleByIdUseCaseName } from "../domain/use_cases/roles/DeleteRoleByIdUseCase";
import { UpdateRolePermissionsUseCase, UpdateRolePermissionsUseCaseName } from "../domain/use_cases/roles/UpdateRolePermissionsUseCase";
import GetShiftByIdUseCase, { GetShiftByIdUseCaseName } from "../domain/use_cases/shift/GetShiftByIdUseCase";
import SearchBusinessUnitUseCase, { SearchBusinessUnitUseCaseName } from "../domain/use_cases/businessUnit/SearchBusinessUnitUseCase";
import UpdateEventForShiftCalendarUseCase, { UpdateEventForShiftCalendarUseCaseName } from "../domain/use_cases/shift/UpdateEventForShiftCalendarUseCase";
import { StartClockTrackingUseCase, StartClockTrackingUseCaseName } from "../domain/use_cases/trackingTime/StartClockTrackingUseCase";
import AsignShiftUseCase, { AsignShiftUseCaseName } from "../domain/use_cases/shift/AsignShiftUseCase";
import CreateShiftUseCase, { CreateShiftUseCaseName } from "../domain/use_cases/shift/CreateShiftUseCase";
import SearchShiftUseCase, { SearchShiftUseCaseName } from "../domain/use_cases/shift/SearchShiftUseCase";
import { PauseClockTrackingUseCase, PauseClockTrackingUseCaseName } from "../domain/use_cases/trackingTime/PauseClockTrackingUseCase";
import GetAllPermissionsUseCase, { GetAllPermissionsUseCaseName } from "../domain/use_cases/roles/GetAllPermissionsUseCase";
import CreateRoleInfoUseCase, { CreateRoleInfoUseCaseName } from "../domain/use_cases/roles/CreateRoleInfoUseCase";
import SearchShiftTemplateUseCase, { SearchShiftTemplateUseCaseName } from "../domain/use_cases/shift/SearchShiftTemplateUseCase";
import RolesProvider, { RolesProviderName } from "../domain/providers/roles/RolesProvider";
import RolesProviderImpl from "../ui/providers/roles/RolesProviderImpl";
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from "../domain/use_cases/user/SearchAgentsUseCase";
import DeleteGroupByIdUseCase, { DeleteGroupByIdUseCaseName } from "../domain/use_cases/group/DeleteGroupByIdUseCase";
import CreateMultipleEventsForShiftUseCase, { CreateMultipleEventsForShiftUseCaseName } from "../domain/use_cases/shift/CreateMultipleEventsForShiftUseCase";
import AllUserRepositoryDev from "../data/repositories/allUsers/AllUserRepositoryDev";
import AllUserRepositoryImpl from "../data/repositories/allUsers/AllUserRepositoryImpl";
import Constants, { ConstantsName } from "../constants/Constants";
import ConstantsTest from "../constants/ConstantsTest";
import ConstantsDev from "../constants/ConstantsDev";
import ConstantsImpl from "../constants/ConstantsImpl";
import HostApi, { HostApiName } from "../data/settings/HostApi";
import Host2Api, { Host2ApiName } from "../data/settings/Host2Api";
import UserHostApi, { UserHostApiName } from "../data/settings/UserHostApi";
import ConstantsFake from "../constants/ConstantsFake";
import GetDayPlannedVsRealUseCase, { GetDayPlannedVsRealUseCaseName } from "../domain/use_cases/trackingTime/GetDayPlannedVsRealUseCase";
import { EditTrackingInteractionAsSupervisorUseCase, EditTrackingInteractionAsSupervisorUseCaseName } from "../domain/use_cases/trackingTime/EditTrackingInteractionAsSupervisorUseCase";
import { AddTrackingInteractionAsSupervisorUseCase, AddTrackingInteractionAsSupervisorUseCaseName } from "../domain/use_cases/trackingTime/AddTrackingInteractionAsSupervisorUseCase";
import GetSummaryAttendanceInTimeRangeUseCase, { GetSummaryAttendanceInTimeRangeUseCaseName } from "../domain/use_cases/trackingTime/GetSummaryAttendanceInTimeRangeUseCase";
import { GetAISummariesUseCase, GetAISummariesUseCaseName } from "../domain/use_cases/ai/GetAISummariesUseCase";
import AIRepository, { AIRepositoryName } from "../domain/repositories/AIRepository";
import AIRepositoryImpl from "../data/repositories/ai/AIRepositoryImpl";
import AIRepositoryDev from "../data/repositories/ai/AIRepositoryDev";
import AIRepositoryFake from "../data/repositories/ai/AIRepositoryFake";
import { GetAISummaryByRecordingUseCase, GetAISummaryByRecordingUseCaseName } from "../domain/use_cases/ai/GetAISummaryByRecordingUseCase";
import SearchAllUserUseCase, { SearchAllUserUseCaseName } from "../domain/use_cases/user/SearchAllUserUseCase";
import DeleteUsersUseCase, { DeleteUsersUseCaseName } from "../domain/use_cases/user/DeleteUserUseCase";
import SearchUserPositionsUseCase, { SearchUserPositionsUseCaseName } from "../domain/use_cases/user/SearchUserPositionsUseCase";
import SearchUserBUnitsUseCase, { SearchUserBUnitsUseCaseName } from "../domain/use_cases/user/SearchUserBUnitsUseCase";
import SearchAlSupervisorsUseCase, { SearchAlSupervisorsUseCaseName } from "../domain/use_cases/user/SearchAlSupervisorsUseCase";
import UpdateUserUseCase, { UpdateUserUseCaseName } from "../domain/use_cases/user/UpdateUserUseCase";
import CreateUserUseCase, { CreateUserUseCaseName } from "../domain/use_cases/user/CreateUserUseCase";
import { GetAIEmbedURLUseCase, GetAIEmbedURLUseCaseName } from "../domain/use_cases/ai/GetAIEmbedURLUseCase";
import SearchUsersOfGroupUseCase, { SearchUsersOfGroupUseCaseName } from "../domain/use_cases/group/SearchUsersOfGroupUseCase";
import EventFromShiftUseCase, { EventFromShiftUseCaseName } from "../domain/use_cases/calendar/EventFromShiftUseCase";
import AsignShiftBulkImportUseCase, { AsignShiftBulkImportUseCaseName } from "../domain/use_cases/shift/AsignShiftBulkImportUseCase";

import { SearchAISummariesUseCase, SearchAISummariesUseCaseName } from "../domain/use_cases/ai/SearchAISummariesUseCase";
import { SearchAISummariesLanguagesUseCase, SearchAISummariesLanguagesUseCaseName } from "../domain/use_cases/ai/SearchAISummariesLanguagesUseCase";
import { SearchAISummariesEntitiesUseCase, SearchAISummariesEntitiesUseCaseName } from "../domain/use_cases/ai/SearchAISummariesEntitiesUseCase";
import GetShiftAsEventsUseCase, { GetShiftAsEventsUseCaseName } from "../domain/use_cases/calendar/GetShiftAsEventsUseCase";
import { GetAIAgentsUseCase, GetAIAgentsUseCaseName } from "../domain/use_cases/ai/GetAIAgentsUseCase";
import { GetAICustomersUseCase, GetAICustomersUseCaseName } from "../domain/use_cases/ai/GetAICustomersUseCase";
import { GetAIPersonUseCase, GetAIPersonUseCaseName } from "../domain/use_cases/ai/GetAIPersonUseCase";
import UpdateUserWeekUseCase, { UpdateUserWeekUseCaseName } from "../domain/use_cases/shift/UpdateUserWeekUseCase";
import { UpdateAISummaryByRecordingUseCase, UpdateAISummaryByRecordingUseCaseName } from "../domain/use_cases/ai/UpdateAISummaryByRecordingUseCase";
import UpdatePasswordUseCase, { UpdatePasswordUseCaseName } from "../domain/use_cases/auth/UpdatePasswordUseCase";

enum MODE_DI { PRODUCTION, DEVELOPMENT, TEST, FAKE }

let mode = MODE_DI.DEVELOPMENT.toString();
if (process.env.REACT_APP_USER_BRANCH == 'prod') {
    mode = MODE_DI.PRODUCTION.toString();
} else if (process.env.REACT_APP_USER_BRANCH == 'dev') {
    mode = MODE_DI.DEVELOPMENT.toString();
} else if (process.env.REACT_APP_USER_BRANCH == 'test') {
    mode = MODE_DI.TEST.toString();
}
const di = new Container();

//#region ------------------ REPOSITORIES ------------------ //
if (mode === MODE_DI.FAKE.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsFake);
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryFake).inSingletonScope();
    di.bind<BusinessUnitRepository>(BusinessUnitRepositoryName).to(BusinessUnitRepositoryFake).inSingletonScope();
    di.bind<CalendarRepository>(CalendarRepositoryName).to(CalendarRepositoryFake).inSingletonScope();
    di.bind<GroupRepository>(GroupRepositoryName).to(GroupRepositoryFake).inSingletonScope();
    di.bind<RoleRepository>(RoleRepositoryName).to(RoleRepositoryFake).inSingletonScope();
    di.bind<ShiftRepository>(ShiftRepositoryName).to(ShiftRepositoryFake).inSingletonScope();
    di.bind<TrackingTimeRepository>(TrackingTimeRepositoryName).to(TrackingTimeRepositoryFake).inSingletonScope();
    di.bind<UserRepository>(UserRepositoryName).to(UserRepositoryFake).inSingletonScope();
    di.bind<AllUserRepository>(AllUserRepositoryName).to(AllUserRepositoryFake).inSingletonScope();
    di.bind<AIRepository>(AIRepositoryName).to(AIRepositoryFake).inSingletonScope();
} else if (mode === MODE_DI.DEVELOPMENT.toString()) {
    di.bind<Constants>(ConstantsName).toConstantValue(ConstantsDev);
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryDev).inSingletonScope();
    di.bind<BusinessUnitRepository>(BusinessUnitRepositoryName).to(BusinessUnitRepositoryDev).inSingletonScope();
    di.bind<CalendarRepository>(CalendarRepositoryName).to(CalendarRepositoryDev).inSingletonScope();
    di.bind<GroupRepository>(GroupRepositoryName).to(GroupRepositoryDev).inSingletonScope();
    di.bind<RoleRepository>(RoleRepositoryName).to(RoleRepositoryDev).inSingletonScope();
    di.bind<ShiftRepository>(ShiftRepositoryName).to(ShiftRepositoryDev).inSingletonScope();
    di.bind<TrackingTimeRepository>(TrackingTimeRepositoryName).to(TrackingTimeRepositoryDev).inSingletonScope();
    di.bind<UserRepository>(UserRepositoryName).to(UserRepositoryDev).inSingletonScope();
    di.bind<AllUserRepository>(AllUserRepositoryName).to(AllUserRepositoryDev).inSingletonScope();
    di.bind<AIRepository>(AIRepositoryName).to(AIRepositoryDev).inSingletonScope();
} else {
    if (mode === MODE_DI.TEST.toString()) di.bind<Constants>(ConstantsName).toConstantValue(ConstantsTest);
    else di.bind<Constants>(ConstantsName).toConstantValue(ConstantsImpl);
    di.bind<AuthRepository>(AuthRepositoryName).to(AuthRepositoryImpl).inSingletonScope();
    di.bind<BusinessUnitRepository>(BusinessUnitRepositoryName).to(BusinessUnitRepositoryImpl).inSingletonScope();
    di.bind<CalendarRepository>(CalendarRepositoryName).to(CalendarRepositoryImpl).inSingletonScope();
    di.bind<GroupRepository>(GroupRepositoryName).to(GroupRepositoryImpl).inSingletonScope();
    di.bind<RoleRepository>(RoleRepositoryName).to(RoleRepositoryImpl).inSingletonScope();
    di.bind<ShiftRepository>(ShiftRepositoryName).to(ShiftRepositoryImpl).inSingletonScope();
    di.bind<TrackingTimeRepository>(TrackingTimeRepositoryName).to(TrackingTimeRepositoryImpl).inSingletonScope();
    di.bind<UserRepository>(UserRepositoryName).to(UserRepositoryImpl).inSingletonScope();
    di.bind<AllUserRepository>(AllUserRepositoryName).to(AllUserRepositoryImpl).inSingletonScope();
    di.bind<AIRepository>(AIRepositoryName).to(AIRepositoryImpl).inSingletonScope();
}
//#endregion ------------------ REPOSITORIES ------------------ //

//#region -------------- APIs ----------------------- //
di.bind<HostApi>(HostApiName).toDynamicValue((context) => {
    return new HostApi();
}).inSingletonScope();
di.bind<Host2Api>(Host2ApiName).toDynamicValue((context) => {
    return new Host2Api();
}).inSingletonScope();
di.bind<UserHostApi>(UserHostApiName).toDynamicValue((context) => {
    return new UserHostApi();
}).inSingletonScope();

// ------------------ PROVIDERS ------------------ //   
di.bind<UserProvider>(UserProviderName).toConstantValue(UserProviderImpl);
di.bind<LocalizationProvider>(LocalizationProviderName).toConstantValue(LocalizationProviderImpl);
di.bind<MastersProvider>(MastersProviderName).toConstantValue(MastersProviderImpl);
di.bind<ModalsProvider>(ModalsProviderName).toConstantValue(ModalsProviderImpl);
di.bind<TrackingTimeProvider>(TrackingTimeProviderName).toConstantValue(TrackingTimeProviderImpl);
di.bind<RolesProvider>(RolesProviderName).toConstantValue(RolesProviderImpl);

//------------------ USE CASES ------------------//
//#region Auth  
di.bind<ConfirmUserUseCase>(ConfirmUserUseCaseName).toDynamicValue((context) => {
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new ConfirmUserUseCase({ authRepository });
}).inSingletonScope();
di.bind<DeleteUserUseCase>(DeleteUserUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new DeleteUserUseCase({ userProvider, authRepository });
}).inSingletonScope();
di.bind<GetSummaryAttendanceInTimeRangeUseCase>(GetSummaryAttendanceInTimeRangeUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const trackingTimeRepository = context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName);
    return new GetSummaryAttendanceInTimeRangeUseCase({ userProvider, trackingTimeRepository });
}).inSingletonScope();
di.bind<GetCurrentUserUseCase>(GetCurrentUserUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    const getTodayTrackingCalendarUseCase = context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName);
    const trackingTimeProvider = context.container.get<TrackingTimeProvider>(TrackingTimeProviderName);
    return new GetCurrentUserUseCase({ userProvider, authRepository, getTodayTrackingCalendarUseCase, trackingTimeProvider });
}).inSingletonScope();
di.bind<SendConfirmCodeUseCase>(SendConfirmCodeUseCaseName).toDynamicValue((context) => {
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new SendConfirmCodeUseCase({ authRepository });
}).inSingletonScope();
di.bind<SendRecoveryPasswordCodeUseCase>(SendRecoveryPasswordCodeUseCaseName).toDynamicValue((context) => {
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new SendRecoveryPasswordCodeUseCase({ authRepository });
}).inSingletonScope();
di.bind<SignInUseCase>(SignInUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    const getTodayTrackingCalendarUseCase = context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName);
    return new SignInUseCase({ userProvider, authRepository, getTodayTrackingCalendarUseCase });
}).inSingletonScope();
di.bind<SignInWithActiveDirectoryUseCase>(SignInWithActiveDirectoryUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new SignInWithActiveDirectoryUseCase({ userProvider, authRepository });
}).inSingletonScope();
di.bind<SignOutUseCase>(SignOutUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new SignOutUseCase({ userProvider, authRepository });
}).inSingletonScope();
di.bind<SignUpUseCase>(SignUpUseCaseName).toDynamicValue((context) => {
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new SignUpUseCase({ authRepository });
}).inSingletonScope();
di.bind<UpdatePasswordByRecoveryUseCase>(UpdatePasswordByRecoveryUseCaseName).toDynamicValue((context) => {
    const userProvider = context.container.get<UserProvider>(UserProviderName);
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new UpdatePasswordByRecoveryUseCase({ userProvider, authRepository });
}).inSingletonScope();

di.bind<UpdatePasswordUseCase>(UpdatePasswordUseCaseName).toDynamicValue((context) => {
    const authRepository = context.container.get<AuthRepository>(AuthRepositoryName);
    return new UpdatePasswordUseCase({ authRepository });
}).inSingletonScope();
//#endregion Auth


//UpdatePasswordUseCaseName


//#region BusinessUnit
di.bind<SearchBusinessUnitUseCase>(SearchBusinessUnitUseCaseName).toDynamicValue((context) => {
    return new SearchBusinessUnitUseCase({
        businessUnitRepository: context.container.get<BusinessUnitRepository>(BusinessUnitRepositoryName),
    });
}).inSingletonScope();
//#endregion BusinessUnit
//#region Default
di.bind<LoadUseCase>(LoadUseCaseName).toDynamicValue((context) => {
    return new LoadUseCase({
        getAllTypeOfEventsUseCase: context.container.get<GetAllTypeOfEventsUseCase>(GetAllTypeOfEventsUseCaseName),
        getCurrentUserUseCase: context.container.get<GetCurrentUserUseCase>(GetCurrentUserUseCaseName),
    });
}).inSingletonScope();
//#endregion Default

//#region Calendar
di.bind<GetShiftAsEventsUseCase>(GetShiftAsEventsUseCaseName).toDynamicValue((context) => {
    return new GetShiftAsEventsUseCase({
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName),
    });
}).inSingletonScope();
di.bind<CreateEventInCalendarUseCase>(CreateEventInCalendarUseCaseName).toDynamicValue((context) => {
    return new CreateEventInCalendarUseCase({
        calendarRepository: context.container.get<CalendarRepository>(CalendarRepositoryName),
        trackingTimeProvider: context.container.get<TrackingTimeProvider>(TrackingTimeProviderName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<EditEventInCalendarUseCase>(EditEventInCalendarUseCaseName).toDynamicValue((context) => {
    return new EditEventInCalendarUseCase({
        calendarRepository: context.container.get<CalendarRepository>(CalendarRepositoryName),
        trackingTimeProvider: context.container.get<TrackingTimeProvider>(TrackingTimeProviderName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<EventFromShiftUseCase>(EventFromShiftUseCaseName).toDynamicValue((context) => {
    return new EventFromShiftUseCase({
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName),
    });
}).inSingletonScope();
di.bind<GetAllTypeOfEventsUseCase>(GetAllTypeOfEventsUseCaseName).toDynamicValue((context) => {
    return new GetAllTypeOfEventsUseCase({
        calendarRepository: context.container.get<CalendarRepository>(CalendarRepositoryName),
        mastersProvider: context.container.get<MastersProvider>(MastersProviderName),
    });
}).inSingletonScope();
di.bind<GetUserCalendarOfDaysUseCase>(GetUserCalendarOfDaysUseCaseName).toDynamicValue((context) => {
    return new GetUserCalendarOfDaysUseCase({
        calendarRepository: context.container.get<CalendarRepository>(CalendarRepositoryName),
    });
}).inSingletonScope();
di.bind<GetUserTodayCalendarUseCase>(GetUserTodayCalendarUseCase).toDynamicValue((context) => {
    return new GetUserTodayCalendarUseCase({
        calendarRepository: context.container.get<CalendarRepository>(CalendarRepositoryName),
    });
}).inSingletonScope();
//#endregion Calendar
//#region groups
di.bind<CreateGroupUseCase>(CreateGroupUseCaseName).toDynamicValue((context) => {
    return new CreateGroupUseCase({
        groupRepository: context.container.get<GroupRepository>(GroupRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchAllMyGroupsUseCase>(SearchAllMyGroupsUseCaseName).toDynamicValue((context) => {
    return new SearchAllMyGroupsUseCase({
        groupRepository: context.container.get<GroupRepository>(GroupRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchUsersOfGroupUseCase>(SearchUsersOfGroupUseCaseName).toDynamicValue((context) => {
    return new SearchUsersOfGroupUseCase({
        groupRepository: context.container.get<GroupRepository>(GroupRepositoryName),
    });
}).inSingletonScope();
di.bind<DeleteGroupByIdUseCase>(DeleteGroupByIdUseCaseName).toDynamicValue((context) => {
    return new DeleteGroupByIdUseCase({
        groupRepository: context.container.get<GroupRepository>(GroupRepositoryName),
    });
}).inSingletonScope();

//#endregion groups
//#region Shift
di.bind<CreateEventForShiftCalendarUseCase>(CreateEventForShiftCalendarUseCaseName).toDynamicValue((context) => {
    return new CreateEventForShiftCalendarUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<CreateMultipleEventsForShiftUseCase>(CreateMultipleEventsForShiftUseCaseName).toDynamicValue((context) => {
    return new CreateMultipleEventsForShiftUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<AsignShiftBulkImportUseCase>(AsignShiftBulkImportUseCaseName).toDynamicValue((context) => {
    return new AsignShiftBulkImportUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<AsignShiftUseCase>(AsignShiftUseCaseName).toDynamicValue((context) => {
    return new AsignShiftUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<CreateShiftUseCase>(CreateShiftUseCaseName).toDynamicValue((context) => {
    return new CreateShiftUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<UpdateEventForShiftCalendarUseCase>(UpdateEventForShiftCalendarUseCaseName).toDynamicValue((context) => {
    return new UpdateEventForShiftCalendarUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<UpdateUserWeekUseCase>(UpdateUserWeekUseCaseName).toDynamicValue((context) => {
    return new UpdateUserWeekUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<GetShiftByIdUseCase>(GetShiftByIdUseCaseName).toDynamicValue((context) => {
    return new GetShiftByIdUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<GetShiftCalendarOfDaysUseCase>(GetShiftCalendarOfDaysUseCaseName).toDynamicValue((context) => {
    return new GetShiftCalendarOfDaysUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchShiftUseCase>(SearchShiftUseCaseName).toDynamicValue((context) => {
    return new SearchShiftUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchShiftTemplateUseCase>(SearchShiftTemplateUseCaseName).toDynamicValue((context) => {
    return new SearchShiftTemplateUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchShiftsWithUsersGroupByStatusUseCase>(SearchShiftsWithUsersGroupByStatusUseCaseName).toDynamicValue((context) => {
    return new SearchShiftsWithUsersGroupByStatusUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchUsersOfShiftUseCase>(SearchUsersOfShiftUseCaseName).toDynamicValue((context) => {
    return new SearchUsersOfShiftUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchUsersWithShiftUseCase>(SearchUsersWithShiftUseCaseName).toDynamicValue((context) => {
    return new SearchUsersWithShiftUseCase({
        shiftRepository: context.container.get<ShiftRepository>(ShiftRepositoryName),
    });
}).inSingletonScope();
//#endregion Shift
//#region TrackingTime
di.bind<AddTrackingInteractionAsSupervisorUseCase>(AddTrackingInteractionAsSupervisorUseCaseName).toDynamicValue((context) => {
    return new AddTrackingInteractionAsSupervisorUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<EditTrackingInteractionAsSupervisorUseCase>(EditTrackingInteractionAsSupervisorUseCaseName).toDynamicValue((context) => {
    return new EditTrackingInteractionAsSupervisorUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<EndEventClockUseCase>(EndEventClockUseCaseName).toDynamicValue((context) => {
    return new EndEventClockUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
        trackingTimeProvider: context.container.get<TrackingTimeProvider>(TrackingTimeProviderName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
    });
}).inSingletonScope();
di.bind<GetDayPlannedVsRealUseCase>(GetDayPlannedVsRealUseCaseName).toDynamicValue((context) => {
    return new GetDayPlannedVsRealUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
    });
}).inSingletonScope();
di.bind<GetReasonsToStopTrackingUseCase>(GetReasonsToStopTrackingUseCaseName).toDynamicValue((context) => {
    return new GetReasonsToStopTrackingUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
    });
}).inSingletonScope();
di.bind<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName).toDynamicValue((context) => {
    return new GetTodayTrackingCalendarUseCase({
        calendarRepository: context.container.get<CalendarRepository>(CalendarRepositoryName),
        userProvider: context.container.get<UserProvider>(UserProviderName),
        trackingTimeProvider: context.container.get<TrackingTimeProvider>(TrackingTimeProviderName),
    });
}).inSingletonScope();
di.bind<StartClockTrackingUseCase>(StartClockTrackingUseCaseName).toDynamicValue((context) => {
    return new StartClockTrackingUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
        trackingTimeProvider: context.container.get<TrackingTimeProvider>(TrackingTimeProviderName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
    });
}).inSingletonScope();
di.bind<PauseClockTrackingUseCase>(PauseClockTrackingUseCaseName).toDynamicValue((context) => {
    return new PauseClockTrackingUseCase({
        trackingTimeRepository: context.container.get<TrackingTimeRepository>(TrackingTimeRepositoryName),
        trackingTimeProvider: context.container.get<TrackingTimeProvider>(TrackingTimeProviderName),
        getTodayTrackingCalendarUseCase: context.container.get<GetTodayTrackingCalendarUseCase>(GetTodayTrackingCalendarUseCaseName),
    });
}).inSingletonScope();
//#endregion TrackingTime

//#region User
di.bind<SearchUserUseCase>(SearchUserUseCaseName).toDynamicValue((context) => {
    return new SearchUserUseCase({
        userRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();
di.bind<GetUserInfoByIdUseCase>(GetUserInfoByIdUseCaseName).toDynamicValue((context) => {
    return new GetUserInfoByIdUseCase({
        userRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchAgentsUseCase>(SearchAgentsUseCaseName).toDynamicValue((context) => {
    return new SearchAgentsUseCase({
        userRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();
di.bind<SearchSupervisorUseCase>(SearchSupervisorUseCaseName).toDynamicValue((context) => {
    return new SearchSupervisorUseCase({
        userRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();
//#endregion User

//#region User
di.bind<SearchAllUsersUseCase>(SearchAllUsersUseCaseName).toDynamicValue((context) => {
    return new SearchAllUsersUseCase({
        allUserRepository: context.container.get<AllUserRepository>(AllUserRepositoryName),
        rolesProvider: context.container.get<RolesProvider>(RolesProviderName),
    });
}).inSingletonScope();
//#endregion User

//#region Roles
di.bind<GetAllRolesUseCase>(GetAllRolesUseCaseName).toDynamicValue((context) => {
    return new GetAllRolesUseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName),
        rolesProvider: context.container.get<RolesProvider>(RolesProviderName),
    });
}).inSingletonScope();
//#endregion Roles

//#Roles by id
di.bind<GetRoleInfoByIdUseCase>(GetRoleInfoByIdUseCaseName).toDynamicValue((context) => {
    return new GetRoleInfoByIdUseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName)
    });
}).inSingletonScope();
//#end Roles by id

//#Update Role by id
di.bind<UpdateRoleUseCaseCase>(UpdateRoleUseCaseCaseName).toDynamicValue((context) => {
    return new UpdateRoleUseCaseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName),
    });
}).inSingletonScope();
//#endUpdate Roles by id CreateRoleInfoUseCaseName

di.bind<CreateRoleInfoUseCase>(CreateRoleInfoUseCaseName).toDynamicValue((context) => {
    return new CreateRoleInfoUseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName),
    });
}).inSingletonScope();

//#Delete Role by id
di.bind<DeleteRoleByIdUseCase>(DeleteRoleByIdUseCaseName).toDynamicValue((context) => {
    return new DeleteRoleByIdUseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName),
    });
}).inSingletonScope();
//#endDelete Roles by id

//#UpdatePermissions for Role by id
di.bind<UpdateRolePermissionsUseCase>(UpdateRolePermissionsUseCaseName).toDynamicValue((context) => {
    return new UpdateRolePermissionsUseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName),
    });
}).inSingletonScope();
//#endUpdatePermissions Roles by id

//#GetPermissions for Role by id
di.bind<GetAllPermissionsUseCase>(GetAllPermissionsUseCaseName).toDynamicValue((context) => {
    return new GetAllPermissionsUseCase({
        roleRepository: context.container.get<RoleRepository>(RoleRepositoryName),
    });
}).inSingletonScope();
//#endGetPermissions Roles by id

//UpdateUserRoleById
di.bind<UpdateUserRoleIdUseCase>(UpdateUserRoleIdUseCaseName).toDynamicValue((context) => {
    return new UpdateUserRoleIdUseCase({
        userRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();
//endUpdateRoleById

di.bind<GetAISummariesUseCase>(GetAISummariesUseCaseName).toDynamicValue((context) => {
    return new GetAISummariesUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<SearchAISummariesUseCase>(SearchAISummariesUseCaseName).toDynamicValue((context) => {
    return new SearchAISummariesUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<SearchAISummariesLanguagesUseCase>(SearchAISummariesLanguagesUseCaseName).toDynamicValue((context) => {
    return new SearchAISummariesLanguagesUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<SearchAISummariesEntitiesUseCase>(SearchAISummariesEntitiesUseCaseName).toDynamicValue((context) => {
    return new SearchAISummariesEntitiesUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<GetAISummaryByRecordingUseCase>(GetAISummaryByRecordingUseCaseName).toDynamicValue((context) => {
    return new GetAISummaryByRecordingUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<UpdateAISummaryByRecordingUseCase>(UpdateAISummaryByRecordingUseCaseName).toDynamicValue((context) => {
    return new UpdateAISummaryByRecordingUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<GetAIEmbedURLUseCase>(GetAIEmbedURLUseCaseName).toDynamicValue((context) => {
    return new GetAIEmbedURLUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<GetAIAgentsUseCase>(GetAIAgentsUseCaseName).toDynamicValue((context) => {
    return new GetAIAgentsUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<GetAICustomersUseCase>(GetAICustomersUseCaseName).toDynamicValue((context) => {
    return new GetAICustomersUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

di.bind<GetAIPersonUseCase>(GetAIPersonUseCaseName).toDynamicValue((context) => {
    return new GetAIPersonUseCase({
        aiRepository: context.container.get<AIRepository>(AIRepositoryName),
    });
}).inSingletonScope();

//GetAIPersonUseCaseName

di.bind<SearchAllUserUseCase>(SearchAllUserUseCaseName).toDynamicValue((context) => {
    return new SearchAllUserUseCase({
        allUserRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

di.bind<DeleteUsersUseCase>(DeleteUsersUseCaseName).toDynamicValue((context) => {
    return new DeleteUsersUseCase({
        deleteUsersRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

di.bind<SearchUserPositionsUseCase>(SearchUserPositionsUseCaseName).toDynamicValue((context) => {
    return new SearchUserPositionsUseCase({
        allUserPositionsRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

di.bind<SearchUserBUnitsUseCase>(SearchUserBUnitsUseCaseName).toDynamicValue((context) => {
    return new SearchUserBUnitsUseCase({
        allUserBUnitssRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

di.bind<SearchAlSupervisorsUseCase>(SearchAlSupervisorsUseCaseName).toDynamicValue((context) => {
    return new SearchAlSupervisorsUseCase({
        allSupervisorsRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

di.bind<UpdateUserUseCase>(UpdateUserUseCaseName).toDynamicValue((context) => {
    return new UpdateUserUseCase({
        updateUsersRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

di.bind<CreateUserUseCase>(CreateUserUseCaseName).toDynamicValue((context) => {
    return new CreateUserUseCase({
        createUsersRepository: context.container.get<UserRepository>(UserRepositoryName),
    });
}).inSingletonScope();

export default di;