import ModulePermision from "./ModulePermision";
import PermisionListEntity from "./PermisionListEntity";
import RoleEntity from "./RoleEntity";
import UserAccountEntity from "./UserAccountEntity";

export enum UserEntityStatus { active = 'active',  deleted = 'deleted' }
export default interface UserEntity extends UserAccountEntity {
    status: UserEntityStatus,
    image?: string | undefined,
    permissions?: PermisionListEntity | undefined,
    position: string,
    area: string,
    role?: { name: string},
    
    cognito_user?: string,
    firstname: string,
    lastname: string,
    profile_picture?: string,
    role_id?: string,
    systemid?: number,
    user_id?: number
    business_unit?: string,
}