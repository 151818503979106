import { useEffect, useState } from 'react';

import { Button, ContentLayout, Grid } from '@cloudscape-design/components';

import { UseFormReturn } from 'react-hook-form/dist/types/form';
import { FieldValues, useForm } from 'react-hook-form';
import { ContactTableMoments } from './ContactTableMoments';

const fakeMomnetsData = [
    {
        moment: "moment1",
        theme: "theme1",
        speaker: 'speaker1',
        teams: 'teams1',
        types: 'types1',
        audio: 'audio1',
        enable: 'enable1',
        actions: 'actions1'
    },
    {
        moment: "moment2",
        theme: "theme2",
        speaker: 'speaker2',
        teams: 'teams2',
        types: 'types2',
        audio: 'audio2',
        enable: 'enable2',
        actions: 'actions2'
    },
    {
        moment: "moment3",
        theme: "theme3",
        speaker: 'speaker3',
        teams: 'teams3',
        types: 'types3',
        audio: 'audio3',
        enable: 'enable3',
        actions: 'actions3'
    }
]

const fakeQuestionsData = [
    {
        question: "question1",
        weight: "weight1",
        enable: 'enable1',
    },
    {
        question: "question2",
        weight: "weight2",
        enable: 'enable2',
    },
    {
        question: "question3",
        weight: "weight3",
        enable: 'enable3',
    },
]

interface HomePageProps {
}

const AiPage: React.FC<HomePageProps> = () => {

    const [ itemsSelected, setItemsSelected ] = useState(fakeMomnetsData);

    const firstCall = async () => {
    }

    const onLoadTranscirption = () => {
        firstCall();
    }

    useEffect(() => {
        firstCall();
    }, []);

    return (
        <div style={{padding: "20px"}}>
            <ContentLayout
                headerBackgroundStyle={"transparent"}
            >
                <div style={{marginBottom: "20px"}}></div>
                <Grid
                    gridDefinition={[
                        {colspan: { default:12} },
                        {colspan: { default:12} }
                    ]}
                >
                    <ContactTableMoments
                        data={itemsSelected as any}
                        tableTitle='Moments'
                    />
                    <Button
                        variant="primary"
                        onClick={() => console.log()}
                    >
                    Create
                    </Button>
                </Grid>
            </ContentLayout>
        </div>
    );
};

export default AiPage;
