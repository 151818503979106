/********************************************************************************
 * File Header - Constants
 * Created By: Marlon Alejandro Mendez Castañeda
 * Contact: +57 3234686680
 * Date: March 14, 2024
 *
 * Description:
 * This is the implementation of the Constants interface, and it is used to store the
 * constants of the dev enviroment
 ********************************************************************************/

import Constants from "./Constants";

const ConstantsTest: Constants = {
    GRAPH_API_URL: "https://nm7gnubbd5f7jbumetktxbe3py.appsync-api.us-east-1.amazonaws.com/graphql",
    BASE_URL: "https://itelligencecx.com/",
    HOST_API_1_URL: "https://8ciupnbqfe.execute-api.us-east-1.amazonaws.com/prod",
    HOST_API_2_URL: "https://zey3854rl4.execute-api.us-east-1.amazonaws.com/prod",
    HOST_API_USERS_URL: "https://jgg4csnpk5.execute-api.us-east-1.amazonaws.com/prod",
    GA4: 'G-TM7KCSV579',
    AI: "https://hqz4bt9ee8.execute-api.us-east-1.amazonaws.com/prod"
    
}

export default ConstantsTest;
