import './StaffPerShiftListPageStyless.scss';
import { FC, useContext, useEffect, useState } from "react";
import di from "../../../../di/DependencyInjection";
import SearchWithGroupsComponent from "../../../components/searchWithGroups/SearchWithGroupsComponent";
import BusinessUnitEntity from "../../../../domain/entities/BusinessUnitEntity";
import UserEntity from "../../../../domain/entities/UserEntity";
import LoadingComponent from "../../../components/LoadingComponent/LoadingComponent";
import CalendarWeekFormComponent from "../../../components/forms/calendarWeekForm/CalendarWeekFormComponent";
import { DateOperations } from "../../../utils/DateOperations";
import DateParse from '../../../utils/DateParse';
import SearchBusinessUnitUseCase, { SearchBusinessUnitUseCaseName } from '../../../../domain/use_cases/businessUnit/SearchBusinessUnitUseCase';
import SearchShiftsWithUsersGroupByStatusUseCase, { SearchShiftsWithUsersGroupByStatusUseCaseName } from '../../../../domain/use_cases/shift/SearchShiftsWithUsersGroupByStatusUseCase';
import SearchSupervisorUseCase, { SearchSupervisorUseCaseName } from '../../../../domain/use_cases/user/SearchSupervisorUseCase';
import SearchAgentsUseCase, { SearchAgentsUseCaseName } from '../../../../domain/use_cases/user/SearchAgentsUseCase';
import StaffPerShiftEntity from '../../../../domain/entities/StaffPerShiftEntity';
import SearchResultEntity from '../../../../domain/entities/SearchResultEntity';
import LocalizationContext from '../../../../domain/providers/localization/LocalizationContext';
import LocalizationContextType from '../../../../domain/providers/localization/LocalizationContextType';
import KeyWordLocalization from '../../../providers/localization/dictionaries/KeyWordLocalization';
import NotResultsComponent from '../../../components/notResults/NotResultsComponent';

const ShiftListPage: FC<{}> = () => {
    const { i18n } = useContext(LocalizationContext) as LocalizationContextType;
    const [groupId, setGroupId] = useState<string | undefined>(undefined);
    const [page, setPage] = useState<number>(1);
    const [items, setItems] = useState<StaffPerShiftEntity[]>([]);
    const itemsPerPage = 100000;
    const [selectedBusinessUnit, setSelectedBusinessUnit] = useState<BusinessUnitEntity[]>([]);
    const [selectedSupervisor, setSelectedSupervisor] = useState<UserEntity[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<UserEntity[]>([]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [currentWeek, setCurrentWeek] = useState<Date[]>(DateOperations.getWeekDays(new Date())); //undefined: loading, null: not found

    const [isLoading, seIsLoading] = useState(true);

    const _searchBusinessUnit = async (word: string): Promise<BusinessUnitEntity[]> => {
        const response = await di.get<SearchBusinessUnitUseCase>(SearchBusinessUnitUseCaseName).call(word, 1, itemsPerPage, []);
        return response.items;
    }
    const _searchSupervisor = async (word: string, businessUnit: string[]): Promise<UserEntity[]> => {
        const response = await di.get<SearchSupervisorUseCase>(SearchSupervisorUseCaseName).call(word, 1, itemsPerPage, businessUnit);
        return response.items;
    }
    const _searchEmployees = async (word: string, businessUnit: string[], supervisors: string[]): Promise<UserEntity[]> => {

        const response = await di.get<SearchAgentsUseCase>(SearchAgentsUseCaseName).call(word, 1, itemsPerPage, businessUnit, supervisors);
        return response.items;
    }
    const _getShiftsGrouped = async () => {
        seIsLoading(true);
        const response: SearchResultEntity<StaffPerShiftEntity> = await di.get<SearchShiftsWithUsersGroupByStatusUseCase>(SearchShiftsWithUsersGroupByStatusUseCaseName).call(selectedBusinessUnit, selectedSupervisor, selectedEmployees, [], currentWeek, page, itemsPerPage);

        if (response?.items?.length < itemsPerPage) setHasMore(false);

        //order and filter
        const filtred = response.items.filter(item => item.days.length > 0);
        const sorted = filtred.sort((a, b) => a.name.localeCompare(b.name));
        seIsLoading(false);


        setItems(sorted);
    }

    const _handleOnChangeSearch = async (businessId: BusinessUnitEntity[], supervisorId: UserEntity[], employeesId: UserEntity[]) => {
        setSelectedBusinessUnit(businessId);
        setSelectedSupervisor(supervisorId);
        setSelectedEmployees(employeesId);
        setPage(1);
        setHasMore(true);
        await _getShiftsGrouped();
    }

    const _handleOnChangeWeek = (dates: Date[]) => {
        setCurrentWeek(dates);
        //_getShiftsGrouped("", selectedBusinessUnit, selectedSupervisor, selectedEmployees, groupId, currentWeek, page, itemsPerPage);
    }


    useEffect(() => {
        _getShiftsGrouped();
    }, []);
    // Array to store Date objects

    return <div className="staff_per_shift_page">
        <div className="page_content" id='scrollable_container'>
            <div className="container">
                <div className="row">
                    <SearchWithGroupsComponent isLoading={seIsLoading} itemsPerPage={itemsPerPage} setItems={setItems} onSearch={_handleOnChangeSearch} onSearchBusinessUnit={_searchBusinessUnit} onSearchEmployees={_searchEmployees} onSearchSupervisor={_searchSupervisor} from={"staffPerShift"} />
                </div>
                <div className="d-flex justify-content-between flex-wrap">
                    <CalendarWeekFormComponent onChange={_handleOnChangeWeek} selectedWeek={currentWeek} />
                </div>
                <div className="row mt-4">
                    <div className="none_item"></div>
                    {!isLoading && items.length == 0 && <NotResultsComponent />}
                    {
                        !isLoading && items.length > 0 ?

                            <div className="table-responsive">
                                <table className='table'>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {currentWeek.map((day, index) => <th key={index} className="text-center">
                                                {DateParse.getDayOfCalendar(day)} {day.getDate()}
                                            </th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {items?.map((bu, index) => <tr key={index}>
                                            <td>{bu.name}</td>
                                            {currentWeek.map((day, index) => {
                                                const shift = bu.days.find(dayShift => DateOperations.isSameDate(dayShift.date, day));
                                                if (!shift) return <td key={index} className="text-end"></td>
                                                return <td key={index} className="text-end">
                                                    <div className="d-flex justify-content-end shift_cell flex-wrap">
                                                        <div className="d-flex align-items-center shift_box">
                                                            {!DateOperations.isAfterToday(day) &&
                                                                <div className="me-2 d-flex align-items-center">
                                                                    <div className="shift_circle me-2" style={{ backgroundColor: shift.absent.color }}></div> {shift.absent.count} {i18n(KeyWordLocalization.StaffPerShiftListPageAbsent)}
                                                                </div>
                                                            }
                                                            <div className="me-2 d-flex align-items-center">
                                                                <div className="shift_circle me-2" style={{ backgroundColor: shift.active.color }}></div> {shift.active.count} {i18n(KeyWordLocalization.StaffPerShiftListPageActive)}
                                                            </div>
                                                            <div className="me-2 d-flex align-items-center">
                                                                <div className="shift_circle me-2" style={{ backgroundColor: shift.training.color }}></div> {shift.training.count} {i18n(KeyWordLocalization.StaffPerShiftListPageTraining)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            })}
                                        </tr>)}
                                    </tbody>
                                </table>

                            </div>
                            : <LoadingComponent />
                    }

                </div>
            </div>
        </div>
    </div>
}

export default ShiftListPage;